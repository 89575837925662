import { nanoid } from "nanoid";
import type {
  DocumentExtract,
  TDetailedScript,
  TDetailedSection,
  TDetailedStoryboard,
  TKeyInsight,
} from "pages/media/types";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export function sortSourcesByPageNumber<T extends DocumentExtract>(a: T, b: T) {
  return a.occurrences[0].page_number - b.occurrences[0].page_number;
}

export const generateId = (target: "section" | "key_insights") => {
  return target + "-" + nanoid();
};

export const addIdsToSectionKeyInsight = (
  keyInsights: TKeyInsight[]
): TKeyInsight[] => {
  return (
    keyInsights?.map((key_insight) => ({
      ...key_insight,
      id: generateId("key_insights"),
    })) ?? []
  );
};

export const storyboardToStoryboardDetailed = (
  storyboardData: TDetailedStoryboard
): TDetailedStoryboard => {
  // console.debug("storyboardData", storyboardData);
  const newBody = storyboardData?.sections?.map((section: TDetailedSection) => {
    const key_insights = addIdsToSectionKeyInsight(section.key_insights);

    const scripts =
      section.scripts?.map((script: TDetailedScript) => ({
        ...script,
        selected: false,
      })) ??
      (section.script && section.script.content.length > 0
        ? [
            {
              ...section.script,
              selected: true,
            },
          ]
        : []); // when restoring a SB, copy the selected script in scripts

    return {
      ...section,
      key_insights,
      scripts,
    };
  });

  return {
    ...storyboardData,
    sections: newBody,
  };
};

export const storyboardDetailedToStoryboard = (
  storyboard: TDetailedStoryboard
) => {
  console.debug("storyboardDetailedToStoryboard", storyboard);
  const result = {
    title: storyboard.title,
    sections: storyboard.sections.map((section) => ({
      id: section.id,
      topic: section.topic,
      script: section.script
        ? // && section.script.content.length > 0
          { content: section.script.content }
        : null, // backend doesn't like empty
    })),
  };
  return result;
};

export const timePretty = (readingTime: number): string => {
  const readingTimeDuration = dayjs.duration(readingTime);

  const readingTimeParts: string[] = [];
  if (readingTimeDuration.hours() > 0) {
    readingTimeParts.push(readingTimeDuration.format("H[h]"));
  }
  if (readingTimeDuration.minutes() > 0) {
    readingTimeParts.push(readingTimeDuration.format("m[min]"));
  }
  if (readingTimeDuration.seconds() > 0) {
    readingTimeParts.push(readingTimeDuration.format("s[s]"));
  }
  return readingTimeParts.join(" ");
};

export const stripId = (storyboard: TDetailedStoryboard): TDetailedStoryboard => {
  if (!storyboard) {
    return storyboard;
  }

  return {
    ...storyboard,
    sections: storyboard.sections.map((section) => ({
      ...section,
      key_insights: section.key_insights?.map(({ id, ...rest }): TKeyInsight => ({
        ...rest,
        id: "", 
      })) ?? [],
    })),
  };
};
