import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Authenticated,
  IResourceComponentsProps,
  useOne,
  useTranslate,
  useList,
  useUpdate,
} from "@refinedev/core";
import {
  Typography,
  Switch,
  Table,
  Button,
  Space,
  Tooltip,
  Spin,
  Flex,
  Alert,
  Result,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Globe } from "@phosphor-icons/react";
import { ProjectSubPageHeader } from "./components/ProjectSubPageHeader";
import { DateField } from "@refinedev/antd";
import styled from "styled-components";
import {
  MediaProjectPublicationResponse,
  MediaProjectResponse,
} from "../types";
import { isPublicationAllDocumentsSelected } from "./helpers/helper";

const { Text } = Typography;

export const EditChat: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const params = useParams();
  const projectId = params.id;
  const [attachAllDocuments, setAttachAllDocuments] = useState(false);
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<string[]>(
    []
  );
  const { mutateAsync: updatePublication, isLoading: isMutating } = useUpdate();

  const { data, isLoading } = useOne<MediaProjectResponse>({
    resource: `media/projects`,
    id: projectId,
  });
  const { data: publicationsData, isLoading: isLoadingPublications } =
    useList<MediaProjectPublicationResponse>({
      resource: `media/projects/${projectId}/publications`,
    });
  // simple logic : first one
  // TODO : support multiple publication
  const publicationId = publicationsData?.data?.[0]?.project_publication_id;
  // loads also documents list
  const { data: publicationData, isLoading: isLoadingPublication } =
    useOne<MediaProjectPublicationResponse>({
      resource: `media/projects/${projectId}/publications`,
      id: publicationId,
      queryOptions: {
        enabled: Boolean(publicationId),
      },
    });
  const project = data?.data;
  const publication = publicationData?.data;
  const documents = project?.documents;
  const projectDocumentsIds = useMemo(
    () => documents?.map((document) => document.id as string),
    [documents]
  );
  const publicationDocumentsIds = useMemo(
    () =>
      Object.values(publication?.documents ?? {}).map(
        (document) => document!.id
      ),
    [publication]
  );

  const toggleAllDocuments = (value: boolean) => {
    setAttachAllDocuments(value);
  };

  const handleRepublish = async () => {
    const publishedDocuments = project.documents.reduce<string[][]>(
      (acc, item, index) => {
        if (selectedDocumentsIds.includes(item.id)) {
          acc.push([`Document_${index}`, item.id]);
        }
        return acc;
      },
      []
    );

    await updatePublication({
      resource: `media/projects/${project.id}/publications`,
      id: publicationId,
      values: {
        documents: Object.fromEntries(publishedDocuments),
      },
    });
  };

  const columns = [
    {
      title: t("projects.components.ShareMedia.name"),
      dataIndex: "name",
      render: (text: string, record: { id: string }) => (
        <Tooltip title={record.id}>
          <Typography.Text>{text ?? "-"}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: t("projects.components.ShareMedia.createdAt"),
      dataIndex: "created",
      key: "created",
      sorter: (a: any, b: any) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
      render: (created: string) => <DateField format="LLL" value={created} />,
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedDocumentsIds,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedDocumentsIds(selectedRowKeys as string[]);
    },
  };

  useEffect(() => {
    setSelectedDocumentsIds(
      attachAllDocuments ? projectDocumentsIds : publicationDocumentsIds
    );
  }, [attachAllDocuments, publicationDocumentsIds, projectDocumentsIds]);

  useEffect(() => {
    if (publicationDocumentsIds && projectDocumentsIds) {
      // console.debug("setAttachAllDocuments");
      setAttachAllDocuments(
        isPublicationAllDocumentsSelected(
          publicationDocumentsIds,
          projectDocumentsIds
        )
      );
    }
  }, [publicationDocumentsIds, projectDocumentsIds]);

  if (isLoading || isLoadingPublications || isLoadingPublication) {
    return <Spin fullscreen />;
  }

  return (
    <Authenticated key="project_editChat">
      <ProjectSubPageHeader
        projectId={project.id}
        projectTitle={project.title}
        page="chatbotEdit"
      />
      {!publicationId ? (
        <Result status={404} />
      ) : (
        <Flex vertical gap={16}>
          <Flex justify="space-between" align="center">
            <label htmlFor="attachAllDocuments">
              <Flex vertical>
                <Space align="center">
                  <Text strong>
                    {t("projects.components.ShareMedia.useAllKnowledge")}
                  </Text>
                  <Tooltip
                    overlayInnerStyle={{ minWidth: 350 }}
                    trigger="click"
                    title={t("projects.components.ShareMedia.aKnowledgeBase")}
                  >
                    <Button
                      type="text"
                      shape="circle"
                      className="opacity-50"
                      icon={<InfoCircleOutlined />}
                    />
                  </Tooltip>
                </Space>
                <Text type="secondary" className="mt-0">
                  {t("projects.components.ShareMedia.aRepublishIs")}
                </Text>
              </Flex>
            </label>
            <Switch
              checked={attachAllDocuments}
              onChange={toggleAllDocuments}
            />
          </Flex>

          {!attachAllDocuments && (
            <Table
              size="large"
              rowKey="id"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={project.documents}
              pagination={false}
              className="rounded-lg"
            />
          )}

          <Alert
            type="warning"
            showIcon
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
            }}
            description={
              <Flex justify="space-between" align="center">
                <Text>{t("projects.components.ShareMedia.aRepublishIs")}</Text>
              </Flex>
            }
          />

          <Flex justify="flex-end" style={{ marginTop: 16 }}>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleRepublish}
              disabled={isMutating}
              loading={isMutating}
            >
              {t("buttons.save")}
            </Button>
          </Flex>
        </Flex>
      )}
    </Authenticated>
  );
};
