import { useOne } from "@refinedev/core";
import { useTemplates } from "./useTemplates";
import { MediaTemplateResponse, TemplateItem } from "types";
import { MediaAsset, PresenterProfileResponse } from "pages/media/types";
import { useCallback, useMemo } from "react";
import { rgbaToHex } from "../helpers/helper";
import { TemplateType } from "../types";
import { useVolatileBoundStore } from "store";
import { PREVIEW_ADJUSTMENT } from "../TemplatePreview";

export const useGetTemplateAssets = ({
  templateId,
  projectId,
}: {
  templateId?: string;
  projectId: string;
}) => {
  const {
    organizationId,
    brandKitPalette,
    mediaSettings,
    presenter: initialPresenter,
  } = useTemplates({
    projectId,
  });

  const {
    addImage,
    clearImages,
    currentChapter,
    view,
    setAvatarClose,
    setBackground,
    setTextStyle,
    setSubtitlesAnimation,
    setTitleStyle,
    setPresenter,
    setIntro,
    setTransition,
    setClosing,
  } = useVolatileBoundStore((store) => {
    return {
      addImage: store.addImage,
      clearImages: store.clearImages,
      currentChapter: store.templateState.currentChapter,
      view: store.templateState.view,
      setAvatarClose: store.setAvatarClose,
      setBackground: store.setBackground,
      setTextStyle: store.setTextStyle,
      setSubtitlesAnimation: store.setSubtitlesAnimation,
      setTitleStyle: store.setTitleStyle,
      setPresenter: store.setPresenter,
      setIntro: store.setIntro,
      setTransition: store.setTransition,
      setClosing: store.setClosing,
    };
  });

  const {
    data: templateData,
    isLoading: isTemplateLoading,
    isRefetching: isTemplateRefetching,
    isFetching: isTemplateFetching,
  } = useOne<MediaTemplateResponse>({
    resource: `media/${organizationId}/templates`,
    id: templateId,
    queryOptions: {
      enabled: !!templateId && !!organizationId,
    },
  });
  const isTemplateDataLoading = templateId
    ? isTemplateLoading || isTemplateRefetching || isTemplateFetching
    : false;
  const chapter = mediaSettings?.chapters.find(
    (chapter) =>
      chapter.chapter_name === currentChapter?.replace("Transition-", "")
  );

  console.debug(templateData?.data);

  const {
    data: templatePresenterData,
    isLoading: isPresenterLoading,
    isRefetching: isPresenterRefetching,
    isFetching: isPresenterFetching,
  } = useOne<PresenterProfileResponse>({
    resource: `media/${organizationId}/presenter_profiles`,
    id: chapter?.presenter_id,
    queryOptions: {
      enabled: !!organizationId && !!chapter?.presenter_id,
    },
  });
  const isPresenterDataLoading = chapter?.presenter_id
    ? isPresenterLoading || isPresenterRefetching || isPresenterFetching
    : false;

  const getItemPosition = (itemAttributes?: TemplateItem["attributes"]) => {
    return itemAttributes?.find((attribute) => attribute.type === "Position")
      ?.position;
  };

  const getItemWidth = (itemAttributes?: TemplateItem["attributes"]) => {
    return itemAttributes?.find((attribute) => attribute.type === "Width")
      ?.rate;
  };

  const getItemHeight = (itemAttributes?: TemplateItem["attributes"]) => {
    return itemAttributes?.find((attribute) => attribute.type === "Height")
      ?.rate;
  };

  const getItemFadeIn = (itemAttributes?: TemplateItem["attributes"]) => {
    const hasFadeIn = itemAttributes?.find(
      (attribute) => attribute.type === "Fade" && attribute.from_opacity === 0
    );
    return Boolean(hasFadeIn);
  };
  const getItemFadeOut = (itemAttributes?: TemplateItem["attributes"]) => {
    const hasFadeOut = itemAttributes?.find(
      (attribute) => attribute.type === "Fade" && attribute.from_opacity === 1
    );
    return Boolean(hasFadeOut);
  };

  const templateItems = templateData?.data?.template?.items;

  const headerRectangle = templateItems?.find(
    (item) =>
      item.name === "headerRectangle" && item?.graphics?.type === "Rectangle"
  )?.graphics;

  const bodyRectangle = templateItems?.find(
    (item) =>
      item.graphics.type === "Rectangle" &&
      item.attributes.some((attribute) => attribute.type === "FullScreen")
  )?.graphics;

  const headerBanner = templateItems?.find(
    (item) =>
      item.name === "headerRectangle" && item.graphics.type === "AssetImage"
  )?.graphics;

  const bodyImage = templateItems?.find(
    (item) =>
      item.graphics.type === "AssetImage" &&
      item.attributes.some((attribute) => attribute.type === "FullScreen")
  )?.graphics;

  const imageItemsArray = useMemo(
    () =>
      templateItems?.filter(
        (item) =>
          item.name !== "headerRectangle" &&
          item.graphics.type === "AssetImage" &&
          item.attributes.some((attribute) => attribute.type !== "FullScreen")
      ),
    [templateItems]
  );

  const title = templateItems?.find((item) => item.graphics.type === "Text");
  const titleAsset = title?.graphics;
  const titlePosition = getItemPosition(title?.attributes);
  const titleWidth = getItemWidth(title?.attributes);
  const titleHeight = getItemHeight(title?.attributes);

  let titleCoordinates: CoordinatesTuple = NULL_COORDINATES;
  if (titlePosition?.type === "Coordinates") {
    titleCoordinates = titlePosition?.position;
  }
  const titleFadeIn = getItemFadeIn(title?.attributes);
  const titleFadeOut = getItemFadeOut(title?.attributes);

  const basicSubtitles = templateItems?.find(
    (item) => item.graphics.type === "Subtitles"
  );
  const basicSubtitlesItem = basicSubtitles?.graphics;
  const basicSubtitlesPosition = getItemPosition(basicSubtitles?.attributes);
  const basicSubtitlesWidth = getItemWidth(basicSubtitles?.attributes);

  const basicSubtitlesHeight = getItemHeight(basicSubtitles?.attributes);
  let basicSubtitlesCoordinates: CoordinatesTuple = NULL_COORDINATES;
  if (basicSubtitlesPosition?.type === "Coordinates") {
    basicSubtitlesCoordinates = basicSubtitlesPosition?.position;
  }
  const basicSubtitlesFadeIn = getItemFadeIn(basicSubtitles?.attributes);
  const basicSubtitlesFadeOut = getItemFadeOut(basicSubtitles?.attributes);

  const animatedSubtitles = templateItems?.find(
    (item) => item.graphics.type === "RichSubtitles"
  );
  const animatedSubtitlesItem = animatedSubtitles?.graphics;
  const animatedSubtitlesPosition = getItemPosition(
    animatedSubtitles?.attributes
  );
  const animatedSubtitlesWidth = getItemWidth(animatedSubtitles?.attributes);
  const animatedSubtitlesHeight = getItemHeight(animatedSubtitles?.attributes);

  let animatedSubtitlesCoordinates: CoordinatesTuple = NULL_COORDINATES;
  if (animatedSubtitlesPosition?.type === "Coordinates") {
    animatedSubtitlesCoordinates = animatedSubtitlesPosition?.position;
  }
  const animatedSubtitlesFadeIn = getItemFadeIn(animatedSubtitles?.attributes);
  const animatedSubtitlesFadeOut = getItemFadeOut(
    animatedSubtitles?.attributes
  );

  const titleBackground = templateItems?.find(
    (item) => item.name === "titleBackground"
  )?.graphics;
  let titleBackgroundColor = NULL_COLOR;
  if (titleBackground?.type === "Rectangle") {
    titleBackgroundColor = rgbaToHex(titleBackground.color);
  }

  const textBackground = templateItems?.find(
    (item) => item.name === "textBackground"
  )?.graphics;
  let textBackgroundColor = NULL_COLOR;
  if (textBackground?.type === "Rectangle") {
    textBackgroundColor = rgbaToHex(textBackground.color);
  }

  const videoItem = templateItems?.find(
    (item) => item.graphics.type === "CustomAssetVideo"
  )?.graphics;
  let videoAssetId: string | null = null;
  if (videoItem?.type === "CustomAssetVideo") {
    videoAssetId = videoItem?.asset_id;
  }

  const videoAssetRequest = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id: videoAssetId ?? "",
    queryOptions: {
      onError: () => {},
      enabled: !!videoAssetId,
    },
  });
  let videoAsset = videoAssetRequest.data?.data;

  const {
    isLoading: isVideoAssetLoading,
    isFetching: isVideoAssetFetching,
    isRefetching: isVideoAssetRefetching,
  } = videoAssetRequest;

  if (!videoAssetId) {
    videoAsset = undefined;
  }
  const isVideoAssetDataLoading = videoAssetId
    ? isVideoAssetLoading || isVideoAssetRefetching || isVideoAssetFetching
    : false;
  let headerAssetPath: string | null = null;
  if (headerBanner && headerBanner?.type === "AssetImage") {
    headerAssetPath = headerBanner.asset_id;
  }

  let bodyImageAssetPath: string | null = null;
  if (bodyImage && "asset_id" in bodyImage) {
    bodyImageAssetPath = bodyImage?.asset_id;
  }

  const avatarCircleGraphics = templateItems?.find(
    (item) => item.graphics.type === "Circle"
  )?.graphics;

  let avatarCircle = false;

  let avatarCircleBackgroundColor: string = NULL_COLOR;
  if (avatarCircleGraphics && avatarCircleGraphics?.type === "Circle") {
    avatarCircle = true;
    avatarCircleBackgroundColor = rgbaToHex(
      avatarCircleGraphics?.color ?? [255, 255, 255, 255]
    );
  }

  // If avatar is circle, position and width should be taken from Circle item, because avatar doesn't have it
  const avatar = avatarCircle
    ? templateItems?.find((item) => item.graphics.type === "Circle")?.attributes
    : templateItems?.find((item) => item.graphics.type === "Avatar")
        ?.attributes;

  let avatarPosition;
  let avatarWidth = 0;
  let avatarHeight = 0;
  let avatarFadeIn = false;
  let avatarFadeOut = false;
  let avatarCoordinates = NULL_COORDINATES;

  if (avatar) {
    avatarPosition = getItemPosition(avatar);

    if (avatarPosition?.type === "Coordinates") {
      avatarCoordinates = avatarPosition?.position;
    }
    avatarWidth =
      avatar?.find((attribute) => attribute.type === "Width")?.rate ?? 0;

    avatarHeight =
      avatar?.find((attribute) => attribute.type === "Height")?.rate ?? 0;

    avatarFadeIn = getItemFadeIn(avatar);
    avatarFadeOut = getItemFadeOut(avatar);
  }

  const {
    data: bannerAsset,
    isLoading: isBannerAssetLoading,
    isRefetching: isBannerAssetRefetching,
    isFetching: isBannerAssetFetching,
  } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id: headerAssetPath ?? "",
    queryOptions: {
      onError: () => {},
      enabled: !!headerAssetPath,
    },
  });
  const isBannerAssetDataLoading = headerAssetPath
    ? isBannerAssetLoading || isBannerAssetRefetching || isBannerAssetFetching
    : false;
  let titleFontAssetId: string | undefined;
  let titleFontSize: number | undefined;
  let titleColor: string | undefined;

  let basicSubtitleFontAssetId: string | undefined;
  let basicSubtitlesColor: string | undefined;
  let basicSubtitlesFontSize: number | undefined;

  let animatedSubtitlesFontAssetId: string | undefined;
  let animatedSubtitlesColor: string | undefined;
  let animatedSubtitlesStrokeColor: string | undefined;
  let animatedSubtitlesFontSize: number | undefined;

  if (titleAsset?.type === "Text") {
    titleFontAssetId = titleAsset?.font;
    titleFontSize = titleAsset?.size;
    titleColor = titleAsset.color
      ? rgbaToHex(titleAsset.color)
      : DEFAULT_TEXT_COLOR;
  }

  if (basicSubtitlesItem?.type === "Subtitles") {
    basicSubtitleFontAssetId = basicSubtitlesItem?.font;
    basicSubtitlesColor = rgbaToHex(basicSubtitlesItem.color);
    basicSubtitlesFontSize = basicSubtitlesItem?.size;
  }

  if (animatedSubtitlesItem?.type === "RichSubtitles") {
    animatedSubtitlesFontAssetId = animatedSubtitlesItem?.text?.font;
    animatedSubtitlesColor = rgbaToHex(
      animatedSubtitlesItem?.text?.color ?? [0, 0, 0, 255]
    );
    animatedSubtitlesStrokeColor = rgbaToHex(
      animatedSubtitlesItem?.text?.stroke_color ?? undefined
    );
    animatedSubtitlesFontSize = animatedSubtitlesItem?.text?.size;
  }

  const fontAssetId: string | undefined =
    titleFontAssetId ??
    basicSubtitleFontAssetId ??
    animatedSubtitlesFontAssetId;

  const {
    data: fontAsset,
    isLoading: isFontAssetLoading,
    isRefetching: isFontAssetRefetching,
    isFetching: isFontAssetFetching,
  } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id: fontAssetId,
    queryOptions: {
      enabled: !!fontAssetId,
      onError: () => {},
    },
  });
  const isFontAssetDataLoading = bodyImageAssetPath
    ? isFontAssetLoading || isFontAssetRefetching || isFontAssetFetching
    : false;

  const { data: bodyImageResponse } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id: bodyImageAssetPath ?? "",
    queryOptions: {
      enabled: !!bodyImageAssetPath,
      onError: () => {},
    },
  });

  let bodyImageAsset = bodyImageResponse?.data;

  if (!bodyImageAssetPath) {
    bodyImageAsset = undefined;
  }

  let bodyView: Background;

  if (videoAsset) {
    bodyView = "Video";
  } else if (
    bodyImageAsset &&
    view !== TemplateType.INTRODUCTION &&
    view !== TemplateType.CLOSING
  ) {
    bodyView = "Picture";
  } else {
    bodyView = "Color";
  }

  const headerView: "Picture" | "Color" = headerBanner ? "Picture" : "Color";

  let bodyRectangleColor: string | undefined;
  if (bodyRectangle?.type === "Rectangle") {
    bodyRectangleColor = rgbaToHex(bodyRectangle.color);
  } else {
    bodyRectangleColor = brandKitPalette?.primary_color;
  }

  let headerRectangleColor: string | undefined;
  if (headerRectangle?.type === "Rectangle") {
    headerRectangleColor = rgbaToHex(headerRectangle.color);
  } else {
    headerRectangleColor = brandKitPalette?.secondary_color;
  }

  let templatePresenter;

  if (avatar) {
    if (chapter?.presenter_id) {
      templatePresenter = templatePresenterData?.data;
    } else if (initialPresenter) {
      templatePresenter = initialPresenter;
    }
  }
  const refreshImageArray = useCallback(
    (imageAssetsArray: TemplateItem[]) => {
      clearImages();

      if (!imageAssetsArray) return;
      imageAssetsArray.forEach((image) => {
        const imageGraphics: TemplateItem["graphics"] = image.graphics;
        let imageGraphicsId: string | undefined = undefined;

        if (imageGraphics?.type === "AssetImage") {
          imageGraphicsId = imageGraphics?.asset_id;
        }
        const imagePositionAttribute = image.attributes?.find(
          (attribute) => attribute.type === "Position"
        );

        const defaultPosition = [0, 0];
        let imagePosition: number[] | undefined = defaultPosition;

        if (
          imagePositionAttribute?.type === "Position" &&
          imagePositionAttribute?.position?.type === "Coordinates"
        ) {
          imagePosition =
            imagePositionAttribute?.position?.position ?? defaultPosition;
        }

        const imageWidth = image.attributes?.find(
          (attribute) => attribute.type === "Width"
        )?.rate;

        const imageHeight = image.attributes?.find(
          (attribute) => attribute.type === "Height"
        )?.rate;

        const imageFadeIn = image.attributes?.find(
          (attribute) =>
            attribute.type === "Fade" && attribute.from_opacity === 0
        );

        const imageFadeOut = image.attributes?.find(
          (attribute) =>
            attribute.type === "Fade" && attribute.from_opacity === 1
        );
        const position = {
          x: imagePosition[0] / PREVIEW_ADJUSTMENT,
          y: imagePosition[1] / PREVIEW_ADJUSTMENT,
        };
        addImage({
          assetId: imageGraphicsId,
          position,
          size: {
            width: imageWidth ?? 0,
            height: imageHeight ?? 0,
          },
          fadeIn: Boolean(imageFadeIn),
          fadeOut: Boolean(imageFadeOut),
        });
      });
    },
    [addImage, clearImages]
  );

  const backgroundTemplateObject = useMemo(
    () => ({
      bodyView: bodyView,
      bodyVideo: videoAsset?.path,
      bodyVideoAsset: videoAsset?.id,
      bodyImage: bodyImageAsset?.path,
      bodyImageAsset: bodyImageAsset?.id,
      bodyColor: bodyRectangleColor,
      headerView: headerView,
      headerImage: bannerAsset?.data?.path,
      headerColor: headerRectangleColor ?? "#ffffff",
    }),
    [
      bannerAsset,
      bodyImageAsset,
      bodyRectangleColor,
      bodyView,
      headerRectangleColor,
      headerView,
      videoAsset,
    ]
  );

  const presenterPosition = useMemo(
    () => ({
      x: avatarCoordinates[0] / PREVIEW_ADJUSTMENT,
      y: avatarCoordinates[1] / PREVIEW_ADJUSTMENT,
    }),
    [avatarCoordinates]
  );

  const presenterSize = useMemo(
    () => ({
      width: avatarWidth > 1 ? DEFAULT_AVATAR_RATIO.width : avatarWidth, //in case the size was wrongly saved in old templates
      height: avatarHeight > 1 ? DEFAULT_AVATAR_RATIO.height : avatarHeight,
    }),
    [avatarWidth, avatarHeight]
  );

  const presenterTemplateObject = useMemo(
    () => ({
      presenter: templatePresenter,
      position: templatePresenter ? presenterPosition : { x: 50, y: 330 },
      size: templatePresenter ? presenterSize : { width: 0.55, height: 0.31 },
      fadeIn: avatarFadeIn,
      fadeOut: avatarFadeOut,
      avatarCircle: Boolean(avatarCircle),
      background: avatarCircleBackgroundColor,
    }),
    [
      templatePresenter,
      presenterPosition,
      presenterSize,
      avatarFadeIn,
      avatarFadeOut,
      avatarCircle,
      avatarCircleBackgroundColor,
    ]
  );

  const basicSubtitlesTemplateObject = useMemo(
    () => ({
      textFont: fontAsset?.data?.path,
      fontAssetId: fontAsset?.data?.id,
      fontSize: basicSubtitlesFontSize,
      subtitles_color: basicSubtitlesColor,
      subtitles_stroke_color: undefined,
      textBackground: textBackgroundColor,
      position: {
        x: basicSubtitlesCoordinates?.[0] / PREVIEW_ADJUSTMENT,
        y: basicSubtitlesCoordinates?.[1] / PREVIEW_ADJUSTMENT,
      },
      size: {
        width: basicSubtitlesWidth,
        height: basicSubtitlesHeight,
      },
      fadeIn: basicSubtitlesFadeIn ?? false,
      fadeOut: basicSubtitlesFadeOut ?? false,
    }),
    [
      basicSubtitlesColor,
      basicSubtitlesCoordinates,
      basicSubtitlesFadeIn,
      basicSubtitlesFadeOut,
      basicSubtitlesFontSize,
      basicSubtitlesHeight,
      basicSubtitlesWidth,
      fontAsset,
      textBackgroundColor,
    ]
  );

  const animatedSubtitlesTemplateObject = useMemo(
    () => ({
      textFont: fontAsset?.data?.path,
      fontAssetId: fontAsset?.data?.id,
      fontSize: animatedSubtitlesFontSize,
      subtitles_color: animatedSubtitlesColor,
      subtitles_stroke_color: animatedSubtitlesStrokeColor,
      textBackground: textBackgroundColor,
      position: {
        x: animatedSubtitlesCoordinates[0] / PREVIEW_ADJUSTMENT,
        y: animatedSubtitlesCoordinates[1] / PREVIEW_ADJUSTMENT,
      },
      size: {
        width: animatedSubtitlesWidth,
        height: animatedSubtitlesHeight,
      },
      fadeIn: animatedSubtitlesFadeIn ?? false,
      fadeOut: animatedSubtitlesFadeOut ?? false,
    }),
    [
      animatedSubtitlesColor,
      animatedSubtitlesCoordinates,
      animatedSubtitlesFadeIn,
      animatedSubtitlesFadeOut,
      animatedSubtitlesFontSize,
      animatedSubtitlesHeight,
      animatedSubtitlesStrokeColor,
      animatedSubtitlesWidth,
      fontAsset,
      textBackgroundColor,
    ]
  );

  const titleTemplateObject = useMemo(
    () => ({
      titleFont: fontAsset?.data?.path,
      fontSize: titleFontSize,
      fontAssetId: fontAsset?.data?.id,
      title_color: titleColor,
      title_stroke_color: undefined,
      titleBackground: titleBackgroundColor,
      position: {
        x: titleCoordinates[0] / PREVIEW_ADJUSTMENT,
        y: titleCoordinates[1] / PREVIEW_ADJUSTMENT,
      },
      size: {
        width: titleWidth,
        height: titleHeight,
      },
      fadeIn: titleFadeIn,
      fadeOut: titleFadeOut,
    }),
    [
      fontAsset,
      titleBackgroundColor,
      titleColor,
      titleCoordinates,
      titleFadeIn,
      titleFadeOut,
      titleFontSize,
      titleHeight,
      titleWidth,
    ]
  );

  const avatarClose = templateData?.data?.template?.avatar_shot;

  const resetTemplate = useCallback(() => {
    setAvatarClose(avatarClose === "CloseUp");
    setBackground(backgroundTemplateObject);

    if (basicSubtitlesItem) {
      setTextStyle(basicSubtitlesTemplateObject);
      setSubtitlesAnimation(false);
    } else if (animatedSubtitlesItem) {
      setTextStyle(animatedSubtitlesTemplateObject);
      setSubtitlesAnimation(true);
    } else if (titleAsset) {
      setTitleStyle(titleTemplateObject);
    }

    if (imageItemsArray) {
      refreshImageArray(imageItemsArray);
    }

    setPresenter(presenterTemplateObject);

    const setFuncs = {
      [TemplateType.INTRODUCTION]: [setIntro],
      [TemplateType.TRANSITION]: [setTransition],
      [TemplateType.CLOSING]: [setClosing],
    };

    if (videoAsset && view !== TemplateType.CHAPTER) {
      const videoAssetObj = {
        enable: true,
        assetId: videoAsset.id,
        assetPath: videoAsset?.path,
        view: "Video",
      };
      setFuncs[view][0](videoAssetObj);
    }
  }, [
    animatedSubtitlesItem,
    animatedSubtitlesTemplateObject,
    avatarClose,
    backgroundTemplateObject,
    basicSubtitlesItem,
    basicSubtitlesTemplateObject,
    imageItemsArray,
    presenterTemplateObject,
    refreshImageArray,
    setAvatarClose,
    setBackground,
    setPresenter,
    setSubtitlesAnimation,
    setTextStyle,
    setTitleStyle,
    setIntro,
    setClosing,
    setTransition,
    titleAsset,
    titleTemplateObject,
    videoAsset,
    view,
  ]);

  const isDataLoading =
    isTemplateDataLoading ||
    isPresenterDataLoading ||
    isFontAssetDataLoading ||
    isVideoAssetDataLoading ||
    isBannerAssetDataLoading;

  return {
    templateData: templateData?.data,
    bodyRectangle,
    bodyImage: bodyImageAsset,
    videoAsset,
    headerRectangle,
    headerBanner: bannerAsset?.data,
    imageItemsArray,
    fontAsset: fontAsset?.data,
    titleAsset,
    titlePosition,
    basicSubtitlesPosition,
    animatedSubtitlesPosition,
    resetTemplate,
    isTemplateLoading,
    isDataLoading,
  };
};
type CoordinatesTuple = [number, number];
type Background = "Video" | "Picture" | "Color";
const NULL_COORDINATES: CoordinatesTuple = [0, 0];
export const NULL_COLOR = "#00000000";
export const DEFAULT_AVATAR_RATIO = { width: 0.55, height: 0.31 };
export const DEFAULT_TEXT_COLOR = "#000000";

// // Compare object but unsign the paths before comparing
// function templateObjectsAreEqual<
//   T extends Record<string, unknown>,
//   P extends keyof T | (keyof T)[],
// >(obj1: T, obj2: T, keys: P) {
//   const unsignPath = (path: string) => path?.split("?")?.[0];
//   return Object.keys(obj2).every((objKey) => {
//     if ((Array.isArray(keys) && keys.includes(objKey)) || objKey === keys) {
//       return (
//         unsignPath(obj1[objKey] as string) ===
//         unsignPath(obj2[objKey] as string)
//       );
//     }
//     return JSON.stringify(obj1[objKey]) === JSON.stringify(obj2[objKey]);
//   });
// }
