import { useTranslate } from "@refinedev/core";
import {
  Badge,
  Button,
  Flex,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import {
  MediaProjectResponse,
  TDetailedScript,
  TDetailedSection,
  DisplayMetrics,
  TScript,
  StoryboardAsset,
  SectionUpdate,
  DocumentExtract,
  TKeyInsight,
  TDetailedStoryboard,
} from "pages/media/types";
import { useEffect, useRef, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useStoryboardWs } from "hooks/useStoryboardWs";
import {
  Active,
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  Over,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { countWords } from "pages/media/utils";
import {
  addIdsToSectionKeyInsight,
  generateId,
  sortSourcesByPageNumber,
  storyboardDetailedToStoryboard,
  storyboardToStoryboardDetailed,
  stripId,
} from "./editor/helpers";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import { StoryboardMessage, WebSocketMessage } from "../chat/types";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { DisplayMode, TCallbackAssociation } from "./editor/types";
import { Section } from "./editor/Section";
import { MiniLabel } from "components/MiniLabel";
import { SPEECH_WORDS_PER_MINUTE } from "pages/media/constants";
import { Present } from "./editor/Present";
import { useVolatileBoundStore } from "store";
import { useFeature } from "hooks/useFeature";
import { useSaveStoryboard } from "../templates/hooks/useSaveStoryboard";
import { ArrowsDownUp } from "@phosphor-icons/react";
import { SortedChaptersModal } from "./editor/SortedChaptersModal";

export const StoryboardEditor = ({
  project,
  storyboardId,
  displayMode,
}: {
  project?: MediaProjectResponse;
  storyboardId?: string;
  displayMode: DisplayMode;
}) => {
  const t = useTranslate();
  const { isEnabled: isMetricsEnabled } = useFeature(
    "features.app.media.storyboard_metrics"
  );
  const {
    setScriptsWordsCount,
    setReadingTime,
    setMetrics,
    setStoryboard,
    resetStoryboardState,
    storyboard,
  } = useVolatileBoundStore((state) => ({
    setScriptsWordsCount: state.setScriptsWordsCount,
    setReadingTime: state.setReadingTime,
    setMetrics: state.setMetrics,
    setStoryboard: state.setStoryboard,
    resetStoryboardState: state.resetStoryboardState,
    storyboard: state.storyboardState.storyboard,
  }));
  const [openSortChapters, setOpenSortChapters] = useState(false);
  const [sortedStoryboard, setSortedStoryboard] =
    useState<TDetailedStoryboard>();
  const [isReady, setIsReady] = useState(false);
  const [keyInsightsId, setKeyInsightId] = useState<string[]>([]);
  const [sectionsId, setSectionsId] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [callbacks, setCallbacks] = useState<TCallbackAssociation[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [editing, setEditing] = useState(false);
  useEffect(() => {
    const initializeStoryboard = async () => {
      setIsReady(false);
      resetStoryboardState();
      setKeyInsightId([]);
      setSectionsId([]);
      setCallbacks([]);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsReady(true);
    };
    initializeStoryboard();
  }, [storyboardId, resetStoryboardState]);
  //  when getting very fast updates lastResponse state seemed to lose some updates
  const onMessage = (lastResponse: WebSocketMessage) => {
    // console.debug({ lastResponse });

    // const call = callbacks!.find(
    //   (item) => item.query_id === lastResponse?.query_id
    // );
    // console.debug("lastResponse changed", { lastResponse, call, callbacks });
    // deprecated StructureDocument
    // if (
    //   lastResponse &&
    //   "storyboard" in lastResponse &&
    //   lastResponse.storyboard
    // ) {
    //   setStoryboard(storyboardToStoryboardDetailed(lastResponse.storyboard));
    // }
    // if (lastResponse && "structure" in lastResponse && lastResponse.structure) {
    //   setStoryboard(storyboardToStoryboardDetailed(lastResponse.structure));
    // }

    if (
      (lastResponse?.action === "CreateSection" ||
        lastResponse?.action === "RegenerateSection") &&
      "section" in lastResponse &&
      lastResponse.section
    ) {
      const { section_id: id, ...newSection } = lastResponse.section;
      handleChangeSection({
        // update local after conversion
        ...newSection,
        id,
        key_insights: addIdsToSectionKeyInsight(
          lastResponse.section?.key_insights ?? []
        ),
      } as unknown as TDetailedSection); // FIXME
    }
    if (
      lastResponse?.action === "GenerateScript" &&
      lastResponse &&
      "scripts" in lastResponse &&
      lastResponse.scripts &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      const call = callbacks!.find(
        (item) => item.query_id === lastResponse.query_id
      );
      call?.callback(lastResponse.scripts);
    }
    if (
      lastResponse?.action === "ImproveScript" &&
      lastResponse &&
      "message" in lastResponse &&
      "script" in lastResponse &&
      lastResponse.script &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      const call = callbacks!.find(
        (item) => item.query_id === lastResponse.query_id
      );
      const { sectionId, scriptIndex } = call?.callback([]) as unknown as {
        sectionId: string;
        scriptIndex: number;
      };
      setStoryboard((old) => ({
        ...old!,
        sections: old!.sections?.map((section) => {
          if (section.id === sectionId) {
            const isSelectedScript = section.scripts?.[scriptIndex]?.selected;
            const content = lastResponse.script;
            return {
              ...section,
              ...(isSelectedScript
                ? { script: { content, selected: true } }
                : {}),
              sources: processSources(section.sources),
              scripts:
                section.scripts?.map((script, index) => {
                  if (index === scriptIndex)
                    return {
                      // merge values
                      ...script,
                      content,
                      // selected: false,
                      revisions: [
                        {
                          content: content as string,
                          prompt: lastResponse.message,
                          selected: isSelectedScript,
                        },
                        ...(script?.revisions ?? [
                          {
                            content: script?.content,
                            prompt: "",
                            selected: false,
                          },
                        ]),
                      ],
                    };
                  else {
                    return script;
                  }
                }) ?? [],
            };
          } else {
            return section;
          }
        }),
      }));
    }

    if (
      lastResponse?.action === "ComputeMetrics" &&
      lastResponse &&
      "metrics" in lastResponse &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      setMetrics(lastResponse.metrics as DisplayMetrics);
    }
    if (
      lastResponse?.action === "TextToSpeech" &&
      lastResponse &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      setStoryboard((old) => {
        if (!old) return;
        const sections = old.sections.map((section) => {
          if (section.id !== lastResponse.section_id) {
            return section;
          }
          return {
            ...section,
            speech: {
              value: lastResponse.speech ?? "",
              loading: false,
            },
          };
        });
        return {
          ...old!,
          sections,
        };
      });
    }
    if (
      lastResponse?.action === "UpdateSection" &&
      lastResponse &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      if (lastResponse.section_id)
        requestRetrieveSectionData(lastResponse.section_id);
    }
    if (
      lastResponse?.action === "RetrieveSectionData" &&
      lastResponse &&
      "data" in lastResponse &&
      "status" in lastResponse &&
      lastResponse.status === "success"
    ) {
      setStoryboard((old) => {
        if (!old) return;
        const sections = old.sections.map((section) => {
          if (section.id !== lastResponse.section_id) {
            return section;
          }
          return {
            ...section,
            scripts: section.scripts ?? [section.script],
            key_insights:
              addIdsToSectionKeyInsight(
                lastResponse.data?.key_insights ?? []
              ) ?? [],
            detailed_summary: lastResponse.data?.detailed_summary ?? "",
            sources: processSources(lastResponse.data?.sources ?? []),
            speech: {
              value: null,
              loading: false,
            },
          };
        });

        return {
          ...old!,
          sections,
        };
      });
    }
  };

  const {
    init,
    requestGenerateScript,
    requestRegenerateSection,
    requestCreateSection,
    requestUpdateSection,
    requestImproveScript,
    requestComputeMetrics,
    requestRetrieveSectionData,
    requestTextToSpeech,
    getConnectionStatus,
    lastMessage,
    isConnected,
    isBusy,
  } = useStoryboardWs({
    organizationId: project!.organization_id,
    documentIds: project!.documents.map((doc) => doc.id),
    onMessage: onMessage,
  });

  const {
    filterAssets,
    projectAssets,
    isMutateLoading,
    isLoadingProjectAssets,
    fetchJsonAsset,
  } = useMediaAssetsStorage({
    organizationId: project!.organization_id,
    projectId: project?.id,
    // mediaId: project?.medias[0].id,
    enabled: Boolean(project?.id),
  });

  const storyboardAsset = projectAssets
    ? filterAssets<StoryboardAsset>(projectAssets as StoryboardAsset[], {
        asset_type: "Storyboard",
        id: storyboardId,
      })?.[0]
    : null;
  console.debug({ projectAssets, storyboardId, storyboardAsset });

  const generateSectionScripts = (
    sectionId: string,
    callback: (scripts: TDetailedScript[]) => void
  ) => {
    if (!storyboardAsset?.id) return; // todo

    setCallbacks((old) => {
      return [
        ...old,
        {
          query_id: requestGenerateScript(
            storyboardDetailedToStoryboard(stripId(storyboard)!),
            sectionId
          ),
          callback,
        },
      ];
    });
  };

  const generateSection = (topic: string, sectionId: string) => {
    if (sectionId.startsWith("section")) {
      // local id // TODO improve logic
      requestCreateSection(
        topic,
        storyboardDetailedToStoryboard(stripId(storyboard)!)
      );
    } else {
      requestRegenerateSection(
        sectionId,
        topic,
        storyboardDetailedToStoryboard(stripId(storyboard)!)
      );
    }
  };

  const handleImproveScript = (
    sectionId: string,
    script: TScript,
    message: string,
    callback: (scripts: TDetailedScript[]) => void
  ) => {
    setCallbacks((old) => {
      return [
        ...old,
        {
          query_id: requestImproveScript(sectionId, script, message),
          callback,
        },
      ];
    });
  };

  const computeMetrics = () => {
    if (!isMetricsEnabled) {
      return;
    }
    requestComputeMetrics(storyboardDetailedToStoryboard(stripId(storyboard)!));
  };

  const extractSectionsIds = () => {
    if (!storyboard) {
      return [];
    }
    return storyboard.sections.flatMap((elt) => (elt ? elt.id : []));
  };

  // const extractIds = (type: "scripts" | "key_insights"): string[] => {
  const extractIds = (type: "key_insights"): string[] => {
    if (!storyboard) {
      return [];
    }

    const scriptIds = storyboard.sections.flatMap(
      (section) => section[type]?.flatMap((elt) => (elt ? elt.id : [])) ?? []
    );

    return scriptIds;
  };

  const handleChangeSection = (
    newSection: TDetailedSection,
    isSection: boolean = true
  ) => {
    const storyboardOptions: {
      section: [
        TDetailedStoryboard,
        React.Dispatch<React.SetStateAction<TDetailedStoryboard>>,
      ];
      sorted: [
        TDetailedStoryboard,
        React.Dispatch<React.SetStateAction<TDetailedStoryboard>>,
      ];
    } = {
      section: [storyboard, setStoryboard],
      sorted: [sortedStoryboard, setSortedStoryboard],
    };

    const storyboardType = isSection ? "section" : "sorted";

    const [currentStoryboard, setCurrentStoryboard] =
      storyboardOptions[storyboardType];

    if (!currentStoryboard) {
      return;
    }
    // delete use case
    if (
      newSection.detailed_summary === "" &&
      newSection.scripts?.length === 0 &&
      newSection.key_insights.length === 0 &&
      newSection.topic === ""
    ) {
      setCurrentStoryboard((old) => ({
        ...old!,
        sections: old!.sections.filter((section: TDetailedSection) => {
          return section.id !== newSection.id;
        }),
      }));
      return;
    }

    console.debug("update section", { newSection });
    setCurrentStoryboard((value) => ({
      ...value,
      sections: value!.sections.map((section: TDetailedSection) => {
        if (section.id === newSection.id || section.topic === newSection.topic)
          return newSection;
        else return section;
      }),
    }));
    const oldSection = currentStoryboard.sections.find(
      (section) => section.id === newSection.id
    );
    const newValues: SectionUpdate = {
      key_insights: newSection.key_insights,
    };
    if (oldSection?.script?.content !== newSection?.script?.content) {
      newValues.script = newSection?.script?.content;
    }
    if (!newSection.id.startsWith("section")) {
      // sync server generated sections
      setCurrentStoryboard((old) => {
        if (!old) return;
        const sections = old.sections.map((section) => {
          if (section.id === newSection.id) {
            return {
              ...section,
              ...newSection,
              speech: {
                value: null,
                loading: false,
              },
            };
          }
          return section;
        });
        return {
          ...old,
          sections,
        };
      });
      requestUpdateSection(newSection.id, newValues);
    }
  };

  const switchPlaces = (
    current: TDetailedSection[] | TKeyInsight[],
    oldIndex: number,
    newIndex: number
  ) => {
    const amputated = [
      ...current.slice(0, oldIndex),
      ...current.slice(oldIndex + 1, current.length),
    ];

    const final = [
      ...amputated.slice(0, newIndex),
      current[oldIndex],
      ...amputated.slice(newIndex, current.length),
    ];

    return final;
  };

  const moveSection = (
    active: Active,
    over: Over | null
  ): TDetailedSection[] => {
    if (!sortedStoryboard) {
      return [];
    }
    const current = sortedStoryboard.sections;
    const oldIndex = current?.findIndex((elt) => elt?.id === active.id);
    const newIndex = current?.findIndex((elt) => elt?.id === over?.id);

    const final = switchPlaces(current, oldIndex, newIndex);

    return final as TDetailedSection[];
  };

  const objectMove = (
    type: "key_insights" | "scripts",
    active: Active,
    over: Over | null
  ) => {
    if (!storyboard) {
      return { parent: {}, final: {} };
    }
    const parent = storyboard.sections.find((section) => {
      return section.key_insights?.find((elt: TKeyInsight) => {
        return elt?.id === active.id;
      });
    });

    const current = parent?.key_insights;
    const oldIndex = current?.findIndex((elt) => elt?.id === active.id);
    const newIndex = current?.findIndex((elt) => elt?.id === over?.id);

    const final = switchPlaces(current, oldIndex, newIndex);

    return { parent, final };
  };

  const handleOnSortCancel = () => {
    setSortedStoryboard(storyboard);
    setSectionsId(extractSectionsIds());
    setOpenSortChapters(false);
  };

  const handleOnSortSubmit = () => {
    setStoryboard(sortedStoryboard);
    setSectionsId(
      sortedStoryboard?.sections.flatMap((elt) => (elt ? elt.id : []))
    );
    setOpenSortChapters(false);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activeElementType = (
        active.data.current as {
          type: "key_insights" | "section";
        }
      )?.type; // sent as custom data
      if (activeElementType === "key_insights") {
        const { parent, final } = objectMove("key_insights", active, over);
        setStoryboard((old) => ({
          ...old,
          sections: old.sections.map((section) => {
            if (section.id === (parent as TKeyInsight).id)
              return {
                ...section,
                key_insights: final as TKeyInsight[],
              };
            else return section;
          }),
        }));
        setKeyInsightId((items) => {
          const oldIndex = items.findIndex((id) => id === active.id);
          const newIndex = items.findIndex((id) => id === over?.id);
          return arrayMove(items, oldIndex, newIndex);
        });
        // sync backend with frontend changes
        if (parent && "id" in parent) {
          requestUpdateSection(parent.id, {
            key_insights: final as TKeyInsight[],
          });
        }
      } else if (activeElementType === "section") {
        const final = moveSection(active, over);
        setSortedStoryboard((old) => {
          return {
            ...old,
            sections: final,
          };
        });
        setSectionsId(final.flatMap((elt) => (elt ? elt.id : [])));
      }
    }
  };

  useEffect(() => {
    if (project?.id && storyboardAsset?.id) {
      init({ projectId: project.id, storyboardId: storyboardAsset.id });
    }
  }, [storyboardAsset?.id, project?.id]);

  const loadStoryboard = async () => {
    try {
      if (storyboardAsset?.path) {
        const json = await fetchJsonAsset(storyboardAsset.path);
        json.sections.map((section: TDetailedSection) => {
          requestRetrieveSectionData(section.id);
        });
        json.sections.forEach(
          (section) =>
            (section.speech = {
              value: null,
              loading: false,
            })
        );
        setStoryboard(storyboardToStoryboardDetailed(json));
        setSortedStoryboard(storyboardToStoryboardDetailed(json));
      }
    } catch (error) {
      console.error("Issue while loading JSON:", error);
    }
  };

  useEffect(() => {
    if (storyboardAsset?.path) {
      loadStoryboard();
    }
  }, [storyboardAsset?.path]);

  useEffect(() => {
    // add necessary ids for the ui
    setKeyInsightId(extractIds("key_insights"));
    setSectionsId(extractSectionsIds());
  }, [storyboard]);

  // useEffect(() => {
  //   if (isConnected) {
  //     requestStructureDocument();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isConnected]);

  // only consider final scripts
  /****
   * CAN BE UPDATED WITH section.script !== undefined
   */
  // const filterFunction = (x: unknown): x is TDetailedSection => {
  //   return (
  //     x !== null && typeof x === "object" && "selected" in x && !!x.selected
  //   );
  // };
  const sectionsWithSelectedScript = storyboard?.sections
    // .filter(filterFunction)
    // .flatMap((x) => x.scripts?.filter(filterFunction));
    .filter((x) => x.scripts?.some((x) => x.selected))
    .flatMap((x) => x.scripts?.filter((x) => x.selected));

  const scriptsWordsCount =
    sectionsWithSelectedScript
      ?.map((x) => countWords(x?.content ?? ""))
      .reduce((acc, count) => acc + count, 0) ?? 0;
  const readingTime = (60000 * scriptsWordsCount) / SPEECH_WORDS_PER_MINUTE; // seconds

  const completionPercent =
    ((sectionsWithSelectedScript?.length ?? 0) * 100) /
    (storyboard?.sections?.length ?? 0);

  const finalScript = sectionsWithSelectedScript?.reduce(
    (acc, x) => acc.concat(x?.content),
    ""
  );

  useEffect(() => {
    setScriptsWordsCount(scriptsWordsCount);
    setReadingTime(readingTime);
  }, [scriptsWordsCount, readingTime, setScriptsWordsCount, setReadingTime]);

  useEffect(() => {
    // recompute metrics when then final script changes
    if (completionPercent === 100) {
      computeMetrics();
    }
  }, [finalScript, completionPercent]);

  const canSave = Boolean(storyboard);

  const { saveStoryboard } = useSaveStoryboard({
    projectId: project?.id,
    organizationId: project?.organization_id,
    storyboardId,
    storyboard,
    documentId: project.documents?.[0]?.id,
  });

  const Status = (
    <Space size={"small"}>
      <MiniLabel>
        {t("components.storyboard.StoryboardEditor.status")}
      </MiniLabel>
      <div style={{ position: "relative", marginLeft: 4 }}>
        <LoadingOutlined
          spin
          style={{
            fontSize: 24,
            opacity: isBusy || getConnectionStatus() === "Connecting" ? 1 : 0,
            position: "absolute",
            left: -9,
            top: -4,
            color: isConnected ? "green" : "red",
          }}
        />
        <Tooltip
          title={
            <>
              <div>{getConnectionStatus()}</div>
              {lastMessage?.is_final === false && (
                <div>
                  {t("components.storyboard.StoryboardEditor.loading")}
                  {lastMessage.action}{" "}
                  {(lastMessage as StoryboardMessage).topic}
                </div>
              )}
            </>
          }
        >
          <Badge dot color={isConnected ? "green" : "red"} />
        </Tooltip>
      </div>
    </Space>
  );

  const Controls = (
    <Flex
      align="center"
      justify="space-between"
      style={{
        margin: "20px 0",
      }}
    >
      <Space>
        {/* {storyboard === undefined ? (
          <Button
            loading={isLoadingProjectAssets}
            disabled={isBusy || !isConnected}
            icon={<BuildOutlined />}
            size="large"
            shape="round"
            type={"primary"}
            onClick={initStoryboard}
          >
            Start
          </Button>
        ) : (
          <Popconfirm title="Are you sure ?" onConfirm={initStoryboard}>
            <Button
              loading={isLoadingProjectAssets}
              disabled={isBusy || !isConnected}
              icon={<BuildOutlined />}
              size="large"
              shape="round"
            >
              Reset
            </Button>
          </Popconfirm>
        )} */}
        {canSave && (
          <>
            {/* TODO: Perhaps edit this if the storyboard is editable after the
         approval? */}
            <Button
              // type="primary"
              size="large"
              shape="round"
              onClick={async () => {
                try {
                  setSaving(true);
                  await saveStoryboard();
                } finally {
                  setSaving(false);
                }
              }}
              loading={saving || isMutateLoading || isLoadingProjectAssets}
              disabled={saving || isMutateLoading}
            >
              {t("components.storyboard.StoryboardEditor.saveChanges")}
            </Button>
          </>
        )}
      </Space>
      {Status}
    </Flex>
  );

  if (!storyboard) {
    return (
      <>
        {Controls}
        {isBusy && <Skeleton active style={{ width: "100%" }} />}
        {isBusy && <Skeleton active style={{ width: "100%" }} />}
        {isBusy && <Skeleton active style={{ width: "100%" }} />}
        {isBusy && <Skeleton active style={{ width: "100%" }} />}
        {isBusy && <Skeleton active style={{ width: "100%" }} />}
      </>
    );
  }

  const handleAddSection = () => {
    const newSecId = generateId("section");
    const newKeyInsightId = generateId("key_insights");
    setStoryboard((old) => {
      return {
        ...old!,
        sections: [
          ...old!.sections,
          {
            topic: "",
            key_insights: [],
            detailed_summary: "",
            scripts: [],
            script: {
              content: "",
              selected: false,
            },
            id: newSecId,
            sources: [],
          },
        ],
      };
    });

    setSectionsId((old) => {
      return [...old, newSecId];
    });

    setKeyInsightId((old) => {
      return [...old, newKeyInsightId];
    });

    // Scroll to the created chapter with a timeout so DOM is updated
    if (containerRef.current) {
      setTimeout(() => {
        if (!containerRef.current?.parentElement) return;
        containerRef.current.parentElement.scrollTo({
          top: containerRef.current.parentElement.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  if (displayMode === "present") {
    return (
      <Flex vertical>
        <Present storyboard={storyboard} />
      </Flex>
    );
  }
  if (!storyboard || !isReady) {
    return <Spin fullscreen />;
  }

  return (
    <>
      {Controls}
      {/* {displayMode === "compact" && (
          <Flex vertical gap={10}>
            <MiniLabel>Markdown final script</MiniLabel>
            <Typography.Paragraph
              copyable
              ellipsis={{ rows: 2, expandable: true }}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {getFullScript()}
            </Typography.Paragraph>
          </Flex>
        )} */}
      <Flex vertical gap={10} style={{ marginTop: 0 }} ref={containerRef}>
        <Flex justify="space-between" align="center">
          <Typography.Title
            level={4}
            style={{ width: "90%", padding: 0, margin: 0 }}
            editable={{
              enterIcon: null,
              triggerType: ["text", "icon"],
              onChange: (value) => {
                setStoryboard((old) => ({
                  ...old!,
                  title: value,
                }));
                setEditing(false);
              },
              editing: editing,
              tooltip: false,
              onStart: () => setEditing(true),
            }}
          >
            {storyboard.title}
          </Typography.Title>
          <Space>
            <Button
              size="small"
              shape="round"
              icon={<ArrowsDownUp />}
              onClick={() => setOpenSortChapters(true)}
            >
              {t("components.storyboard.StoryboardEditor.sortChapters")}
            </Button>
            <Button
              size="small"
              shape="round"
              icon={<PlusOutlined />}
              onClick={handleAddSection}
            >
              {t("components.storyboard.StoryboardEditor.addChapter")}
            </Button>
          </Space>
        </Flex>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          {storyboard?.sections.map(
            (section: TDetailedSection, index: number) => {
              return (
                <Section
                  requestTextToSpeech={requestTextToSpeech}
                  generateId={generateId}
                  storyboard={storyboard}
                  key={section.id}
                  id={section.id}
                  changeSection={handleChangeSection}
                  generateSectionScripts={generateSectionScripts}
                  improveScript={handleImproveScript}
                  generateSection={generateSection}
                  section={section}
                  keyInsightsId={keyInsightsId}
                  setKeyInsightId={setKeyInsightId}
                  displayMode={displayMode}
                  editable={!isBusy && isConnected}
                  index={index}
                />
              );
            }
          )}
          <SortedChaptersModal
            changeSection={(newSection) => {
              handleChangeSection(newSection, false);
            }}
            open={openSortChapters}
            sectionsId={sectionsId}
            storyboard={sortedStoryboard}
            onSubmit={handleOnSortSubmit}
            onCancel={handleOnSortCancel}
          />
        </DndContext>

        {/* <Typography.Paragraph>
          {JSON.stringify(keyInsightsId, null, 2)}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {JSON.stringify(scriptsId, null, 2)}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {JSON.stringify(sectionsId, null, 2)}
        </Typography.Paragraph> */}
        {/* <JsonPreview data={stripId()} /> */}
      </Flex>
    </>
  );
};
function processSources(unsortedSources: DocumentExtract[]) {
  const sources = [...unsortedSources];
  sources.sort(sortSourcesByPageNumber);
  return sources;
}
