import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAnalytics as _useAnalytics } from "use-analytics";
import PageTitle from "components/PageTitle";

export type OnboardingStepType =
  | "organization"
  | "department"
  | "usage"
  | "business"
  | "website"
  | "source"
  | "finish";

type ProjectSettingType =
  | "brandkit"
  | "experience_type"
  | "storyboard_language"
  | "experience_tone"
  | "selected_pages"
  | "movie_duration"
  | "summary_extraction"
  | "contact"
  | "video_transition"
  | "call_to_action"
  | "project_objective";

interface UTMParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

const getUTMParams = (): UTMParams => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    utm_source: urlParams.get("utm_source") || undefined,
    utm_medium: urlParams.get("utm_medium") || undefined,
    utm_campaign: urlParams.get("utm_campaign") || undefined,
    utm_term: urlParams.get("utm_term") || undefined,
    utm_content: urlParams.get("utm_content") || undefined,
  };
};

export const initializeAnalytics = () => {
  if (!sessionStorage.getItem("initial_utm_params")) {
    // not sure we need these
    // sessionStorage.setItem("initial_referrer", document.referrer);
    // sessionStorage.setItem("initial_landing_page", window.location.href);
    sessionStorage.setItem(
      "initial_utm_params",
      JSON.stringify(getUTMParams())
    );
  }
};

const getSector = () => {
  return "default"; // todo
};

export const getCommonProperties = () => ({
  // sector: "default", // todo with landing page specs
  // initial_referrer:
  //   sessionStorage.getItem("initial_referrer") || document.referrer,
  // initial_landing_page:
  //   sessionStorage.getItem("initial_landing_page") || window.location.href,
});

const getTrackingProperties = (additionalProps = {}) => {
  const utmParams = JSON.parse(
    sessionStorage.getItem("initial_utm_params") || "{}"
  );
  const commonProps = getCommonProperties();
  const campaign = {
    name: utmParams.utm_campaign,
    source: utmParams.utm_source,
    medium: utmParams.utm_medium,
    term: utmParams.utm_term,
    content: utmParams.utm_content,
  };
  return {
    ...commonProps,
    ...additionalProps,
    ...(Object.keys(campaign).length ? campaign : undefined),
  };
};

export const useAnalytics = () => {
  const analytics = _useAnalytics();
  const isEnabled = !(
    // for testing first
    (
      process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "staging"
    )
  );

  const baseTrack = (event: string, properties?: any) => {
    if (!isEnabled) return;
    const trackingProps = getTrackingProperties(properties);
    analytics.track(event, trackingProps);
  };

  const baseIdentify = (userId: string, properties?: any) => {
    if (!isEnabled) return;
    const trackingProps = getTrackingProperties(properties);
    analytics.identify(userId, trackingProps);
  };

  const basePage = (title?: string, properties?: any) => {
    if (!isEnabled) return;
    const analyticsTitle = document
      .querySelector('meta[name="analytics-title"]')
      ?.getAttribute("content");

    const trackingProps = getTrackingProperties({
      ...properties,
    });

    analytics.page({ title: analyticsTitle ?? title }, trackingProps);
  };

  const trackPageView = () => {
    // hack : wait page to be rendered with proper page title
    setTimeout(() => basePage(), 1000);
  };

  const identifyUser = (userId: string, userTraits?: any) => {
    baseIdentify(userId, userTraits);
  };
  const trackSignupStarted = () => {
    baseTrack("signup_start");
  };

  const trackSignupCompleted = (userId: string, plan: string) => {
    const properties = { user_id: userId, plan };
    identifyUser(userId, properties);
    baseTrack("signup_complete", properties);
  };

  const trackProjectStarted = (projectId: string, documentFormat: string) => {
    const properties = {
      project_id: projectId,
      document_format: documentFormat,
    };
    baseTrack("project_start", properties);
  };
  const trackProjectSettingUpdated = (
    projectId: string,
    settingType: ProjectSettingType,
    value: string | number,
    page: "project_creation_wizard" | "project_settings"
  ) => {
    baseTrack("project_setting_update", {
      page,
      project_id: projectId,
      setting_name: settingType,
      setting_value: String(value),
    });
  };

  const trackOnboardingStep = (step: OnboardingStepType) => {
    baseTrack("onboarding_step_view", { step });
  };

  const trackOnboardingAnswer = (
    step: OnboardingStepType,
    answer: { answers?: any; other?: string }
  ) => {
    const answerValue = Array.isArray(answer.answers)
      ? answer.answers
      : answer.answers;

    baseTrack("onboarding_step_answer", {
      step,
      answer: answerValue,
      ...(answer.other && { answer_other: answer.other }),
    });
  };

  const trackOnboardingCompleted = (
    onboardingState: Record<OnboardingStepType, any>
  ) => {
    const simplifiedAnswers = Object.keys(onboardingState).reduce(
      (acc, step) => {
        const currentStep = step as OnboardingStepType;
        acc[currentStep] = onboardingState[currentStep]?.answers || "";
        return acc;
      },
      {} as Record<OnboardingStepType, string>
    );

    baseTrack("onboarding_complete", { answers: simplifiedAnswers });
  };

  return {
    trackPageView,
    identifyUser,
    trackSignupStarted,
    trackSignupCompleted,
    trackProjectStarted,
    trackProjectSettingUpdated,
    trackOnboardingStep,
    trackOnboardingAnswer,
    trackOnboardingCompleted,
  };
};

export const PageViewTracker: React.FC = () => {
  const location = useLocation();
  const { trackPageView } = useAnalytics();
  // todo : wait for user to be identified ???

  useEffect(() => {
    trackPageView();
  }, [location]);

  // fallback value if not defined in Route
  return <PageTitle />;
};
