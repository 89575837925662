import { Flex, Tag } from "antd";
import { FontPreviewText } from "./FontPreview";
import React, { CSSProperties } from "react";

export const FontPreviewWithTag = ({
  fontPath,
  color = "black",
  label,
  styles,
}: {
  fontPath: string;
  color?: string;
  label: string | React.ReactNode;
  styles?: CSSProperties;
}) => {
  return (
    <Flex align="center" justify="space-between">
      <FontPreviewText
        font={fontPath}
        text={"Example"}
        style={{ fontSize: 14, padding: "10px 0px" }}
      />
      <Tag
        bordered={false}
        color={color}
        style={{
          textTransform: "uppercase",
          fontSize: 8,
          letterSpacing: 2.5,
          padding: "2px 5px",
          lineHeight: 1,
          ...styles,
        }}
      >
        {label}
      </Tag>
    </Flex>
  );
};
