import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init<object>({
    ns: ["common", "page-titles"],
    returnEmptyString: true,
    supportedLngs: ["en", "fr"],
    defaultNS: "common",
    fallbackLng: ["en"],
    ignoreJSONStructure: true,
    load: "all",
    detection: {
      order: ["localStorage", "navigator"],
    },
  });

export default i18n;
export const t = i18n.t.bind(i18n);
