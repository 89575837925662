import { useTranslate } from "@refinedev/core";
import { useCreate, useGo, useOne, useUpdate } from "@refinedev/core";
import {
  Form,
  Input,
  Typography,
  Flex,
  Button,
  Steps,
  Space,
  Spin,
  Result,
  Progress,
} from "antd";
import {
  BookOpenText,
  Swatches,
  Files,
  HourglassHigh,
  MaskHappy,
  Translate,
  UsersThree,
  SealCheck,
  Video,
  Exclude,
  Sparkle,
} from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";

import { ProjectDocumentUpload } from "./ProjectDocumentUpload";
import { MediaProject, MediaProjectResponse } from "pages/media/types";
import { AiStarIcon } from "components/icons";
import { useAntTheme } from "hooks/useAntTheme";
import { OrganizationSwitch } from "components/OrganizationSwitch";
import { WizardStepHeader } from "./WizardStepHeader";
import {
  ProjectPromptTemplates,
  ProjectSettingForm,
  PromptTemplate,
} from "./ProjectSettingForm";
import styled from "styled-components";
import { CascadingText } from "components/CascadingText";
import { SettingsApprovalScreen } from "./SettingsApproval";
import { useParams } from "react-router-dom";
import { guessProjectTitleFromDocument } from "../../utils";
import { useOrganization } from "hooks/useOrganization";
import { CheckCircleOutlined } from "@ant-design/icons";
import { EllipsisAnimated } from "components/EllipsisAnimated";
import { ExpandableAlertInfoMarkdown } from "components/ExpandableAlert";
import { t } from "i18n";
import { useProjectWizardStore } from "../useProjectWizardStore";
import { AppContext } from "appContext";
import { useAnalytics } from "hooks/useAnalytics";
const { Text } = Typography;

// const requiredSettingsKeys = ["workflow", "language", "duration", "status"];
// const settingsAreValid = (settings: { [key: string]: any }) => {
//   for (const key of requiredSettingsKeys) {
//     if (!settings[key]) return false;
//   }
//   return true;
// };

const allSteps = [
  // remove support for Upload form to make code simpler
  // "Upload",
  "Confirm",
  "Experience",
  "Language",
  "Pages",
  "ContentExtraction",
  "Duration",
  "Tone",
  "BrandKit",
  "Transitions",
  "Contact",
  "Review",
];

export type Step = (typeof allSteps)[number];
const maxWidth = 750;
const FIVE_MINUTES = 5 * 60 * 1000;

const stepBanners: Record<Step, string | null> = {
  Upload:
    "https://images.unsplash.com/photo-1672239272089-250c32c3e2e2?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  Confirm:
    "https://images.unsplash.com/photo-1633596683562-4a47eb4983c5?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  Experience:
    "https://images.unsplash.com/photo-1658409009905-00a0f737bdf3?q=80&w=2500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  Language:
    "https://images.unsplash.com/photo-1633886038302-9710437f6ca2?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  Pages:
    "https://images.unsplash.com/photo-1633596683562-4a47eb4983c5?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

  ContentExtraction:
    "https://images.unsplash.com/photo-1717501218385-55bc3a95be94?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  Duration:
    "https://images.unsplash.com/photo-1717501218636-a390f9ac5957?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  Tone: "https://images.unsplash.com/photo-1636690568786-7e83bd2bd789?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  // "Presenter",
  Contact:
    "https://images.unsplash.com/photo-1642615835477-d303d7dc9ee9?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  BrandKit:
    "https://images.unsplash.com/photo-1672239272089-250c32c3e2e2?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  Transitions:
    "https://images.unsplash.com/photo-1672239272089-250c32c3e2e2?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

  Review:
    "https://images.unsplash.com/photo-1633596683562-4a47eb4983c5?q=70&w=1600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
};

const ProjectStatus = {
  WAITING_FOR_SETTINGS: "WaitingForSettings",
  NEW: "New",
  ANALYZING_DOCUMENT: "AnalyzingDocument",
};

const ProjectProgress = ({ project }: { project: MediaProject }) => {
  const t = useTranslate();
  const [processPercent, setProcessPercent] = useState(0);
  // const { theme } = useAntTheme();
  //   const { status: liveDocumentStatus } = useDocumentStatus(
  //     data?.data?.documents[0]
  //   );

  const StatusMessages: {
    [key: string]: string;
  } = {
    // not used ?
    // [ProjectStatus.WAITING_FOR_SETTINGS]: "Waiting for settings",
    [ProjectStatus.NEW]: t(
      "projects.components.ProjectCreateWizard.creatingProject"
    ),
    [ProjectStatus.ANALYZING_DOCUMENT]: t(
      "projects.components.ProjectCreateWizard.analyzingDocument"
    ),
  };

  useEffect(() => {
    if (!isDocumentProcessing(project.status ?? "")) {
      setProcessPercent(100);
    }
  }, [project.status]);

  useEffect(() => {
    if (processPercent < 100) {
      // simulate progress
      setTimeout(() => {
        if (isDocumentProcessing(project.status ?? "")) {
          setProcessPercent((value) => value + 1);
        }
      }, FIVE_MINUTES / 100);
    }
  }, [processPercent]);

  return (
    <Flex vertical>
      <Typography.Text type="secondary">
        <CascadingText
          key={project?.status ?? "none"}
          delay={0}
          cascade
          damping={5e-2}
        >
          {isDocumentProcessing(project?.status ?? "") ? (
            <>
              {StatusMessages[project.status ?? ""]}
              <EllipsisAnimated />
            </>
          ) : (
            <Space>
              <CheckCircleOutlined />
              {t("projects.components.ProjectCreateWizard.documentProcessed")}
            </Space>
          )}
        </CascadingText>
      </Typography.Text>
      <Progress
        style={{ width: "100%" }}
        showInfo={false}
        percent={processPercent}
        strokeColor={{ from: "#C13BF1", to: "#4900E5" }}
      />
    </Flex>
  );
};

const isDocumentProcessing = (status: string) => {
  return [ProjectStatus.ANALYZING_DOCUMENT, ProjectStatus.NEW].includes(status);
};

export const ProjectCreateWizard = () => {
  const params = useParams();
  const { dispatch } = useContext(AppContext);
  const { trackProjectSettingUpdated } = useAnalytics();

  const { organization, hasMultipleOrganizations } = useOrganization({});
  const go = useGo();
  const stateProject = useProjectWizardStore((state) => state.project);
  const [project, setProject] = useState<MediaProject | null>(stateProject);
  const [projectId, setProjectId] = useState<string>();
  const isEdit = Boolean(params.id);
  const isSetupStoryboard = params.setupType === "storyboard";
  let steps: Step[] = allSteps;
  if (isSetupStoryboard) {
    steps = [
      "Language",
      "Pages",
      "ContentExtraction",
      "Duration",
      "Tone",
      "Review",
    ];
  }
  //  else if (isEdit) {
  //   steps = allSteps.slice(1);
  // }
  // console.debug({ steps });
  const initialStep: Step = steps[0];

  const { setStep, step, setFile } = useProjectWizardStore((state) => ({
    setStep: state.setStep,
    step: state.step,
    setFile: state.setFile,
  }));
  const stepIndex = steps.findIndex((x) => x === step);
  const [maxStepIndexReached, setMaxStepIndexReached] = useState(0);
  const { mutateAsync: create } = useCreate<MediaProject>();
  const { mutateAsync: update } = useUpdate<MediaProject>();
  const [isPollingForProject, setIsPollingForProject] = useState(false);
  const [finished, setFinished] = useState(false);
  const { theme } = useAntTheme();
  const { Text, Paragraph, Title } = Typography;
  const id = project?.id || projectId;
  const {
    data: projectData,
    isSuccess: projectDataSuccess,
    isLoading: projectDataLoading,
  } = useOne<MediaProject>({
    resource: "media/projects",
    id,
    queryOptions: {
      enabled: id && (isEdit || isPollingForProject),
      refetchInterval: (data) => {
        if (
          data?.data.status &&
          isDocumentProcessing(data.data.status) &&
          isPollingForProject
        ) {
          return 10000; // 10s
        } else {
          return false;
        }
      },
    },
  });
  const [form] = Form.useForm();
  const [showHint, setShowHint] = useState(true);

  const createProject = async (filenames: string[]) => {
    const title = guessProjectTitleFromDocument(filenames);
    // todo placeholder project name
    const data = await create({
      resource: "media/projects",
      values: {
        title,
        organization_id: organization?.id,
        type: "new_video",
      },
      successNotification() {
        return false;
      },
    });
    setProject(data.data);
    return data.data;
  };

  const onUploadSuccess = () => {
    setIsPollingForProject(true);
    setStep("Confirm");
  };

  const onUploadAbort = () => {
    setIsPollingForProject(false);
  };

  const handleSubmitTitle = async (values: {
    title: string;
    description: string;
  }) => {
    trackProjectSettingUpdated(
      project?.id,
      "project_objective",
      "",
      "project_creation_wizard"
    );
    try {
      // update project title
      await update({
        resource: "media/projects",
        id: project?.id!,
        values: {
          ...values,
        },
        successNotification() {
          return false;
        },
      });
      if (finished) {
        goToProject();
        return;
      }
      nextStep();
    } catch (e) {
      setFinished(false);
    }
  };
  // deprecated ?
  //   const minimumDate = dayjs().add(7, "day");
  const submitAndFinish = () => {
    setFinished(true);
    form.submit();
  };
  const goToProject = () => {
    go({
      to: { resource: "media_projects", id: project?.id!, action: "show" },
    });
  };

  const nextStep = () => {
    const newStepIndex = stepIndex + 1;
    if (newStepIndex > maxStepIndexReached)
      setMaxStepIndexReached(newStepIndex);
    setStep(steps[newStepIndex]);
  };
  const previousStep = () => {
    setStep(steps[stepIndex - 1]);
  };

  useEffect(() => {
    if (params.id) {
      setProjectId(params.id);
      // console.debug({ initialStep });
      // setStep(initialStep);
    }
  }, [params.id]);

  useEffect(() => {
    setFile(null);
    setStep(initialStep);
    return () => {
      setStep(initialStep); // init step
    };
  }, []);

  // when editing
  useEffect(() => {
    if (projectDataSuccess) {
      setProject(projectData.data);
    }
  }, [projectDataSuccess, projectData]);

  useEffect(() => {
    dispatch({ type: "setSidebarCollapsed", payload: true });

    return () => {
      dispatch({ type: "setSidebarCollapsed", payload: false });
    };
  }, []);

  const emptyRequiredMark = (
    label: React.ReactNode,
    {
      required,
    }: {
      required: boolean;
    }
  ) => <>{label}</>;
  return (
    <Flex>
      <Flex vertical gap={40} style={{ width: "100%" }}>
        {/* dummy space to avoid flickering of empty div when no content */}{" "}
        {projectDataLoading && projectId && <Spin spinning fullscreen />}
        <StepBanner url={stepBanners[step]} />
        <Flex
          vertical
          gap={40}
          style={{
            maxWidth,
            width: maxWidth,
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          {step === "Upload" && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.uploadYourDocument"
                )}
              />
              <ExpandableAlertInfoMarkdown
                title={t(
                  "projects.components.ProjectCreateWizard.uploadDocumentWelcome.title"
                )}
                text={t(
                  "projects.components.ProjectCreateWizard.uploadDocumentWelcome.text"
                )}
              />
              {hasMultipleOrganizations && (
                <Flex vertical gap={5}>
                  <Text type="secondary">
                    {t("projects.components.ProjectCreateWizard.organization")}
                  </Text>
                  <OrganizationSwitch />
                </Flex>
              )}

              <ProjectDocumentUpload
                onFileAdded={(file) => setFile(file)}
                onUploadAbort={onUploadAbort}
                onUploadSuccess={onUploadSuccess}
                onSubmit={createProject}
                submitButtonProps={{
                  type: "primary",
                  shape: "round",
                  size: "large",
                  style: {
                    marginTop: 30,
                    marginLeft: "auto",
                    display: "block",
                  },
                  icon: null,
                }}
                submitButtonLabel={t("buttons.next")}
                showSuccessNotification={false}
                acceptedMimeTypes="application/pdf"
              />
            </>
          )}
          {step === "Confirm" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />

              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmitTitle}
                initialValues={project}
                requiredMark={emptyRequiredMark}
              >
                <Form.Item
                  label={t(
                    "projects.components.ProjectCreateWizard.confirmYourProject"
                  )}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "projects.components.ProjectCreateWizard.aProjectName"
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    style={{ maxWidth }}
                    suffix={
                      <Sparkle
                        color={theme.colorPrimary}
                        weight="fill"
                        size={24}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginTop: 50 }}
                  rules={[
                    {
                      pattern: new RegExp(/^(?!.*\[[^[\]]+\]).*$/s),
                      message: t(
                        "projects.components.ProjectCreateWizard.fillValuesBrackets"
                      ),
                    },
                  ]}
                  label={t(
                    "projects.components.ProjectCreateWizard.describeYourGoal"
                  )}
                  name="description"
                >
                  <Input.TextArea
                    placeholder={t(
                      "projects.components.ProjectCreateWizard.optional"
                    )}
                    size="large"
                    rows={4}
                    style={{ maxWidth }}
                  />
                </Form.Item>

                <Flex vertical gap={30} style={{ marginTop: -20 }}>
                  <ProjectPromptTemplates
                    value={undefined}
                    onChange={(value: PromptTemplate) => {
                      form.setFieldValue("description", value.prompt);
                    }}
                  />
                  {/* 
                    <Alert
                      type="info"
                      showIcon
                      icon={<LightbulbFilament size={40} />}
                      description={
                        <Flex vertical gap={10}>
                          <Typography.Text>
                            {t(
                              "projects.components.ProjectCreateWizard.example"
                            )}
                          </Typography.Text>
                          <Typography.Text type="secondary" copyable>
                            {t(
                              "projects.components.ProjectCreateWizard.weWantTo"
                            )}
                          </Typography.Text>
                        </Flex>
                      }
                    /> */}
                </Flex>
                <Flex justify="space-between">
                  <Button
                    htmlType="submit"
                    type="primary"
                    shape="round"
                    size="large"
                    style={{ marginTop: 30, marginLeft: "auto" }}
                  >
                    {t("buttons.next")}
                  </Button>
                </Flex>
              </Form>
            </>
          )}
          {step === "Experience" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.chooseTypeOfExperience"
                )}
              />
              <ProjectSettingForm<string>
                projectId={project?.id}
                title={t("projects.components.ProjectCreateWizard.experience")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.selectExperience"
                )}
                icon={<Video size={24} />}
                value={getExperienceValueFromProject(project)}
                type="Experience"
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
                cancellable={false}
              />
            </>
          )}
          {step === "Language" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />
              <ProjectSettingForm<string>
                projectId={project?.id!}
                title={t("projects.components.ProjectCreateWizard.Language")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheLanguage"
                )}
                icon={<Translate size={24} />}
                value={project?.settings.language} // default from org
                type="Language"
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
                cancellable={false}
              />
            </>
          )}
          {step === "Pages" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />
              <ProjectSettingForm<string>
                projectId={project.id!}
                title={t("projects.components.ProjectCreateWizard.Pages")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseThePages"
                )}
                icon={<Files size={24} />}
                type="Pages"
                value={project.settings.selected_pages}
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "ContentExtraction" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />
              <ProjectSettingForm<boolean>
                projectId={project.id!}
                title={t(
                  "projects.components.ProjectCreateWizard.ContentExtraction"
                )}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseYourType"
                )}
                extra={
                  <ExpandableAlertInfoMarkdown
                    title={t(
                      "projects.components.ProjectCreateWizard.contentExtractionHint.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.contentExtractionHint.text"
                    )}
                  />
                }
                icon={<SealCheck size={24} />}
                // TODO default to false until backend change None to false
                value={project.settings.preserve_original_doc ?? false}
                type="PreserveDocument"
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "Duration" && project && (
            <StyledDurationSection
              display={showHint ? "1" : "0"}
              gap={40}
              vertical
            >
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />
              <ProjectSettingForm<number>
                projectId={project?.id!}
                title={t("projects.components.ProjectCreateWizard.Duration")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheDuration"
                )}
                extra={
                  <ExpandableAlertInfoMarkdown
                    title={t(
                      "projects.components.ProjectCreateWizard.contentDuration.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.contentDuration.text"
                    )}
                    onExpand={() => {
                      setShowHint(false);
                      setTimeout(() => {
                        setShowHint(true);
                      }, 500);
                    }}
                  />
                }
                icon={<HourglassHigh size={24} />}
                value={project?.settings.duration}
                type="Duration"
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
              />
            </StyledDurationSection>
          )}
          {step === "Tone" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />
              <ProjectSettingForm<string>
                projectId={project?.id!}
                title={t("projects.components.ProjectCreateWizard.Tone")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheTone"
                )}
                icon={<MaskHappy size={24} />}
                value={project.settings.tone_id}
                type="Tone"
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                // onClose={() => goToProject()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "Contact" && (
            /* project &&  */ <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />
              <ProjectSettingForm<string>
                projectId={project?.id!}
                isWizard
                isEdit={isEdit}
                title={t("projects.components.ProjectCreateWizard.Contact")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheContact"
                )}
                extra={
                  <ExpandableAlertInfoMarkdown
                    title={t(
                      "projects.components.ProjectCreateWizard.chooseContactHint.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.chooseContactHint.text"
                    )}
                  />
                }
                icon={<UsersThree size={24} />}
                value={project?.settings?.contact_id}
                type="Contacts"
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {step === "BrandKit" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />
              <ProjectSettingForm<string>
                projectId={project.id!}
                title={t("projects.components.ProjectCreateWizard.BrandKit")}
                subtitle={t(
                  "projects.components.ProjectCreateWizard.chooseTheBrand"
                )}
                extra={
                  <ExpandableAlertInfoMarkdown
                    title={t(
                      "projects.components.ProjectCreateWizard.brandKitHint.title"
                    )}
                    text={t(
                      "projects.components.ProjectCreateWizard.brandKitHint.text"
                    )}
                  />
                }
                icon={<Swatches size={24} />}
                type="BrandKit"
                value={project.settings.brand_kit_id}
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
                // show={{ transitions: true }}
              />
            </>
          )}
          {step === "Transitions" && project && (
            <>
              <WizardStepHeader
                title={t(
                  "projects.components.ProjectCreateWizard.title." + step
                )}
              />
              <ProjectSettingForm<string>
                projectId={project.id!}
                title={t("projects.components.ProjectSettings.transition")}
                subtitle={t(
                  "projects.components.ProjectSettings.chooseTransition"
                )}
                icon={<Exclude size={24} />}
                type="Transitions"
                value={project.settings.transitions ?? "Basic"}
                onClose={() => nextStep()}
                onCancel={() => previousStep()}
                cancelButtonLabel={t("buttons.back")}
                submitButtonLabel={t("buttons.next")}
              />
            </>
          )}
          {project && (
            <>
              {step === "Review" && (
                <>
                  <WizardStepHeader
                    title={t(
                      "projects.components.ProjectCreateWizard.title." + step
                    )}
                  />
                  {project.settings.status === "Approved" ? (
                    <Result
                      status="success"
                      icon={<BookOpenText size={100} />}
                      title={t(
                        "projects.components.ProjectCreateWizard.thanksYourRequirements"
                      )}
                      subTitle={t(
                        "projects.components.ProjectCreateWizard.weWillGenerate"
                      )}
                      extra={[
                        <Button
                          size="large"
                          key="new_project"
                          shape="round"
                          onClick={() =>
                            go({
                              to: {
                                resource: "media_projects",
                                action: "list",
                              },
                            })
                          }
                        >
                          {t(
                            "projects.components.ProjectCreateWizard.createAnotherProject"
                          )}
                        </Button>,
                        <Button
                          size="large"
                          type="primary"
                          key="project"
                          shape="round"
                          onClick={goToProject}
                        >
                          {t(
                            "projects.components.ProjectCreateWizard.openProject"
                          )}
                        </Button>,
                      ]}
                    />
                  ) : (
                    <SettingsApprovalScreen
                      setStep={setStep}
                      previousStep={previousStep}
                      projectId={project?.id!}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>
      <StyledSide
        vertical
        align="center"
        style={{
          margin: -theme.paddingLG, //24
          marginLeft: 0,
          width: "24vw",
          minWidth: 250,
          padding: theme.paddingLG * 2,
          paddingTop: theme.paddingLG,
          height: "100%",
          minHeight: "100vh",
          background: "linear-gradient(0deg, #3BD4CA33 0%, #3BD4CA10 100%)",
        }}
      >
        <Flex vertical gap={60} style={{ width: "100%" }}>
          {!isEdit && <ProjectProgress project={project} />}
          {!isEdit && (
            <Flex vertical>
              <Typography.Title level={4} type="secondary">
                {t("projects.components.ProjectCreateWizard.createYourProject")}
              </Typography.Title>
              <Space size="large">
                <AiStarIcon width={26} />
                <Typography.Text>
                  {t(
                    "projects.components.ProjectCreateWizard.weWillAutomatically"
                  )}
                </Typography.Text>
              </Space>
            </Flex>
          )}
          {/* {stepIndex >= 2 && ( */}
          <Steps
            style={{ minHeight: "50vh", width: "auto" }}
            items={
              // project &&
              steps.map((step, index) => {
                const title = t(
                  "projects.components.ProjectCreateWizard.step." +
                    step.split(" ").join("")
                );
                return {
                  title,
                  // never reshow Upload form
                  // prevent users to force to next steps during creation
                  disabled:
                    step === "Upload" ||
                    (!isEdit && maxStepIndexReached < index),
                };
              })
            }
            direction="vertical"
            current={stepIndex}
            onChange={(value) => {
              setStep(steps[value]);
              // console.debug(steps[value]);
            }}
          />
          {/* )} */}
        </Flex>
      </StyledSide>
    </Flex>
  );
};

const StyledSide = styled(Flex)`
  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    // background-image: url("https://images.unsplash.com/photo-1634017839464-5c339ebe3cb4?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
  }
`;

interface StyledDurationSectionProps {
  display: string;
}

const StyledDurationSection = styled(Flex)<StyledDurationSectionProps>`
  .ant-tooltip {
    opacity: ${(props) => {
      return props.display;
    }};
    transition: opacity 0.3s ease-in-out;
  }
`;

interface StyledStepBannerProps {
  url: string;
}

const StepBanner = styled.div<StyledStepBannerProps>`
  height: 20vh;
  max-height: 180px;
  margin: -24px;
  margin-right: 0;
  background-image: ${(props) => `url(${props.url})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &:after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #00000000, #000000aa);
  }
`;

const getExperienceValueFromProject = (project?: MediaProjectResponse) => {
  if (!project) return;
  const { media_generation_mode, include_document_animations, chat_mode } =
    project.settings;
  if (
    include_document_animations === false &&
    media_generation_mode === "Video" &&
    chat_mode === "Disabled"
  ) {
    return "video";
  } else if (
    include_document_animations === true &&
    media_generation_mode === "Video" &&
    chat_mode === "Disabled"
  ) {
    return "video_doc";
  } else if (
    include_document_animations === true &&
    media_generation_mode === "Video" &&
    chat_mode !== "Disabled"
  ) {
    return "video_doc_chat";
  }
};
