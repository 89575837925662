import { notification } from "antd";
import { useTranslate } from "@refinedev/core";
import { TDetailedStoryboard, StoryboardAsset } from "pages/media/types";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import {
  storyboardDetailedToStoryboard,
  stripId,
} from "../../storyboard/editor/helpers";

interface UseSaveStoryboardProps {
  projectId: string;
  organizationId: string;
  storyboardId?: string;
  storyboard: TDetailedStoryboard;
  documentId?: string;
}

export const useSaveStoryboard = ({
  projectId,
  organizationId,
  storyboardId,
  storyboard,
  documentId,
}: UseSaveStoryboardProps) => {
  const t = useTranslate();
  const [api] = notification.useNotification();
  const canSave = Boolean(storyboard);

  const { filterAssets, saveProjectAsset, projectAssets } =
    useMediaAssetsStorage({
      organizationId: organizationId,
      projectId: projectId,
      enabled: Boolean(projectId),
    });

  const storyboardAsset = projectAssets
    ? filterAssets<StoryboardAsset>(projectAssets as StoryboardAsset[], {
        asset_type: "Storyboard",
        id: storyboardId,
      })?.[0]
    : null;

  const saveStoryboard = async () => {
    if (!canSave || !documentId) {
      return;
    }

    try {
      await saveProjectAsset(
        "Storyboard",
        storyboardDetailedToStoryboard(stripId(storyboard)),
        storyboardAsset?.id,
        {
          document_id: documentId,
        }
      );

      api.success({
        message: t("components.storyboard.StoryboardEditor.saveSuccessful"),
        description: t(
          "components.storyboard.StoryboardEditor.changesHaveBeen"
        ),
        placement: "bottomRight",
      });
    } catch (error) {
      api.error({
        message: t("components.storyboard.StoryboardEditor.saveFailed"),
        description: t("components.storyboard.StoryboardEditor.failedToSave"),
        placement: "bottomRight",
      });
    }
  };

  return { saveStoryboard };
};
