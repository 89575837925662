import { useTranslate } from "@refinedev/core";
import { useContext, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  ITreeMenu,
  CanAccess,
  useIsExistAuthentication,
  useLogout,
  useMenu,
  useWarnAboutChange,
  useIsAuthenticated,
} from "@refinedev/core";
import { Link, useMatch } from "react-router-dom";
import { Sider, ThemedTitleV2 } from "@refinedev/antd";
import { Layout as AntdLayout, Menu, Grid, theme, Button, Flex } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { ThemeSwitch } from "components/ThemeSwitch";
import { OrganizationSwitch } from "components/OrganizationSwitch";
import { ColorModeContext } from "contexts";
import { AppContext } from "appContext";
import styled from "styled-components";
import { Sidebar, SidebarSimple, SignOut } from "@phosphor-icons/react";
import { UserMenu } from "components/UserMenu";
import { resetAllSlices } from "store";
import { AppLogo } from "components/AppLogo";
import { useAntTheme } from "hooks/useAntTheme";
import { getAppTitle, isDocustream } from "helpers/utils";
import { changeLanguage } from "i18next";
import { WorkspaceSwitch } from "components/WorkspaceSwitch";
const { useToken } = theme;

export const CustomSider: typeof Sider = ({ render }) => {
  const t = useTranslate();
  const { token } = useToken();
  const {
    dispatch,
    state: { sidebarCollapsed: collapsed, appFlavor, hasAccesses, user },
  } = useContext(AppContext);

  const isExistAuthentication = useIsExistAuthentication();
  const { data: authenticated } = useIsAuthenticated();
  const isAuthenticated = authenticated?.authenticated;
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const { mutate: mutateLogout } = useLogout();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { SubMenu } = Menu;
  const { mode } = useContext(ColorModeContext);
  const isOnboarding = useMatch("/onboarding");
  const breakpoint = Grid.useBreakpoint();
  const { theme } = useAntTheme();

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { name, children, meta, key, list } = item;

      const icon = meta?.icon;
      const label = meta?.label ?? name;
      const parent = meta?.parent;
      const route =
        typeof list === "string"
          ? list
          : typeof list !== "function"
            ? list?.path
            : key;

      if (children.length > 0) {
        return (
          <SubMenu
            key={route}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }

      const isSelected = route === selectedKey || key === selectedKey;
      const isRoute = !(parent !== undefined && children.length === 0);
      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{ resource: item }}
        >
          <Menu.Item
            key={route}
            className={isSelected ? "ant-menu-item-selected" : undefined}
            style={{
              paddingLeft: !collapsed ? 24 : undefined,
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            {route ? <Link to={route || "/"}>{label}</Link> : label}
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  const queryClient = useQueryClient();
  const handleLogout = () => {
    const confirm = true;
    if (warnWhen) {
      const confirm = window.confirm(t("warnWhenUnsavedChanges"));
    }
    if (confirm) {
      setWarnWhen(false);
      mutateLogout();
      // Reset stale user data
      queryClient.invalidateQueries(["api/users/me", "organizations"]);
      // Reset Zustand store
      resetAllSlices();
      localStorage.clear();
      changeLanguage();
    }
  };

  const logout = isExistAuthentication && isAuthenticated && (
    <Button
      block
      type="text"
      onClick={handleLogout}
      style={{ justifyContent: "start" }}
      icon={
        <span className="anticon">
          <SignOut />
        </span>
      }
    >
      {t("buttons.logout", "Logout")}
    </Button>
  );
  const isCreateOrganization = useMatch("/organization/create");
  const items = renderTreeView(
    isAuthenticated && hasAccesses && !isOnboarding && !isCreateOrganization
      ? menuItems
      : [],
    selectedKey
  );

  const renderSider = () => {
    if (render) {
      return render({
        dashboard: null,
        items,
        logout,
        collapsed,
      });
    }
    return <>{items}</>;
  };

  const siderStyle = isMobile ? antLayoutSiderMobile : antLayoutSider;
  const appTitle = useMemo(getAppTitle, []);

  return (
    // <Authenticated key="sider" fallback={null}>
    // </Authenticated>
    <AntdLayout.Sider
      width={250}
      collapsible
      collapsedWidth={isMobile ? 0 : 60}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed: boolean): void =>
        dispatch({ type: "setSidebarCollapsed", payload: collapsed })
      }
      style={{
        ...siderStyle,
        backgroundColor: token.colorBgContainer,
        borderRight: `1px solid ${token.colorBgElevated}`,
        // height: "100vh",
        // ...(!isMobile ? { position: "fixed" } : {}),
      }}
      trigger={
        !isMobile && (
          <Button
            type="text"
            style={{
              borderRadius: 0,
              height: "100%",
              width: "100%",
              backgroundColor: token.colorBgContainer,
            }}
          >
            {collapsed ? (
              <SidebarSimple
                size={24}
                style={{
                  color: token.colorText,
                  zoom: 1.2,
                  opacity: 0.8,
                }}
              />
            ) : (
              <Sidebar
                size={24}
                style={{
                  color: token.colorText,
                  zoom: 1.2,
                  opacity: 0.8,
                }}
              />
            )}
          </Button>
        )
      }
    >
      <div
        style={{
          width: collapsed ? "60px" : "240px",
          padding: collapsed ? "0" : "0 16px",
          display: "flex",
          justifyContent: collapsed ? "center" : "flex-start",
          alignItems: "center",
          height: "64px",
          // backgroundColor: token.colorBgElevated,
          fontSize: "14px",
        }}
      >
        <ThemedTitleV2
          wrapperStyles={{ marginLeft: collapsed ? 0 : 10 }}
          collapsed={collapsed}
          text={appTitle}
          icon={
            <AppLogo
              color={theme.colorPrimary}
              fill={theme.colorText}
              height={26}
            />
          }
        />
      </div>
      {isAuthenticated && !collapsed && (
        <Flex style={{ padding: 20 }} vertical gap={10}>
          <OrganizationSwitch />
          <WorkspaceSwitch />
        </Flex>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <StyledMenu
            defaultOpenKeys={[...defaultOpenKeys, "/media"]}
            selectedKeys={[selectedKey]}
            mode="inline"
            style={{
              marginTop: "8px",
              border: "none",
            }}
            onClick={() => {
              if (!breakpoint.lg) {
                dispatch({ type: "setSidebarCollapsed", payload: collapsed });
              }
            }}
          >
            {renderSider()}
          </StyledMenu>
        </div>

        {!collapsed && (
          <>
            {/* {isAuthenticated && <Divider />} */}
            <Flex
              style={{ padding: 20, paddingTop: 20, marginBottom: "100px" }}
              gap={"large"}
              vertical
            >
              <div
              // style={{ display: "flex", justifyContent: "center" }}
              >
                <ThemeSwitch />
              </div>
            </Flex>
          </>
        )}
      </div>
      {isAuthenticated && user && (
        <UserMenu collapsed={collapsed} user={user} logout={logout} />
      )}
    </AntdLayout.Sider>
  );
};

const StyledMenu = styled(Menu)`
  .ant-menu-item-icon svg,
  // from phosphor
  svg.ant-menu-item-icon {
    height: 24px;
    width: 24px;
  }

  .ant-menu-sub {
    background: transparent !important;
  }
  .ant-menu-item {
    font-size: 16px;
  }
  &:not(.ant-layout-sider-collapsed) {
    .ant-menu-item {
      font-size: 16px;
      height: 50px;
    }
    .ant-menu-item-icon {
      margin-right: 6px;
    }
  }
  .ant-menu-submenu-title {
    font-size: 12px;
    letter-spacing: 3px;
    // line-height: 30px !important;
    padding-right: 0 !important;
    text-transform: uppercase;
    margin-bottom: -5px;
    .ant-menu-submenu-arrow {
      display: none;
    }
  }
  .ant-menu-item:not(.ant-menu-item-selected) {
    opacity: 0.6;
  }
`;
