import { TemplateItem } from "types";
import { NewImage, TemplateType } from "../types";
import { hexToRgba } from "../helpers/helper";
import { NULL_COLOR } from "./useGetTemplateAssets";
import { useVolatileBoundStore } from "store";
import { PREVIEW_ADJUSTMENT } from "../TemplatePreview";

export const useTemplateItems = () => {
  const {
    images,
    storeBackground,
    view,
    storeIntro,
    storeTransition,
    titleStyle,
    textStyle,
    presenter,
    subtitlesAnimation,
    storeClosing,
  } = useVolatileBoundStore((store) => ({
    images: store.templateState.images,
    storeBackground: store.templateState.background,
    view: store.templateState.view,
    storeIntro: store.templateState.intro,
    storeTransition: store.templateState.transition,
    titleStyle: store.templateState.titleStyle,
    textStyle: store.templateState.textStyle,
    presenter: store.templateState.presenter,
    subtitlesAnimation: store.templateState.subtitlesAnimation,
    storeClosing: store.templateState.closing,
  }));

  const items: TemplateItem[] = [];

  const getIntroductionItems = () => {
    if (storeIntro.enable && storeIntro.assetId) {
      const introItem: TemplateItem = {
        graphics: generateCustomAssetVideo(storeIntro.assetId),
        attributes: generateFullScreenAttributes(),
      };

      items.push(introItem);

      const imageItems: TemplateItem[] = generateImageItems(images);
      items.push(...imageItems);
    }
  };

  const getTransitionItems = () => {
    if (!storeTransition.enable) return;

    let graphicsType;

    if (storeTransition.view === "Video") {
      graphicsType = generateCustomAssetVideo(storeTransition.assetId);
    } else if (storeTransition.view === "Picture") {
      graphicsType = generateImage(storeBackground.bodyImageAsset);
    } else if (storeTransition.view === "Color") {
      graphicsType = generateRectangle(storeBackground.bodyColor ?? "#ffffff");
    }

    const transitionItem: TemplateItem = {
      graphics: graphicsType,
      attributes: generateFullScreenAttributes(),
    };

    items.push(transitionItem);

    const imageItems: TemplateItem[] = generateImageItems(images);
    items.push(...imageItems);

    const titleItem: TemplateItem = {
      graphics: generateTextItem(
        titleStyle.fontAssetId,
        titleStyle.fontSize,
        titleStyle.title_color
      ),
      attributes: generateCommonAttributes(
        titleStyle.position.x,
        titleStyle.position.y,
        titleStyle.size.width,
        titleStyle.size.height
      ),
    };

    if (titleStyle.fadeIn) {
      titleItem.attributes.push(generateFadeInAttributes());
    }
    if (titleStyle.fadeOut) {
      titleItem.attributes.push(generateFadeOutAttributes());
    }

    if (titleStyle.titleBackground) {
      const titleBackgroundRect: TemplateItem = {
        name: "titleBackground",
        graphics: generateRectangle(titleStyle.titleBackground),
        attributes: [
          ...generateCommonAttributes(
            titleStyle.position.x,
            titleStyle.position.y,
            titleStyle.size.width,
            titleStyle.size.height,
            titleStyle.fadeIn,
            titleStyle.fadeOut
          ),
        ],
      };

      if (titleStyle.fadeIn) {
        titleBackgroundRect.attributes.push(generateFadeInAttributes());
      }
      if (titleStyle.fadeOut) {
        titleBackgroundRect.attributes.push(generateFadeOutAttributes());
      }

      items.push(titleBackgroundRect);
    }

    items.push(titleItem);
  };

  const generateChapterItemHeaderGraphics = () => {
    if (
      storeBackground.headerView === "Picture" &&
      storeBackground.headerImageAsset
    )
      return generateImage(storeBackground.headerImageAsset, false);
    return generateRectangle(storeBackground.headerColor ?? "#ffffff");
  };

  const getChapterItems = () => {
    const header: TemplateItem = {
      name: "headerRectangle",
      graphics: generateChapterItemHeaderGraphics(),
      attributes: generateHeaderAttributes(),
    };

    let bodyGraphics;

    if (
      storeBackground.bodyView === "Video" &&
      storeBackground.bodyVideoAsset
    ) {
      bodyGraphics = generateCustomAssetVideo(storeBackground.bodyVideoAsset);
    } else if (
      storeBackground.bodyView === "Picture" &&
      storeBackground.bodyImageAsset
    ) {
      bodyGraphics = generateImage(storeBackground.bodyImageAsset);
    } else {
      bodyGraphics = generateRectangle(storeBackground.bodyColor ?? "#ffffff");
    }

    const body: TemplateItem = {
      graphics: bodyGraphics,
      attributes: generateFullScreenAttributes(),
    };
    items.push(body, header);

    const imageItems: TemplateItem[] = generateImageItems(images);
    items.push(...imageItems);

    if (textStyle.showSubtitles) {
      const textItem: TemplateItem = {
        graphics: subtitlesAnimation
          ? generateRichSubtitles(
              textStyle.subtitles_color,
              textStyle.fontAssetId,
              textStyle.fontSize,
              textStyle.subtitles_stroke_color
            )
          : generateBasicSubtitles(
              textStyle.subtitles_color,
              textStyle.fontAssetId,
              textStyle.fontSize
            ),
        attributes: generateCommonAttributes(
          textStyle.position.x,
          textStyle.position.y,
          textStyle.size.width,
          textStyle.size.height
        ),
      };

      if (textStyle.fadeIn) {
        textItem.attributes.push(generateFadeInAttributes());
      }
      if (textStyle.fadeOut) {
        textItem.attributes.push(generateFadeOutAttributes());
      }

      if (textStyle.textBackground) {
        const textBackgroundRect: TemplateItem = {
          name: "textBackground",
          graphics: generateRectangle(textStyle.textBackground),
          attributes: [
            ...generateCommonAttributes(
              textStyle.position.x,
              textStyle.position.y,
              textStyle.size.width,
              textStyle.size.height,
              textStyle.fadeIn,
              textStyle.fadeOut
            ),
          ],
        };

        items.push(textBackgroundRect);
      }

      items.push(textItem);
    }

    if (presenter.presenter) {
      const presenterItem: TemplateItem = {
        graphics: {
          type: "Avatar",
          mask: presenter.avatarCircle
            ? {
                shape: {
                  type: "Circle",
                  color: [255, 255, 255, 255],
                },
                // Mask attributes refer to the avatar, not the media
                attributes: [
                  {
                    type: "Position",
                    position: {
                      type: "Align",
                      vertical: "center",
                      horizontal: "center",
                    },
                  },
                  {
                    type: "Width",
                    rate: 1,
                  },
                ],
              }
            : null,
        },
        attributes: presenter.avatarCircle // Circle avatar is related to Circle, not the media
          ? [
              {
                type: "Position",
                position: {
                  type: "Align",
                  vertical: "center",
                  horizontal: "center",
                  reference: "avatar_background",
                },
              },
              {
                type: "Width",
                rate: presenter.size.width,
              },
            ]
          : [
              ...generateCommonAttributes(
                presenter.position.x,
                presenter.position.y,
                presenter.size.width,
                presenter.size.height,
                presenter.fadeIn,
                presenter.fadeOut
              ),
            ],
      };

      if (presenter.avatarCircle) {
        const circleItem: TemplateItem = {
          name: "avatar_background",
          graphics: {
            type: "Circle",
            color: hexToRgba(presenter.background),
          },
          attributes: [
            ...generateCommonAttributes(
              presenter.position.x,
              presenter.position.y,
              presenter.size.width,
              presenter.size.height,
              presenter.fadeIn,
              presenter.fadeOut
            ),
          ],
        };

        items.push(circleItem);
      }

      items.push(presenterItem);
    }
  };

  const getClosingItems = () => {
    if (!storeClosing.enable || !storeClosing.assetId) return;

    const closingItem: TemplateItem = {
      graphics: generateCustomAssetVideo(storeClosing.assetId),
      attributes: generateFullScreenAttributes(),
    };

    items.push(closingItem);

    const imageItems: TemplateItem[] = generateImageItems(images);
    items.push(...imageItems);
  };

  if (view === TemplateType.INTRODUCTION) {
    getIntroductionItems();
  } else if (view === TemplateType.TRANSITION) {
    getTransitionItems();
  } else if (view === TemplateType.CHAPTER) {
    getChapterItems();
  } else if (view === TemplateType.CLOSING) {
    getClosingItems();
  }

  return { items };
};

const generateImageItems = (images: NewImage[]) => {
  return images.map((image) => {
    const imageItem: TemplateItem = {
      graphics: generateImage(image.assetId),
      attributes: generateCommonAttributes(
        image.position.x,
        image.position.y,
        image.size.width,
        image.size.height,
        image.fadeIn,
        image.fadeOut
      ),
    };

    return imageItem;
  });
};

const generateImage = (
  assetId: string,
  keep_ratio?: boolean
): TemplateItem["graphics"] => {
  return {
    type: "AssetImage",
    mask: null, // null for all images
    asset_id: assetId,
    keep_ratio: keep_ratio ?? true, // true for all images
  };
};

const generateRectangle = (color: string): TemplateItem["graphics"] => {
  const rect: TemplateItem["graphics"] = {
    type: "Rectangle",
    color: hexToRgba(color),
    width: 0,
    height: 0,
  };
  return rect;
};

const generateCustomAssetVideo = (
  assetId: string
): TemplateItem["graphics"] => {
  return {
    type: "CustomAssetVideo",
    asset_id: assetId,
    loop: false,
  };
};

const generateBasicSubtitles = (
  color: string,
  fontAssetId: string,
  fontSize: number
): TemplateItem["graphics"] => {
  return {
    type: "Subtitles",
    color: hexToRgba(color),
    font: fontAssetId,
    size: fontSize,
    upper_case: false,
  };
};
const generateRichSubtitles = (
  color: string,
  fontAssetId: string,
  fontSize: number,
  strokeColor?: string
): TemplateItem["graphics"] => {
  const richSubtitles: TemplateItem["graphics"] = {
    type: "RichSubtitles",
    text: {
      color: hexToRgba(color),
      font: fontAssetId,
      size: fontSize,
      upper_case: false,
      blurry_background: false,
      stroke_color: hexToRgba(NULL_COLOR),
    },
    highlights: {
      color: hexToRgba(color),
      font: fontAssetId,
      size: fontSize,
      upper_case: false,
      blurry_background: false,
      stroke_color: hexToRgba(NULL_COLOR),
    },
  };

  if (strokeColor) {
    richSubtitles.text.stroke_color = hexToRgba(strokeColor);
    richSubtitles.highlights.stroke_color = hexToRgba(strokeColor);
  }
  return richSubtitles;
};

const generateTextItem = (
  fontAssetId: string,
  fontSize: number,
  color: string
): TemplateItem["graphics"] => {
  return {
    type: "Text",
    font: fontAssetId,
    size: fontSize ?? 30,
    color: hexToRgba(color ?? "#000000"),
    content: "{chapter.topic}", //needs to be hardcoded like this,
    wrap_text: true,
    background: null,
    upper_case: false,
    align: "center",
    adjust_font: true,
  };
};

const generateHeaderAttributes = (): TemplateItem["attributes"] => {
  return [
    {
      type: "Position",
      position: {
        type: "Align",
        vertical: "top",
        horizontal: "center",
      },
    },
    {
      type: "Width",
      rate: 1,
    },
    {
      type: "Height",
      rate: 0.1,
    },
  ];
};

const generatePositionAttributes = (
  x: number,
  y: number
): TemplateItem["attributes"][number] => {
  return {
    type: "Position",
    position: {
      type: "Coordinates",
      position: [
        Math.round(x * PREVIEW_ADJUSTMENT),
        Math.round(y * PREVIEW_ADJUSTMENT),
      ],
    },
  };
};

const generateCommonAttributes = (
  x: number,
  y: number,
  width: number,
  height: number,
  fadeIn?: boolean,
  fadeOut?: boolean
) => {
  const attributes: TemplateItem["attributes"] = [
    generatePositionAttributes(x, y),
    {
      type: "Width",
      rate: width,
    },
    {
      type: "Height",
      rate: height,
    },
  ];

  if (fadeIn) {
    attributes.push(generateFadeInAttributes());
  }

  if (fadeOut) {
    attributes.push(generateFadeOutAttributes());
  }

  return attributes;
};

const generateFullScreenAttributes = (): TemplateItem["attributes"] => {
  return [
    {
      type: "FullScreen",
    },
  ];
};

const generateFadeInAttributes = (): TemplateItem["attributes"][number] => {
  return {
    type: "Fade",
    start: 0,
    duration: 10,
    from_opacity: 0,
    to_opacity: 255,
    method: "EaseInOut",
  };
};

const generateFadeOutAttributes = (): TemplateItem["attributes"][number] => {
  return {
    type: "Fade",
    start: 65,
    duration: 10,
    from_opacity: 255,
    to_opacity: 0,
    method: "EaseInOut",
  };
};
