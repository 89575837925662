import { DeleteOutlined } from "@ant-design/icons";
import { ArrowsDownUp, DotsSix } from "@phosphor-icons/react";
import { Button, Card, Flex, Modal, Space } from "antd";
import { CardTitle } from "./Section";
import { TDetailedSection, TDetailedStoryboard } from "pages/media/types";
import { CSS } from "@dnd-kit/utilities";
import { useTranslate } from "@refinedev/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
export const SortedChaptersModal = ({
  changeSection,
  open,
  sectionsId,
  storyboard,
  onSubmit,
  onCancel,
}: {
  changeSection: (section: TDetailedSection) => void;
  open: boolean;
  sectionsId: string[];
  storyboard: TDetailedStoryboard;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const t = useTranslate();
  return (
    <Modal
      title={
        <Space align="center">
          <ArrowsDownUp />
          {t("components.storyboard.StoryboardEditor.sortChapters")}
        </Space>
      }
      open={open}
      onCancel={onCancel}
      onOk={() => {
        onSubmit();
      }}
      style={{
        minWidth: "60vw",
      }}
    >
      <SortableContext
        items={sectionsId}
        strategy={verticalListSortingStrategy}
      >
        <Flex vertical gap={10} style={{ marginTop: 20 }}>
          {storyboard?.sections.map(
            (section: TDetailedSection, index: number) => {
              return (
                <SortableItem
                  key={section?.id}
                  index={index}
                  section={section}
                  changeSection={changeSection}
                />
              );
            }
          )}
        </Flex>
      </SortableContext>
    </Modal>
  );
};

const SortableItem = ({
  changeSection,
  index,
  section,
}: {
  changeSection: (section: TDetailedSection) => void;
  index: number;
  section: TDetailedSection;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: section?.id,
      data: { type: "section" },
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div ref={setNodeRef} {...attributes} style={style}>
      <Card
        title={
          <CardTitle
            index={index}
            changeSection={changeSection}
            section={section}
          />
        }
        size="small"
        styles={{
          body: { padding: 0, border: 0 },
          header: {
            border: 0,
            backgroundColor: "transparent",
            borderRadius: 0,
          },

          extra: { display: "flex", alignItems: "center", border: 0 },
          ...style,
        }}
        extra={[
          <DotsSix
            key={1}
            {...listeners}
            size={24}
            style={{ cursor: "move" }}
          />,
          <Button
            key={2}
            danger
            icon={
              <DeleteOutlined
                onClick={() => {
                  changeSection({
                    detailed_summary: "",
                    scripts: [],
                    script: {
                      content: "",
                      selected: false,
                    },
                    key_insights: [],
                    topic: "",
                    id: section.id,
                    sources: [],
                  });
                }}
              />
            }
            type="text"
          ></Button>,
        ]}
      ></Card>
    </div>
  );
};
