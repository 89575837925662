import { useTranslate } from "@refinedev/core";
import { t } from "i18next";
import { ArrowLeft, ArrowRight, Check } from "@phosphor-icons/react";
import {
  Button,
  Flex,
  FlexProps,
  Progress,
  Space,
  Spin,
  Typography,
} from "antd";
import { useBoundStore } from "store";
import { useAntTheme } from "hooks/useAntTheme";
import Finish from "./Finish";
import { ORDERED_STEPS, getCurrentStepNumber } from "./helpers";
import {
  Authenticated,
  useCreate,
  useNotification,
  useInvalidate,
  useUpdate,
} from "@refinedev/core";
import { OnboardingAnswer, UpdateOrganizationRequest } from "pages/media/types";
import { setStepValue } from "./onboardingSlice";
import { OTHER_VALUE, Steps } from "./types";
import { useContext, useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import styled from "styled-components";
import {
  EMPTY_ANSWER_ALLOWED,
  FREE_TEXT_ANSWERS,
  I18N_PREFIX,
} from "./constants";
import { useOrganization } from "hooks/useOrganization";
import useOnboardingAnswers from "./hooks/useOnboardingAnswers";
import { AppContext } from "appContext";
import { useAnalytics } from "hooks/useAnalytics";

const OnboardingPage = () => {
  const {
    trackOnboardingStep,
    trackOnboardingAnswer,
    trackOnboardingCompleted,
  } = useAnalytics();
  const t = useTranslate();
  const { theme, isMobile } = useAntTheme();
  const { open } = useNotification();
  const {
    resetOnboardingState,
    currentStep,
    goForward,
    goBack,
    onboardingState,
    setCurrentStep,
  } = useBoundStore((state) => ({
    currentStep: state.onboardingState.currentStep,
    onboardingState: state.onboardingState,
    goForward: state.goForward,
    goBack: state.goBack,
    resetOnboardingState: state.resetOnboardingState,
    setCurrentStep: state.setCurrentStep,
  }));
  const { isLoading: isLoadingAnswers, hasCompletedOnboarding } =
    useOnboardingAnswers();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch } = useContext(AppContext);
  const { mutateAsync: createAnswer, isLoading: isLoadingCreateAnswer } =
    useCreate<OnboardingAnswer>();
  const {
    mutateAsync: updateOrganization,
    isLoading: isLoadingUpdateOrganization,
  } = useUpdate<UpdateOrganizationRequest>();
  const { organization } = useOrganization({});
  const invalidate = useInvalidate();
  const handleUpdateOrganization = async () => {
    await updateOrganization({
      resource: "media/organizations",
      id: organization!.id,
      values: {
        name: onboardingState.organization,
      },
      successNotification: false,
    });
    dispatch({
      type: "setOrganization",
      payload: {
        name: onboardingState.organization,
        id: organization!.id,
      },
    });
    await invalidate({
      resource: `api/users/me/organizations`,
      invalidates: ["all"],
    });
    goForward();
  };

  const handleContinue = async () => {
    // Submits the current answers and continues if successful
    try {
      await form.validateFields();
    } catch {
      // Error is handled by the form (displaying an error message)
      return;
    }
    if (currentStep === "organization") return handleUpdateOrganization();
    const value = onboardingState[currentStep];
    if (answerNotGiven(value, currentStep)) {
      const options = {
        message: "",
        description:
          (typeof value === "object" && "other" in value && !value.answers) ||
          (Array.isArray(value.answers) && !value.answers.length)
            ? t(`${I18N_PREFIX}.errors.choiceRequired`)
            : t(`${I18N_PREFIX}.errors.otherValueRequired`),
        type: "error" as const,
        // Otherwise we get not quite helpful "Something went wrong" in
        // big letters.
        skipFriendly: true,
      };
      return open!(options);
    }
    let answer: {
      answers?: unknown;
      other?: string | null;
    };
    if (typeof value === "object" && value !== null) {
      answer = { ...value };
      if (value.other === null) {
        delete answer.other;
      }
    } else {
      answer = { answers: value };
    }
    setStepValue(value, { [currentStep]: answer, currentStep });
    trackOnboardingAnswer(currentStep, onboardingState[currentStep]);

    try {
      await createAnswer({
        resource: "media/onboarding/answer",
        values: {
          question: currentStep.toUpperCase(),
          answer,
        },
        successNotification: false,
      });
    } catch (error) {
      console.debug("Oops, something went wrong", error);
      return;
    }
    goForward();
  };
  const handleFinish = async () => {
    trackOnboardingCompleted(onboardingState);
    goForward();
  };
  const Component = ORDERED_STEPS[currentStep];
  const isFinished = currentStep === "finish";
  const stepNumber = getCurrentStepNumber(currentStep);
  const isLastStep = stepNumber === Object.keys(ORDERED_STEPS).length - 1;
  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (
      event.key === "Enter" &&
      !answerNotGiven(onboardingState[currentStep], currentStep)
    ) {
      if (isLastStep) handleFinish();
      else handleContinue();
    }
  }
  const isFirstStep = stepNumber === 0;

  // Component did mount
  useEffect(() => {
    // Reset the state on reloading. This is needed because we cannot have
    // only parts of a store in Zustand to persist. Such separation requires
    // using multiple stores.
    resetOnboardingState();

    // Collapse sidebar
    dispatch({ type: "setSidebarCollapsed", payload: true });
  }, []);

  useEffect(() => {
    // If the user already has all the questions answered, redirect to finish
    if (hasCompletedOnboarding) {
      setCurrentStep("finish");
    }
    if (isLoadingAnswers) return;
    setIsLoading(false);
  }, [hasCompletedOnboarding, isLoadingAnswers]);
  useEffect(() => {
    trackOnboardingStep(currentStep);
  }, [currentStep]);
  return (
    <OnboardingContainer isLoading={isLoading} handleKeyDown={handleKeyDown}>
      {isFinished ? (
        <div
          style={{
            margin: isMobile ? "-24px 0 0 0" : "-24px 0 100px 0",
          }}
        >
          <Finish />
        </div>
      ) : (
        <Flex
          gap={isMobile ? 10 : 50}
          vertical
          style={{
            margin: isMobile ? 0 : "25% 0 0 0",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <Component key={currentStep} form={form} />
          <Flex
            align="center"
            gap={isMobile ? 10 : 50}
            vertical={isMobile}
            justify="space-between"
          >
            <Progress
              strokeColor={theme.colorPrimary}
              showInfo={false}
              percent={(100 / Object.keys(ORDERED_STEPS).length) * stepNumber}
            />
            <Space>
              {isFirstStep ? null : (
                <Button
                  shape="round"
                  size="large"
                  type="text"
                  tabIndex={0}
                  onClick={() => goBack()}
                >
                  <span className="anticon">
                    <ArrowLeft style={{ verticalAlign: "text-top" }} />
                  </span>
                  {t("pages.onboarding.buttons.back")}
                </Button>
              )}

              {isLastStep ? (
                <Button tabIndex={0} onClick={() => handleFinish()}>
                  {t("pages.onboarding.buttons.submit")}{" "}
                  <span className="anticon">
                    <Check style={{ verticalAlign: "text-top" }} />
                  </span>
                </Button>
              ) : (
                <Button
                  shape="round"
                  size="large"
                  tabIndex={0}
                  disabled={
                    isLoadingCreateAnswer || isLoadingUpdateOrganization
                  }
                  onClick={() => handleContinue()}
                >
                  {t("pages.onboarding.buttons.continue")}
                  <span className="anticon">
                    <ArrowRight style={{ verticalAlign: "text-top" }} />
                  </span>
                </Button>
              )}
            </Space>
          </Flex>
        </Flex>
      )}
    </OnboardingContainer>
  );
};
export default OnboardingPage;

export const OnboardingContainer = ({
  children,
  isLoading,
  handleKeyDown,
  justify = "center",
}: {
  children: React.ReactNode;
  isLoading: boolean;
  justify?: FlexProps["justify"];
  handleKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}) => {
  const { theme, isMobile } = useAntTheme();
  return (
    <Authenticated key="onboarding">
      {isLoading ? (
        <Spin fullscreen />
      ) : (
        <Flex
          justify="space-between"
          style={{
            margin: isMobile ? "-24px 0 0 0" : "-24px",
          }}
        >
          <Flex
            align="center"
            vertical
            tabIndex={0}
            role="menu"
            onKeyDown={handleKeyDown}
            style={{
              padding: isMobile ? theme.paddingLG : "0 36px",
              borderRadius: theme.borderRadius,
              maxWidth: 800,
              flex: isMobile ? "1 0 100%" : "1 0 75%",
              margin: "0 auto",
            }}
          >
            {" "}
            {children}
          </Flex>{" "}
          {!isMobile && (
            <StyledSide vertical align="center" gap={30}>
              <Typography.Text></Typography.Text>
            </StyledSide>
          )}
        </Flex>
      )}
    </Authenticated>
  );
};

function answerNotGiven(value: Steps[keyof Steps], currentStep: keyof Steps) {
  // Some answers allow empty other value, others don't.
  // None of the answers allow not choosing anything.
  if (
    typeof value === "object" &&
    value !== null &&
    "answers" in value &&
    // 👆 Just some general type-checking
    (value.answers === OTHER_VALUE || // if other button is selected
      // or if other button is selected in a multi-choice answer
      (Array.isArray(value.answers) &&
        (value.answers as string[]).includes(OTHER_VALUE))) &&
    // and the other value is empty
    !value.other
  ) {
    // check that that answer allows an empty value (e.g. the type of business question)
    return !EMPTY_ANSWER_ALLOWED.includes(currentStep);
  } else if (FREE_TEXT_ANSWERS.includes(currentStep)) {
    // This is a special handling for the organization step
    // because it doesn't have "answers" field and is just a string
    return Array.isArray(value) ? value.length === 0 : !value;
  } else if (typeof value === "object" && value !== null) {
    // A general check that the user has selected a button in an answer
    // with buttons
    return (
      value.answers === null ||
      (Array.isArray(value.answers) && value.answers.length === 0)
    );
  }
  return false;
}
export const StyledSide = styled(Flex)`
  position: relative;
  flex: 0 1 40vw;
  max-width: 600px;
  text-align: center;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom, #000000 0%, #000000cc 100%);
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    // background-image: url("https://images.unsplash.com/photo-1634017839464-5c339ebe3cb4?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    // from https://unsplash.com/fr/@philipsfuture
    background-image: url("https://images.unsplash.com/photo-1725830826396-bcb0585da085?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-position: center;
  }
  &:nth-of-type(1) {
    transform: rotateY(180deg);
  }
  > * {
    z-index: 1;
  }
`;
