import { PlusOutlined } from "@ant-design/icons";
import {
  ArrowFatLinesRight,
  Exclude,
  Image,
  MagicWand,
  Palette,
  Swatches,
  TextT,
  User,
  Video,
} from "@phosphor-icons/react";
import {
  Button,
  Card,
  Checkbox,
  ColorPicker,
  ConfigProvider,
  Dropdown,
  Flex,
  Modal,
  notification,
  Radio,
  Select,
  Slider,
  Space,
  Switch,
  Tabs,
  Typography,
} from "antd";
import { t } from "i18n";
import React, { CSSProperties, useMemo, useRef, useState } from "react";
import { PresenterProfilePreviewView } from "../PresenterProfilePreviewView";
import { useTemplates } from "./hooks/useTemplates";
import { FontPreviewText } from "pages/media/brandkit/components/FontPreview";
import { Label } from "../storyboard/editor/Label";
import {
  AssetLibrary,
  BrandKitAsset,
  ContactResponse,
  MediaAsset,
  PaletteResponse,
  PresenterProfileResponse,
} from "pages/media/types";
import {
  useCustomMutation,
  useInvalidate,
  useList,
  useOne,
} from "@refinedev/core";
import { PicturePreview } from "pages/media/brandkit/components/PicturePreview";
import { ContactAddingModal } from "pages/media/contacts/components/ContactAddingModal";
import { Effects, TabKeys, TemplateType } from "./types";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useVolatileBoundStore } from "store";
import { useAntTheme } from "hooks/useAntTheme";
import AssetLibraryDetail, {
  useMediaLibraryAssets,
} from "pages/asset-libraries/edit";
import VideoPreview from "pages/media/brandkit/components/VideoPreview";
import styled from "styled-components";
import { InfiniteScrollList } from "./InfiniteScrollList";
import { getRectangleRelativeSize } from "./helpers/helper";
import { TFunction } from "i18next";
import { nanoid } from "nanoid";
import { FontPreviewWithTag } from "pages/media/brandkit/components/FontPreviewWithTag";

interface SettingsProps {
  projectId: string;
  currentAssetLibraryId: string;
  setCurrentAssetLibraryId: React.Dispatch<React.SetStateAction<string>>;
}

let interval = 0;
export const TemplateSettings: React.FC<SettingsProps> = ({
  projectId,
  currentAssetLibraryId,
  setCurrentAssetLibraryId,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const { mutateAsync, isLoading } = useCustomMutation();
  const invalidate = useInvalidate();

  const {
    logo,
    banner,
    background,
    intro,
    transition,
    organizationId,
    closing,
    textFont,
    subtitlesFont,
    titleFont,
    brandKitPalette,
    assetLibrary,
    assetLibraries,
    presenter: initialPresenter,
    publicFonts,
  } = useTemplates({ projectId });

  if (!currentAssetLibraryId && assetLibrary?.id) {
    setCurrentAssetLibraryId(assetLibrary.id);
  }

  const {
    view,
    presenter,
    storeIntro,
    setIntro,
    setTransition,
    storeTransition,
    setClosing,
    storeClosing,
    activeTab,
    setActiveTab,
  } = useVolatileBoundStore((store) => ({
    view: store.templateState.view,
    presenter: store.templateState.presenter,
    storeIntro: store.templateState.intro,
    setIntro: store.setIntro,
    setTransition: store.setTransition,
    storeTransition: store.templateState.transition,
    setClosing: store.setClosing,
    storeClosing: store.templateState.closing,
    activeTab: store.templateState.activeTab,
    setActiveTab: store.setActiveTab,
  }));

  const [api, contextHolder] = notification.useNotification();

  const handleChangeTransition = async (hasTransition: boolean) => {
    await mutateAsync({
      url: `media/projects/${projectId}/settings`,
      method: "patch",
      values: { transitions: hasTransition ? "Basic" : "NoTransition" },
    });

    setTransition({ enable: hasTransition });

    invalidate({
      resource: "media/projects",
      id: projectId,
      invalidates: ["detail"],
    });
  };
  const [page, setPage] = useState(1);
  const [newAssets, setNewAssets] = useState<Partial<MediaAsset>[]>([]);

  const {
    images,
    music,
    fonts,
    videos,
    assets,
    hasMore,
    isLoadingAssetLibraryAssets,
  } = useMediaLibraryAssets(
    currentAssetLibraryId,
    organizationId,
    page,
    newAssets,
    setNewAssets
  );

  const imagesFromBrandkitAndAsset: (Partial<MediaAsset> | BrandKitAsset)[] = [
    logo,
    banner,
  ];

  if (images) {
    imagesFromBrandkitAndAsset.push(...images);
  }

  if (background && background.type === "Picture") {
    imagesFromBrandkitAndAsset.push(background);
  }

  const [activeLibraryTab, setActiveLibraryTab] =
    useState<AssetNames>("images");
  const handleClickAddAsset = (type) => {
    setActiveLibraryTab(type);
    setOpenModal(true);
  };

  if (!activeTab) {
    setActiveTab(TabKeys.images);
  }

  const settingsItems = [
    {
      key: TabKeys.images,
      item: t("components.templates.settings.images"),
      icon: <Image size={24} weight="bold" />,
      child: (
        <Flex
          id="infinite-scroll-images"
          vertical
          gap={20}
          style={{
            maxHeight: "calc(100vh - 120px)",
            overflow: "auto",
            marginRight: "-24px",
          }}
        >
          <AddAssetButton
            title="image"
            onClick={() => handleClickAddAsset("images")}
          />
          <InfiniteScrollList
            assetLength={assets?.length ?? 0}
            setPage={setPage}
            dataSource={imagesFromBrandkitAndAsset}
            hasMore={hasMore}
            scrollableTarget="infinite-scroll-images"
          >
            {(image, index) => {
              let fileName: string;
              if ("description" in image) {
                fileName = image.description;
              } else if ("name" in image) {
                // name has type unknown
                fileName = image.name as string;
              } else {
                fileName = "image" + index;
              }

              return (
                <>
                  <AddToTemplateButton
                    assetId={image && getRowKey(image)}
                    size={{ width: 0.5, height: 0.5 }}
                  />
                  <PicturePreview
                    cardWidth={116}
                    coverHeight={"max-content"}
                    image={image?.path}
                    fileName={image && fileName}
                  />
                </>
              );
            }}
          </InfiniteScrollList>
        </Flex>
      ),
    },
    {
      key: TabKeys.intro,
      item: t("components.templates.Introduction"),
      icon: <Video size={24} weight="bold" />,
      child: (
        <Flex
          id="infinite-scroll-intro"
          vertical
          gap={20}
          style={{
            height: "calc(100vh - 120px)",
            overflow: "auto",
            marginRight: "-24px",
            paddingRight: 24,
          }}
        >
          {intro && (
            <VideoPreview
              video={intro.path}
              height={"max-content"}
              fileName={intro?.name}
              actions={[
                <ChooseVideoButton
                  key={intro.asset_id}
                  onChoose={() => {
                    setIntro({
                      assetPath: intro?.path,
                      assetId: intro?.asset_id,
                      view: "Video",
                    });
                  }}
                />,
              ]}
            />
          )}
          <SwitchComponent
            show={storeIntro.enable}
            view={TemplateType.INTRODUCTION}
            onSwitchChange={() => {
              setIntro({ enable: !storeIntro.enable });

              if (!storeIntro.assetId) {
                api.warning({
                  message: t("components.templates.settings.noVideoAsset", {
                    view: "Introduction",
                  }),
                });
              }
            }}
          />
          <InfiniteScrollList
            assetLength={assets?.length ?? 0}
            setPage={setPage}
            dataSource={videos}
            hasMore={hasMore}
            scrollableTarget="infinite-scroll-intro"
          >
            {(video) => (
              <VideoPreview
                video={video.path}
                height={"max-content"}
                fileName={video.description}
                actions={[
                  <ChooseVideoButton
                    key={video.id}
                    onChoose={() => {
                      setIntro({
                        assetPath: video.path,
                        assetId: video.id,
                        view: "Video",
                      });
                    }}
                  />,
                ]}
                /* actions={[
                      <DeleteButton
                        key={video.id}
                        accessControl={{ enabled: false }}
                        recordItemId={video.id}
                        resource={`/media/${organizationId}/asset_libraries/${assetLibrary?.id}/assets/${video?.id}`}
                      />,
                    ]} */
              />
            )}
          </InfiniteScrollList>
          <AddAssetButton
            title={TemplateType.INTRODUCTION}
            onClick={() => handleClickAddAsset("video")}
          />
        </Flex>
      ),
    },
    {
      key: TabKeys.transition,
      item: t("components.templates.Transition"),
      icon: <Exclude size={24} weight="bold" />,
      child: (
        <Flex
          id="infinite-scroll-transition"
          vertical
          gap={20}
          style={{
            height: "calc(100vh - 120px)",
            overflow: "auto",
            marginRight: -24,
            paddingRight: 24,
          }}
        >
          <Label wrap>
            {t("components.templates.settings.transitionView.label")}
          </Label>
          <Radio.Group
            value={storeTransition.view}
            onChange={(e) => {
              setTransition({ view: e.target.value });
            }}
          >
            <Radio value={"Video"}>
              {t("components.templates.settings.transitionView.video")}
            </Radio>
            <Radio value={"Picture"}>
              {t("components.templates.settings.transitionView.picture")}
            </Radio>
            <Radio value={"Color"}>
              {t("components.templates.settings.transitionView.color")}
            </Radio>
          </Radio.Group>
          {transition && (
            <VideoPreview
              video={transition.path}
              height={"max-content"}
              fileName={transition?.name}
              actions={[
                <ChooseVideoButton
                  key={transition.asset_id}
                  onChoose={() => {
                    setTransition({
                      assetPath: transition?.path,
                      assetId: transition?.asset_id,
                      view: "Video",
                    });
                  }}
                />,
              ]}
            />
          )}
          <SwitchComponent
            show={storeTransition.enable}
            view={TemplateType.TRANSITION}
            isLoading={isLoading}
            onSwitchChange={() => {
              handleChangeTransition(!storeTransition.enable);
            }}
          />
          <InfiniteScrollList
            assetLength={assets?.length ?? 0}
            setPage={setPage}
            dataSource={videos}
            hasMore={hasMore}
            scrollableTarget="infinite-scroll-transition"
          >
            {(video) => (
              <VideoPreview
                video={video?.path}
                fileName={video?.description}
                width={120}
                height={"max-content"}
                actions={[
                  <ChooseVideoButton
                    key={video?.id}
                    onChoose={() => {
                      setTransition({
                        assetPath: video?.path,
                        assetId: video?.id,
                        view: "Video",
                      });
                    }}
                  />,
                ]}
                // actions={[
                //   <DeleteButton
                //     key={video?.id}
                //     accessControl={{ enabled: false }}
                //     recordItemId={video?.id}
                //     resource={`/media/${organizationId}/asset_libraries/${assetLibrary?.id}/assets/${video?.id}`}
                //   />,
                // ]}
              />
            )}
          </InfiniteScrollList>
          <AddAssetButton
            title={TemplateType.TRANSITION}
            onClick={() => handleClickAddAsset("video")}
          />
        </Flex>
      ),
    },
    {
      key: TabKeys.closing,
      item: t("components.templates.Closing"),
      icon: <ArrowFatLinesRight size={24} weight="bold" />,
      child: (
        <Flex
          id="infinite-scroll-closing"
          vertical
          style={{
            height: "calc(100vh - 120px)",
            overflow: "auto",
            marginRight: "-24px",
            paddingRight: 24,
          }}
        >
          {closing && (
            <VideoPreview
              video={closing.path}
              width={120}
              height={"max-content"}
              fileName={closing.name}
              actions={[
                <ChooseVideoButton
                  key={closing.asset_id}
                  onChoose={() => {
                    setClosing({
                      assetPath: closing.path,
                      assetId: closing.asset_id,
                      view: "Video",
                    });
                  }}
                />,
              ]}
            />
          )}
          {!closing && storeClosing.enable && (
            <AddAssetButton
              title={TemplateType.CLOSING}
              onClick={() => setOpenModal(true)}
            />
          )}
          <SwitchComponent
            show={storeClosing.enable}
            view={TemplateType.CLOSING}
            onSwitchChange={() => {
              setClosing({ enable: !storeClosing.enable });
              if (!storeClosing.assetId) {
                api.warning({
                  message: t("components.templates.settings.noVideoAsset", {
                    view: "Closing",
                  }),
                });
              }
            }}
          />
          <InfiniteScrollList
            assetLength={assets?.length ?? 0}
            setPage={setPage}
            dataSource={videos}
            hasMore={hasMore}
            scrollableTarget="infinite-scroll-closing"
          >
            {(video) => (
              <VideoPreview
                video={video.path}
                width={120}
                height={"max-content"}
                fileName={video.description}
                actions={[
                  <ChooseVideoButton
                    key={video.id}
                    onChoose={() => {
                      setClosing({
                        assetPath: video.path,
                        assetId: video.id,
                        view: "Video",
                      });
                    }}
                  />,
                ]}
                /* actions={[
              <DeleteButton
                key={video.id}
                accessControl={{ enabled: false }}
                recordItemId={video.id}
                resource={`/media/${organizationId}/asset_libraries/${assetLibrary?.id}/assets/${video?.id}`}
              />,
            ]} */
              />
            )}
          </InfiniteScrollList>
          <AddAssetButton
            title={t("components.templates.Closing")}
            onClick={() => handleClickAddAsset("video")}
          />
        </Flex>
      ),
    },
    {
      key: TabKeys.background,
      item: t("components.templates.settings.background"),
      icon: <Palette size={24} weight="bold" />,
      child: <BackgroundTab />,
    },
    {
      key: TabKeys.text,
      item: t("components.templates.settings.text"),
      icon: <TextT size={24} weight="bold" />,
      child: (
        <TextSettingsTab
          hasMore={hasMore}
          setPage={setPage}
          isLoadingMediaAssets={isLoadingAssetLibraryAssets}
          onClick={() => setOpenModal(true)}
          textFont={textFont}
          titleFont={titleFont}
          brandKitPalette={brandKitPalette}
          subtitlesFont={subtitlesFont}
          handleClickAddAsset={handleClickAddAsset}
          fonts={fonts}
          publicFonts={publicFonts}
        />
      ),
    },
    {
      key: TabKeys.presenter,
      item: t("components.templates.settings.presenter"),
      icon: <User size={24} weight="bold" />,
      child: presenter && (
        <PresenterSettings
          initialPresenter={initialPresenter}
          organizationId={organizationId}
        />
      ),
    },
    {
      key: TabKeys.effects,
      item: t("components.templates.settings.effects.title"),
      icon: <MagicWand size={24} weight="bold" />,
      child: <EffectsSettings />,
    },
  ];

  const tabsItems = settingsItems?.map((item) => {
    return {
      label: (
        <Flex vertical align="center" justify="center" gap={0}>
          {item.icon}
          <Typography.Text style={{ fontSize: 11 }}>
            {item.item}
          </Typography.Text>
        </Flex>
      ),
      key: `${item.key}`,
      children: (
        <Flex vertical style={{ margin: 24, marginRight: 0 }} gap={20}>
          {assetLibraries?.length > 1 && (
            <>
              <label htmlFor="library-select">
                {t("asset_libraries.select_library")}
              </label>
              <Select
                id={"library-select"}
                optionFilterProp="label"
                value={currentAssetLibraryId}
                style={{ width: "100%", marginTop: -20 }}
                showSearch
                options={assetLibraries?.map((lib) => {
                  return {
                    label: lib.name,
                    value: lib.id,
                  };
                })}
                onChange={(value) => {
                  setCurrentAssetLibraryId?.(value);
                  setPage(1);
                  infiniteScrollFix({ t, tab: activeTab, hasMore, dataMap });
                }}
              />
            </>
          )}
          {item.child ? (
            item.child
          ) : (
            <AddAssetButton
              title={
                item.item === TemplateType.TRANSITION
                  ? "Transition Video"
                  : item.item
              }
              onClick={() => handleClickAddAsset("video")}
            />
          )}
        </Flex>
      ),
      disabled: item.item === "Text" && view === TemplateType.INTRODUCTION,
    };
  });

  const assetLibraryDetailRef = useRef<{
    handleClickSave: () => Promise<void>;
    myId: string;
  }>(null);
  const { data: libraries } = useList<AssetLibrary>({
    resource: `/media/${organizationId}/asset_libraries`,
    queryOptions: {
      enabled: !!organizationId,
    },
  });
  const dataMap = {
    images,
    video: videos,
    fonts,
    audio: music,
  };

  if (!interval) {
    infiniteScrollFix({ t, tab: activeTab, hasMore, dataMap });
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            // cardBg: "rgba(0, 0, 0, 0)",
          },
        },
      }}
    >
      {contextHolder}
      <StyledTabs
        size="small"
        tabPosition="right"
        items={tabsItems}
        activeKey={activeTab}
        type="card"
        tabBarStyle={{
          padding: 5,
          height: "calc(100vh - 57px)",
          overflow: "auto",
        }}
        onChange={(tab) => {
          setActiveTab(tab as TabKeys);
          infiniteScrollFix({ t, tab, hasMore, dataMap });
        }}
      />
      <ModalWithAssetLibrary
        key={activeLibraryTab}
        openModal={openModal}
        assetLibraryDetailRef={assetLibraryDetailRef}
        setPage={setPage}
        activeLibraryTab={activeLibraryTab}
        setOpenModal={setOpenModal}
        organizationId={organizationId}
        setNewAssets={setNewAssets}
        setCurrentAssetLibraryId={setCurrentAssetLibraryId}
        currentAssetLibraryId={currentAssetLibraryId}
        libraries={libraries?.data}
      />
    </ConfigProvider>
  );
};

const ModalWithAssetLibrary: React.FC<{
  openModal: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  assetLibraryDetailRef: React.MutableRefObject<{
    handleClickSave: () => Promise<void>;
  }>;
  activeLibraryTab: AssetNames;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  organizationId?: string;
  setNewAssets: (newAssets: Partial<MediaAsset>[]) => void;
  setCurrentAssetLibraryId: React.Dispatch<React.SetStateAction<string>>;
  currentAssetLibraryId: string;
  libraries: AssetLibrary[];
}> = ({
  openModal,
  assetLibraryDetailRef,
  activeLibraryTab,
  setOpenModal,
  organizationId,
  setNewAssets,
  setPage,
  setCurrentAssetLibraryId,
  currentAssetLibraryId,
  libraries,
}) => {
  return (
    <Modal
      destroyOnClose
      open={openModal}
      onOk={async () => {
        await assetLibraryDetailRef.current?.handleClickSave();
        return setOpenModal(false);
      }}
      onCancel={() => setOpenModal(false)}
    >
      <AssetLibraryDetail
        libraries={libraries}
        onUploadComplete={(newAssets) => {
          setNewAssets(newAssets);
        }}
        setPage={setPage}
        setCurrentAssetLibraryId={setCurrentAssetLibraryId}
        activeTab={activeLibraryTab}
        ref={assetLibraryDetailRef}
        id={currentAssetLibraryId}
        organizationId={organizationId}
        noLayout
        noNavigateAfterSave
        isEdit
      />
    </Modal>
  );
};

const AddAssetButton = ({
  title,
  onClick,
  style,
}: {
  title: string;
  onClick: () => void;
  style?: CSSProperties;
}) => {
  return (
    <Button icon={<PlusOutlined />} onClick={onClick} style={{ ...style }}>
      <Typography.Text ellipsis={true} style={{ maxWidth: "80%" }}>
        {t("components.templates.settings.addButton", { title })}
      </Typography.Text>
    </Button>
  );
};

const TextSettingsTab = ({
  hasMore,
  onClick,
  brandKitPalette,
  textFont,
  titleFont,
  subtitlesFont,
  handleClickAddAsset,
  fonts,
  publicFonts,
  setPage,
  isLoadingMediaAssets,
}: {
  hasMore: boolean;
  onClick: () => void;
  brandKitPalette: PaletteResponse;
  textFont: BrandKitAsset;
  titleFont: BrandKitAsset;
  subtitlesFont: BrandKitAsset;
  handleClickAddAsset: (string) => void;
  fonts: Partial<MediaAsset>[];
  publicFonts: Partial<MediaAsset>[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isLoadingMediaAssets?: boolean;
}) => {
  const fontItems = [titleFont, textFont, subtitlesFont];

  const {
    view,
    subtitlesAnimation,
    setSubtitlesAnimation,
    textStyle,
    setTextStyle,
    titleStyle,
    setTitleStyle,
    addUpdate,
  } = useVolatileBoundStore((store) => ({
    view: store.templateState.view,
    subtitlesAnimation: store.templateState.subtitlesAnimation,
    setSubtitlesAnimation: store.setSubtitlesAnimation,
    textStyle: store.templateState.textStyle,
    setTextStyle: store.setTextStyle,
    titleStyle: store.templateState.titleStyle,
    setTitleStyle: store.setTitleStyle,
    addUpdate: store.addUpdate,
  }));

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    if (checked) {
      setTextStyle({
        showSubtitles: true,
      });
    } else {
      setTextStyle({
        showSubtitles: false,
      });
    }
  };

  const subtitlesStrokeColor =
    textStyle.subtitles_stroke_color ?? brandKitPalette?.subtitle_stroke_color;

  const textColor =
    view === TemplateType.TRANSITION
      ? titleStyle.title_color
      : textStyle.subtitles_color;

  const textBackground =
    view === TemplateType.TRANSITION
      ? titleStyle.titleBackground
      : textStyle.textBackground;

  const strokeColor =
    view === TemplateType.TRANSITION ? undefined : subtitlesStrokeColor;

  const fontSizeValue =
    view === TemplateType.TRANSITION ? titleStyle.fontSize : textStyle.fontSize;

  const { theme } = useAntTheme();

  const brandKitFontsOptions = useMemo(() => {
    return (
      fontItems
        ?.filter((font) => font !== undefined)
        .map((font) => {
          return {
            key: nanoid(),
            value: font?.asset_id,
            label: (
              <FontPreviewWithTag
                fontPath={font.path}
                label={<Swatches size={15} color={theme.colorTextBase} />}
                styles={{ letterSpacing: 1.5, background: "none" }}
              />
            ),
          };
        }) ?? []
    );
  }, [fontItems, theme.colorTextBase]);

  const defaultFontsOptions = useMemo(() => {
    return (
      publicFonts?.map((font) => {
        return {
          key: nanoid(),
          value: font?.id,
          label: (
            <FontPreviewWithTag
              fontPath={font.path}
              label={t("components.templates.settings.builtInTag")}
            />
          ),
        };
      }) ?? []
    );
  }, [publicFonts]);

  const fontSelectOptions = useMemo(() => {
    const fontItems = [...fonts];
    return fontItems
      .filter((font) => font !== undefined)
      .map((font) => {
        return {
          key: nanoid(),
          value: font?.id,
          label: (
            <Space>
              <FontPreviewText
                font={font.path}
                text={"Example"}
                style={{ fontSize: 18, padding: "10px 0px" }}
              />
            </Space>
          ),
        };
      });
  }, [fonts]);

  const onScroll = async (event) => {
    const target = event.target;

    if (
      !isLoadingMediaAssets &&
      Math.round(target.scrollTop + target.offsetHeight) === target.scrollHeight
    ) {
      if (hasMore) setPage((prev) => prev + 1);
    }
  };

  const fontDefaultValue =
    view === TemplateType.TRANSITION
      ? titleStyle.fontAssetId
      : textStyle.fontAssetId;

  return (
    <Flex vertical gap={20}>
      <Flex vertical style={{ width: "100%" }} gap={10}>
        {view === TemplateType.CHAPTER && (
          <Checkbox defaultChecked onChange={onCheckboxChange}>
            {t("components.templates.settings.showSubtitles")}
          </Checkbox>
        )}
        <Label wrap>{t("components.templates.settings.uploadedFonts")}</Label>
        <Select
          id="fonts-select"
          size="large"
          value={fontDefaultValue}
          onPopupScroll={onScroll}
          options={[
            ...brandKitFontsOptions,
            ...defaultFontsOptions,
            ...fontSelectOptions,
          ]}
          onChange={(value: string) => {
            const allFonts = [...fontItems, ...fonts, ...publicFonts];
            const fontItem = allFonts.find((font) => {
              if (!font) return;
              if ("asset_id" in font) return font?.asset_id === value;
              else if ("id" in font) return font?.id === value;
            });

            if (!fontItem) return;

            let id = "";
            if ("asset_id" in fontItem) {
              id = fontItem.asset_id;
            } else if ("id" in fontItem) {
              id = fontItem.id;
            }

            if (view === TemplateType.TRANSITION) {
              setTitleStyle({
                titleFont: fontItem.path,
                fontAssetId: id,
              });
            } else {
              setTextStyle({
                textFont: fontItem.path,
                fontAssetId: id,
              });
            }

            addUpdate();
          }}
        />
      </Flex>
      <AddAssetButton
        title="fonts"
        onClick={() => {
          handleClickAddAsset("fonts");
          onClick();
        }}
      />
      <Flex vertical>
        <Space>
          <Label wrap>{t("components.templates.settings.fontSize")}</Label>
          <Typography.Text>{fontSizeValue}</Typography.Text>
        </Space>
        <Slider
          min={10}
          max={50}
          onChange={(value: number) => {
            const rectangle =
              document.querySelector(".font-rect").firstElementChild;

            if (view === TemplateType.TRANSITION) {
              setTitleStyle({
                fontSize: value,
                size: getRectangleRelativeSize(rectangle),
              });
            } else {
              setTextStyle({
                fontSize: value,
                size: getRectangleRelativeSize(rectangle),
              });
            }

            addUpdate();
          }}
          value={fontSizeValue}
        />
      </Flex>
      <Flex vertical style={{ width: "fit-content" }} gap={10}>
        <Label wrap>{t("components.templates.settings.textColor")}</Label>
        <ColorPicker
          showText
          value={textColor}
          onChange={(color) => {
            if (view === TemplateType.TRANSITION) {
              setTitleStyle({ title_color: color.toHexString() });
            } else {
              setTextStyle({ subtitles_color: color.toHexString() });
            }
            addUpdate();
          }}
        />
        {/* Subtitles stroke color only works for RichSubtitles on the backend */}
        {subtitlesAnimation && view === TemplateType.CHAPTER && (
          <>
            <Label wrap>{t("components.templates.settings.strokeColor")}</Label>
            <ColorPicker
              value={strokeColor}
              showText
              allowClear
              onClear={() => {
                setTextStyle({ subtitles_stroke_color: undefined });
                addUpdate();
              }}
              onChange={(color) => {
                setTextStyle({ subtitles_stroke_color: color.toHexString() });
                addUpdate();
              }}
            />
          </>
        )}
        <Label wrap>{t("components.templates.settings.textBackground")}</Label>
        <ColorPicker
          value={textBackground}
          showText
          allowClear
          onClear={() => {
            if (view === TemplateType.TRANSITION) {
              setTitleStyle({ titleBackground: undefined });
            } else {
              setTextStyle({ textBackground: undefined });
            }
            addUpdate();
          }}
          onChange={(color) => {
            if (view === TemplateType.TRANSITION) {
              setTitleStyle({ titleBackground: color.toHexString() });
            } else {
              setTextStyle({ textBackground: color.toHexString() });
            }
            addUpdate();
          }}
        />
      </Flex>
      {view !== TemplateType.TRANSITION && (
        <Flex vertical style={{ width: "fit-content" }} gap={10}>
          <Label wrap>{t("components.templates.settings.textAnimation")}</Label>
          <Radio.Group
            defaultValue={subtitlesAnimation}
            onChange={(e) => {
              setSubtitlesAnimation(e.target.value);
              addUpdate();
            }}
          >
            <Radio value={false}>
              {t("components.templates.settings.Basic")}
            </Radio>
            <Radio value={true}>
              {t("components.templates.settings.Animated")}
            </Radio>
          </Radio.Group>
        </Flex>
      )}
    </Flex>
  );
};

const BackgroundTab = () => {
  const { setBackground, storeBackground, addUpdate } = useVolatileBoundStore(
    (store) => ({
      setBackground: store.setBackground,
      storeBackground: store.templateState.background,
      addUpdate: store.addUpdate,
    })
  );

  return (
    <Flex
      vertical
      gap={20}
      id="infinite-scroll-bg"
      style={{
        height: "calc(100vh - 120px)",
        overflow: "auto",
        marginRight: "-24px",
      }}
    >
      <Flex vertical style={{ width: 120 }} gap={10}>
        <Label wrap>{t("components.templates.settings.headerColor")}</Label>
        <ColorPicker
          showText
          value={storeBackground.headerColor}
          onChange={(color) => {
            setBackground({
              headerColor: color.toHexString(),
              headerView: "Color",
            });
            addUpdate();
          }}
        />
        <Label wrap>{t("components.templates.settings.headerImage")}</Label>
      </Flex>
      <Flex vertical style={{ width: 120 }} gap={10}>
        <Label wrap>{t("components.templates.settings.bodyColor")}</Label>
        <ColorPicker
          showText
          value={storeBackground.bodyColor}
          onChange={(color) => {
            setBackground({
              bodyColor: color.toHexString(),
              bodyView: "Color",
            });
            addUpdate();
          }}
        />
      </Flex>
    </Flex>
  );
};

const PresenterSettings = ({
  initialPresenter,
  organizationId,
}: {
  initialPresenter: PresenterProfileResponse;
  organizationId: string;
}) => {
  const [contactModal, setContactModal] = useState(false);
  const [newContactSaveCallback, setNewContactSaveCallback] = useState<
    () => void
  >(() => {});

  const { setAvatarClose, avatarClose, presenter, setPresenter, addUpdate } =
    useVolatileBoundStore((store) => ({
      setAvatarClose: store.setAvatarClose,
      avatarClose: store.templateState.avatarClose,
      presenter: store.templateState.presenter,
      setPresenter: store.setPresenter,
      addUpdate: store.addUpdate,
    }));

  const { data: contactsListData, refetch } = useList<ContactResponse>({
    resource: `media/${organizationId}/contacts`,
    queryOptions: {
      enabled: !!organizationId,
    },
  });

  const contactList = contactsListData?.data;

  const hasPresenterViewChoice =
    presenter.presenter?.face_profile?.thumbnail_asset_path &&
    presenter.presenter?.face_profile?.extracted_asset_path &&
    presenter.presenter?.face_profile?.thumbnail_asset_path !==
      presenter.presenter?.face_profile?.extracted_asset_path;

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    if (checked) {
      setPresenter({ presenter: initialPresenter });
    } else {
      setPresenter({ presenter: undefined });
    }
    addUpdate();
  };

  const refreshContactList = () => {
    refetch();
  };

  return (
    <Flex vertical gap={30}>
      <Checkbox
        defaultChecked={Boolean(presenter.presenter)}
        onChange={onCheckboxChange}
      >
        {t("components.templates.settings.showPresenter")}
      </Checkbox>
      <Flex vertical gap={10}>
        <Label wrap>{t("components.templates.settings.presenters")}</Label>
        {contactList && (
          <Flex gap={30} wrap="wrap">
            {contactList.map((contact: ContactResponse) => (
              <PresenterView contact={contact} key={contact.id} />
            ))}
          </Flex>
        )}
        <AddAssetButton
          title="new presenter"
          onClick={() => setContactModal(true)}
        />
        <ContactAddingModal
          contactsModal={contactModal}
          setContactsModal={setContactModal}
          newContactSaveCallback={newContactSaveCallback}
          setNewContactSaveCallback={setNewContactSaveCallback}
          onFinish={refreshContactList}
        />
      </Flex>

      {hasPresenterViewChoice && (
        <Flex vertical style={{ width: "fit-content" }} gap={10}>
          <Label wrap>{t("components.templates.settings.avatarSize")}</Label>
          <Radio.Group
            defaultValue={avatarClose}
            onChange={(e) => {
              setAvatarClose(e.target.value);
              addUpdate();
            }}
          >
            <Radio value={false}>
              {t("components.templates.settings.standard")}
            </Radio>
            <Radio value={true}>
              {t("components.templates.settings.closeUp")}
            </Radio>
          </Radio.Group>
        </Flex>
      )}
      <Flex vertical gap={10}>
        <Label wrap>{t("components.templates.settings.avatarShape")}</Label>
        <Radio.Group
          defaultValue={presenter.avatarCircle}
          onChange={(e) => {
            setPresenter({ avatarCircle: e.target.value });
            addUpdate();
          }}
        >
          <Radio value={false}>
            {t("components.templates.settings.rectangle")}
          </Radio>
          <Radio value={true}>
            {t("components.templates.settings.circle")}
          </Radio>
        </Radio.Group>
        {presenter.avatarCircle && (
          <>
            <Label wrap>{t("components.templates.settings.circleColor")}</Label>
            <ColorPicker
              showText
              value={presenter.background}
              onChange={(color) => {
                setPresenter({ background: color.toHexString() });
                addUpdate();
              }}
              style={{ width: "fit-content" }}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

const PresenterView = ({ contact }: { contact: ContactResponse }) => {
  const { data: presenterData } = useOne<PresenterProfileResponse>({
    resource: `media/${contact.organization_id}/presenter_profiles`,
    id: contact.presenter_id,
  });

  const { setPresenter, addUpdate } = useVolatileBoundStore((store) => ({
    setPresenter: store.setPresenter,
    addUpdate: store.addUpdate,
  }));

  if (!presenterData?.data) return <></>;

  return (
    <Flex
      onClick={() => {
        setPresenter({
          presenter: presenterData?.data,
          contact_id: contact.id,
        });
        addUpdate();
      }}
    >
      <PresenterProfilePreviewView presenter={presenterData?.data} />
    </Flex>
  );
};

const AddToTemplateButton = ({
  assetId,
  position,
  size,
}: {
  assetId: string;
  position?: { x: number; y: number };
  size?: { width: number; height: number };
}) => {
  const { addImage, setBackground } = useVolatileBoundStore((store) => ({
    addImage: store.addImage,
    setBackground: store.setBackground,
  }));

  const { data: imageAsset } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id: assetId,
  });

  const handleAddToTemplate = () => {
    addImage({
      assetId,
      position: position ?? { x: 0, y: 100 },
      size: size ?? { width: 50, height: 50 },
    });
  };

  const items = [
    {
      key: "1",
      label: (
        <Button type="text" onClick={handleAddToTemplate}>
          {t("components.templates.settings.addToTemplate")}
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="text"
          onClick={() => {
            setBackground({
              headerImage: imageAsset?.data?.path,
              headerImageAsset: assetId,
              headerView: "Picture",
            });
          }}
        >
          {t("components.templates.settings.useAsHeaderBackground")}
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="text"
          onClick={() => {
            setBackground({
              bodyImage: imageAsset?.data?.path,
              bodyImageAsset: assetId,
              bodyView: "Picture",
            });
          }}
        >
          {t("components.templates.settings.useAsBodyBackground")}
        </Button>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottomLeft"
      trigger={["click"]}
      destroyPopupOnHide
    >
      <Space style={{ cursor: "pointer" }}>
        <PlusOutlined style={{ fontSize: 8 }} />
        <Label wrap ellipsis>
          {t("components.templates.settings.useImage")}
        </Label>
      </Space>
    </Dropdown>
  );
};

const ChooseVideoButton = ({ onChoose }: { onChoose: () => void }) => {
  const { addUpdate } = useVolatileBoundStore((store) => ({
    addUpdate: store.addUpdate,
  }));
  return (
    <Button
      onClick={() => {
        onChoose();
        addUpdate();
      }}
      size="small"
      type="text"
      icon={<PlusOutlined style={{ fontSize: 8 }} />}
      style={{
        fontSize: 12,
      }}
    >
      {t("components.templates.settings.choose")}
    </Button>
  );
};

const SwitchComponent = ({
  show,
  view,
  isLoading = false,
  onSwitchChange,
}: {
  show: boolean;
  view: string;
  isLoading?: boolean;
  onSwitchChange: () => void;
}) => {
  return (
    <>
      <Typography.Text>
        {show
          ? t(`components.templates.settings.settingEnabled`, { view })
          : t(`components.templates.settings.settingDisabled`, { view })}
      </Typography.Text>
      <Space>
        {show
          ? t("components.templates.settings.disable")
          : t("components.templates.settings.enable")}
        <Switch
          defaultChecked={show}
          style={{ width: 50 }}
          loading={isLoading}
          onChange={onSwitchChange}
        />
      </Space>
    </>
  );
};

const EffectsSettings = () => {
  const [api, contextHolder] = notification.useNotification();
  const { theme } = useAntTheme();

  const {
    currentItem,
    images,
    updateImage,
    textStyle,
    titleStyle,
    setTextStyle,
    setTitleStyle,
    presenter,
    setPresenter,
  } = useVolatileBoundStore((store) => ({
    currentItem: store.templateState.currentItem,
    images: store.templateState.images,
    updateImage: store.updateImage,
    textStyle: store.templateState.textStyle,
    titleStyle: store.templateState.titleStyle,
    setTextStyle: store.setTextStyle,
    setTitleStyle: store.setTitleStyle,
    presenter: store.templateState.presenter,
    setPresenter: store.setPresenter,
  }));

  const currentImage = images?.find((image) => image.id === currentItem?.id);

  const isEffectPicked = (effect: string) => {
    if (!currentItem) return false;

    switch (currentItem.type) {
      case "Image":
        return currentImage?.[`${effect}`] ?? false;
      case "Subtitles":
        return textStyle[`${effect}`];
      case "Title":
        return titleStyle[`${effect}`];
      case "Presenter":
        return presenter[`${effect}`];
      default:
        return false;
    }
  };

  const handleChangeEffect = (effect: string) => {
    if (!currentItem) {
      api.info({
        message: t("components.templates.settings.selectItem"),
      });
      return;
    }

    const itemObjects = {
      Image: {
        item: images.find((image) => image.id === currentItem.id),
        func: updateImage,
      },
      Presenter: {
        item: presenter,
        func: setPresenter,
      },
      Title: {
        item: titleStyle,
        func: setTitleStyle,
      },
      Subtitles: {
        item: textStyle,
        func: setTextStyle,
      },
    };

    const currentItemObject = itemObjects[currentItem.type];
    const id = currentItem.id ?? undefined;

    if (effect === "fadeIn") {
      currentItemObject.func({
        id,
        fadeIn: !currentItemObject.item?.fadeIn,
        fadeOut:
          !currentItemObject.item?.fadeIn === true
            ? false
            : currentItemObject.item?.fadeOut,
      });
    } else if (effect === "fadeOut") {
      currentItemObject.func({
        id,
        fadeOut: !currentItemObject.item?.fadeOut,
        fadeIn:
          !currentItemObject.item?.fadeOut === true
            ? false
            : currentItemObject.item?.fadeIn,
      });
    }
  };

  const effects = Object.values(Effects);

  return (
    <>
      {contextHolder}
      {effects.map((effect) => {
        return (
          <Card
            key={effect}
            style={{
              border: "1px solid",
              borderColor: isEffectPicked(effect)
                ? theme.colorPrimary
                : theme.colorBgContainer,
            }}
            onClick={() => handleChangeEffect(effect)}
          >
            {t(`components.templates.settings.effects.${effect}`)}
          </Card>
        );
      })}
    </>
  );
};
export type AssetNames = "images" | "video" | "music" | "fonts";
const StyledTabs = styled(Tabs)`
  .ant-tabs-tabpane {
    padding: 0;
  }
  .ant-tabs-nav-list {
    gap: 4px;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    border: 1px solid transparent !important;
    border-radius: 6px !important;
    background: none !important;
    opacity: 0.7;
  }
  .ant-tabs-tab-btn {
    width: 100%;
  }
  .ant-tabs-tab-active {
    opacity: 1;
    transition: 500ms;
    background: ${({ theme }) => theme.colorPrimaryBg}!important;
    border: 1px solid ${({ theme }) => theme.colorPrimaryBorder}!important;
    span {
      color: ${({ theme }) => theme.colorPrimary}!important;
    }
  }
`;
function getRowKey(image: BrandKitAsset | Partial<MediaAsset>) {
  if (!image) return undefined;
  return "asset_id" in image
    ? image?.asset_id
    : "id" in image
      ? image?.id
      : undefined;
}
function infiniteScrollFix({
  t,
  tab,
  hasMore,
  dataMap,
}: {
  t: TFunction;
  tab: string;
  hasMore: boolean;
  dataMap: Record<string, Partial<MediaAsset>[]>;
}) {
  const mediaLibraryTabsMap = {
    [t("components.templates.settings.images")]: [
      "images",
      "infinite-scroll-images",
    ],
    [t("components.templates.Introduction")]: [
      "video",
      "infinite-scroll-intro",
    ],
    [t("components.templates.Transition")]: [
      "video",
      "infinite-scroll-transition",
    ],
    [t("components.templates.settings.background")]: [
      "images",
      "infinite-scroll-bg",
    ],
    [t("components.templates.settings.text")]: ["fonts"],
    [t("components.templates.settings.music")]: [
      "audio",
      "infinite-scroll-music",
    ],
    [t("components.templates.Closing")]: ["video", "infinite-scroll-closing"],
  };
  // A hack to fetch initial items when height of current items <
  // height of the container
  if (hasMore) {
    interval = setInterval(() => {
      if (dataMap[mediaLibraryTabsMap[tab][0]].length < 10 && hasMore) {
        const element = document.getElementById(mediaLibraryTabsMap[tab][1]);
        element?.dispatchEvent(new Event("scroll"));
      }
    });
  } else {
    clearInterval(interval);
  }
}
