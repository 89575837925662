import { LinkSimpleHorizontal } from "@phosphor-icons/react";
import { useList, useTranslate } from "@refinedev/core";
import { MediaProjectPublicationBase } from "pages/media/types";
import { FatButton } from "components/FatButton";
import { useAntTheme } from "hooks/useAntTheme";
import RotatingBorder from "../../components/RotatingBorder";
import { useOpenPublication } from "../hooks/useOpenPublication";
import { sortByDate } from "helpers/utils";

export const ViewExperienceButton = ({
  projectId,
  mediaStatus,
  mediaId,
}: {
  projectId: string;
  mediaId: string;
  mediaStatus: string;
}) => {
  const t = useTranslate();
  const { theme } = useAntTheme();
  const { data: publications, isLoading } =
    useList<MediaProjectPublicationBase>({
      resource: `media/projects/${projectId}/publications`,
    });

  // upload my video flow will create a new media + new auto. publication on upload
  // make sure we take the latest publication with this specific media
  const sortedMediaPublications = sortByDate(
    // TODO URGENT we need to make the model stronger with seb to support multiple publications
    // the media_id changes when Published
    publications?.data,
    // ?.filter((x) => x.media_id === mediaId),
    "created",
    "DESC"
  );
  const publicationId = sortedMediaPublications?.[0]?.project_publication_id;
  const { openPublication } = useOpenPublication({
    projectId,
    publicationId,
    requiresAccessToken: mediaStatus === "Preview",
  });

  // might not be available publication at all !
  if (isLoading) {
    return null;
  }

  return (
    <RotatingBorder
      color={theme.colorSecondaryBorder}
      shadeColor={"#ff1493"}
      speed={7}
      padding={1}
      borderRadius={6}
    >
      <FatButton
        block
        color="secondary"
        icon={<LinkSimpleHorizontal size={44} />}
        title={t("media.projects.detail.openDocustream")}
        onClick={openPublication}
        style={{ height: 160, width: 250 - 2, border: 0 }}
      />
    </RotatingBorder>
  );
};
