import React, { useMemo } from "react";
import {
  Card,
  Col,
  ColorPicker,
  Flex,
  Form,
  FormInstance,
  Row,
  ColorPickerProps,
  Button,
  Space,
  Typography,
} from "antd";
import {
  green,
  red,
  cyan,
  blue,
  purple,
  orange,
  magenta,
} from "@ant-design/colors";
import { PaletteResponse } from "pages/media/types";
import styled from "styled-components";
import { nanoid } from "nanoid";
import { useTranslate } from "@refinedev/core";
import { StopOutlined } from "@ant-design/icons";

const { Text } = Typography;
const SWATCH_SIZE = 18;

const colorPickerProps: ColorPickerProps = {
  showText: true,
  format: "hex",
  size: "large",
  disabledAlpha: true,
};

const MainColorsSelect = ({
  colors,
  onColorClick,
}: {
  colors: PaletteResponse;
  onColorClick: (color: string) => void;
}) => {
  const t = useTranslate();
  return (
    <Flex
      gap={10}
      align="center"
      justify="space-between"
      style={{ width: "100%" }}
    >
      {colors.primary_color && (
        <Flex
          gap={6}
          align="center"
          onClick={() => onColorClick(colors.primary_color)}
        >
          <div
            style={{
              width: SWATCH_SIZE,
              height: SWATCH_SIZE,
              backgroundColor: colors.primary_color,
              borderRadius: 4,
              cursor: "pointer",
            }}
          />
          <Text style={{ fontSize: 10 }}>
            {t(`media.brandkit.detail.primaryColor`)}
          </Text>
        </Flex>
      )}
      {colors.secondary_color && (
        <Flex
          gap={6}
          align="center"
          onClick={() => onColorClick(colors.secondary_color)}
        >
          <div
            style={{
              width: SWATCH_SIZE,
              height: SWATCH_SIZE,
              backgroundColor: colors.secondary_color,
              borderRadius: 4,
              cursor: "pointer",
            }}
          />
          <Text style={{ fontSize: 10 }}>
            {t(`media.brandkit.detail.secondaryColor`)}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export const ColorPickerCard = ({
  colorKey,
  allowClear,
  defaultValue,
  form,
  setColors,
  value,
  required,
}: {
  colorKey: string;
  allowClear?: boolean;
  defaultValue?: string;
  form: FormInstance;
  setColors: React.Dispatch<React.SetStateAction<PaletteResponse>>;
  value?: ColorPickerProps["value"];
  required?: boolean;
}) => {
  const t = useTranslate();

  const getCurrentFormColors = () => ({
    primary_color: form.getFieldValue(["colors", "primary_color"]) || undefined,
    secondary_color:
      form.getFieldValue(["colors", "secondary_color"]) || undefined,
  });

  const resetColor = (colorKey: string) => {
    form.setFieldValue(["colors", colorKey], null);
    setColors((prev) => ({
      ...prev,
      [colorKey]: undefined,
    }));
  };

  const handleColorChange = (color: string) => {
    form.setFieldValue(["colors", colorKey], color);
    setColors((prev) => ({
      ...prev,
      [colorKey]: color,
    }));
  };

  const genPresets = useMemo(
    () => [
      { label: nanoid(), colors: red },
      { label: nanoid(), colors: green },
      { label: nanoid(), colors: cyan },
      { label: nanoid(), colors: blue },
      { label: nanoid(), colors: purple },
      { label: nanoid(), colors: orange },
      { label: nanoid(), colors: magenta },
    ],
    []
  );

  const customPanelRender: ColorPickerProps["panelRender"] = (
    _,
    { components: { Picker, Presets } }
  ) => (
    <Row justify="space-between" wrap={false}>
      <Col flex="auto" xs={24} sm={11}>
        <Flex vertical gap={5}>
          {allowClear && (
            <Space>
              <Button
                shape="round"
                size="small"
                icon={<StopOutlined />}
                onClick={() => resetColor(colorKey)}
              >
                {t("components.ColorPicker.resetColor")}
              </Button>
            </Space>
          )}
          <Picker />
        </Flex>
      </Col>
      <StyledPickerCol flex="auto" xs={0} sm={11}>
        <Flex vertical gap={6}>
          <MainColorsSelect
            colors={getCurrentFormColors()}
            onColorClick={handleColorChange}
          />
          <Presets />
        </Flex>
      </StyledPickerCol>
    </Row>
  );

  return (
    <Card
      style={{
        width: "100%",
      }}
      size="small"
    >
      <Form.Item
        initialValue={value}
        label={t(`media.brandkit.create.${colorKey}`)}
        name={["colors", colorKey]}
        rules={
          required
            ? [{ required: true, message: t("forms.requiredField") }]
            : []
        }
      >
        <ColorPicker
          {...colorPickerProps}
          value={form.getFieldValue(["colors", colorKey])}
          defaultValue={defaultValue}
          size="large"
          presets={genPresets}
          styles={{
            popupOverlayInner: { minWidth: "calc(min(520px, 80vw))" },
          }}
          panelRender={customPanelRender}
          onChange={(color) => handleColorChange(color.toHexString())}
          showText={(color) =>
            !color.cleared
              ? color.toHexString().toUpperCase()
              : t(`media.brandkit.detail.defaultColor`)
          }
        />
      </Form.Item>
    </Card>
  );
};

const StyledPickerCol = styled(Col)`
  .ant-color-picker-presets {
    .ant-collapse-header {
      // force hide preset name
      display: none !important;
    }
    .ant-color-picker-presets-color {
      width: ${SWATCH_SIZE}px !important;
      height: ${SWATCH_SIZE}px !important;
      &:before {
        width: ${SWATCH_SIZE + 4}px !important;
        height: ${SWATCH_SIZE + 4}px !important;
      }
      &:after {
        // checkmark
        height: 10px !important;
      }
    }
  }
`;
