import { DeleteButton, Edit, ListButton, Show } from "@refinedev/antd";
import {
  Authenticated,
  GetOneResponse,
  useCreate,
  useForm,
  useList,
  useNavigation,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { Card, Flex, Form, Input, Select, Spin, Tabs } from "antd";
import UploadFiles, { UploadFilesRef } from "components/UploadFiles";
import { useOrganization } from "hooks/useOrganization";
import { FontPreviewCard } from "pages/media/brandkit/components/FontPreview";
import { PicturePreview } from "pages/media/brandkit/components/PicturePreview";
import VideoPreview from "pages/media/brandkit/components/VideoPreview";
import { AudioSample } from "pages/media/components/AudioSample";
import { AssetNames } from "pages/media/components/templates/TemplateSettings";
import {
  AssetLibrary,
  AssetSource,
  CreateAssetResponse,
  MediaAsset,
} from "pages/media/types";
import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
const CREATE_ASSET_REQUEST_TYPE = "CreateLibraryAsset";
export const useSortedAssets = (assets: Partial<MediaAsset>[]) => {
  const images = useMemo(
    () => assets?.filter((asset) => asset.category === "AssetImage"),
    [assets]
  );
  const music = useMemo(
    () => assets?.filter((asset) => asset.category === "Music"),
    [assets]
  );
  const fonts = useMemo(
    () => assets?.filter((asset) => asset.category === "Font"),
    [assets]
  );
  const videos = useMemo(
    () => assets?.filter((asset) => asset.category === "Video"),
    [assets]
  );
  return { images, music, fonts, videos, assets };
};
const AssetLibraryDetail = forwardRef<
  { handleClickSave: () => void },
  {
    id?: string;
    isEdit?: boolean;
    noLayout?: boolean;
    noNavigateAfterSave?: boolean;
    activeTab?: "images" | "video" | "music" | "fonts";
    organizationId?: string;
    onUploadComplete?: (assets: Partial<MediaAsset>[]) => void;
    setCurrentAssetLibraryId?: (id: string) => void;
    currentAssetLibraryId?: string;
    libraries?: AssetLibrary[];
    setPage?: (page: number) => void;
  }
>(
  (
    {
      id,
      isEdit,
      noLayout,
      noNavigateAfterSave,
      activeTab,
      organizationId,
      onUploadComplete,
      setCurrentAssetLibraryId,
      libraries,
      setPage,
    },
    ref
  ) => {
    const params = useParams();
    let isModal = true;
    const { resource } = useResource();
    if (!id && resource?.name === "media_asset_libraries") {
      id = params.id;
      isModal = false;
    }

    const { show } = useNavigation();
    const [form] = Form.useForm();
    const t = useTranslate();
    const { organization } = useOrganization({});
    if (!organizationId) {
      organizationId = organization?.id;
    }
    const [myId, setMyId] = useState<string>(id);

    const { onFinish, formLoading, queryResult } = useForm<AssetLibrary>({
      action: "edit",
      resource: `/media/asset_libraries`,
      id: myId,
      queryOptions: {
        enabled: !!myId,
      },
    });
    const { mutateAsync: createMediaLibrary } = useCreate<AssetLibrary>();

    if (!queryResult?.data?.data && !formLoading && !myId) {
      // create a new asset library
      setMyId("-");
      createMediaLibrary({
        resource: `/media/${organizationId}/asset_libraries`,
        values: {
          name: "Default",
          id: myId,
        },
      })
        .then((response) => {
          if (response) {
            setMyId(response?.data.id);
            setCurrentAssetLibraryId?.(response?.data.id);
          }
        })
        .catch((error) => {});
    }
    const uploadPicturesRef = useRef<UploadFilesRef>(null);
    const uploadMusicRef = useRef<UploadFilesRef>(null);
    const uploadFontsRef = useRef<UploadFilesRef>(null);
    const uploadVideoRef = useRef<UploadFilesRef>(null);
    const [activeKey, setActiveKey] = useState(activeTab ?? "images");
    const [newAssets, setNewAssets] = useState([]);
    const { images, music, fonts, videos } = useMediaLibraryAssets(
      myId ?? queryResult?.data?.data?.id,
      organizationId,
      1,
      newAssets,
      setNewAssets
    );
    const handleClickSave = async () => {
      const values = form.getFieldsValue();

      await uploadPicturesRef.current?.upload(
        `/media/asset_libraries/${myId}/assets`
      );
      await uploadMusicRef.current?.upload(
        `/media/asset_libraries/${myId}/assets`
      );
      await uploadFontsRef.current?.upload(
        `/media/asset_libraries/${myId}/assets`
      );
      await uploadVideoRef.current?.upload(
        `/media/asset_libraries/${myId}/assets`
      );
      await onFinish(values);
      if (!noNavigateAfterSave) {
        show(`media_asset_libraries`, myId);
      }
    };
    const assetLibrary = queryResult?.data;
    if (form.getFieldValue("name") === undefined && assetLibrary?.data) {
      form.resetFields();
    }

    useImperativeHandle(ref, () => ({
      handleClickSave,
      myId,
    }));

    const tabs = [
      {
        label: t("asset_libraries.images"),
        key: "images",
        children: (
          <Flex wrap={"wrap"} gap={10}>
            {images?.map((image) => (
              <PicturePreview
                fileName="image"
                key={image.id}
                image={image.path}
              />
            ))}
          </Flex>
        ),
        uploadProps: {
          ref: uploadPicturesRef,
          defaultItems: images,
          acceptedMimeTypes: "image/jpeg, image/png, .png, .jpeg, .jpg",
          itemRequestProperties: (file) => ({
            type: CREATE_ASSET_REQUEST_TYPE,
            organization_id: organizationId,
            category: "AssetImage" as const,
            source: AssetSource.UIGenerated,
            description: file?.name,
            asset: {
              type: "Picture" as const,
            },
          }),
          preview: (file) => (
            <PicturePreview
              fileName={file.name}
              key={file.id}
              image={file.originFileObj}
            />
          ),
        },
      },
      {
        label: t("asset_libraries.music"),
        key: "audio",
        children: (
          <Flex wrap={"wrap"} gap={10}>
            {music?.map((audio) => (
              <Card key={audio.id} size="small" style={{ minWidth: 200 }}>
                <Flex gap={10} justify="space-between" align="center">
                  <AudioSample
                    canDelete={false}
                    audio={audio.path}
                  ></AudioSample>
                  <DeleteButton
                    accessControl={{ enabled: false }}
                    hideText
                    recordItemId={audio.id}
                    resource={`/media/asset_libraries/${myId}/assets/${audio?.id}`}
                  />
                </Flex>
              </Card>
            ))}
          </Flex>
        ),
        uploadProps: {
          ref: uploadMusicRef,
          defaultItems: music,
          acceptedMimeTypes: "audio/mp3, .mp3",

          itemRequestProperties: (file) => ({
            type: CREATE_ASSET_REQUEST_TYPE,
            organization_id: organization?.id,
            description: file.name,
            category: "Music" as const,
            source: AssetSource.UIGenerated,
            asset: {
              type: "Picture" as const,
            },
          }),
          preview: (file) => (
            <AudioSample
              canDelete={false}
              audio={file.originFileObj}
            ></AudioSample>
          ),
        },
      },
      {
        label: t("asset_libraries.fonts"),
        key: "fonts",
        children: (
          <Flex wrap={"wrap"} gap={10}>
            {fonts?.map((font) => (
              <FontPreviewCard key={font.id} title={"Font"} font={font.path} />
            ))}
          </Flex>
        ),
        uploadProps: {
          ref: uploadFontsRef,
          defaultItems: fonts,
          acceptedMimeTypes: "font/ttf, .ttf",

          itemRequestProperties: (file: File) => ({
            type: CREATE_ASSET_REQUEST_TYPE,
            organization_id: organizationId,
            category: "Font" as const,
            description: file.name,
            source: AssetSource.UIGenerated,
            asset: {
              type: "Picture" as const,
            },
          }),
          preview: (file) => (
            <FontPreviewCard
              key={file.id}
              title={"Font"}
              font={file.originFileObj}
            />
          ),
        },
      },
      {
        label: t("asset_libraries.videos"),
        key: "video",
        children: (
          <Flex wrap={"wrap"} gap={10}>
            {videos?.map((video) => (
              <VideoPreview
                fileName={video.description}
                video={video.path}
                key={video.id}
                actions={[]}
              />
            ))}
          </Flex>
        ),
        uploadProps: {
          ref: uploadVideoRef,
          defaultItems: videos,

          acceptedMimeTypes: "video/mp4, .mp4",
          itemRequestProperties: (file) => ({
            type: CREATE_ASSET_REQUEST_TYPE,
            organization_id: organizationId,
            description: file.name,
            category: "Video" as const,
            source: AssetSource.UIGenerated,
            asset: {
              type: "Video" as const,
            },
          }),
          preview: (file) => (
            <VideoPreview
              actions={[]}
              fileName={"Video"}
              video={file.originFileObj}
            />
          ),
        },
      },
    ];

    const activeTabUploadProps = tabs.find(
      (tab) => tab.key === activeKey
    ).uploadProps;
    let LayoutComponent = isEdit ? Edit : Show;

    let layoutProps: Record<string, unknown> = {
      isLoading: formLoading,
      canDelete: true,
      saveButtonProps: { onClick: handleClickSave },
      deleteButtonProps: {
        resource: `/media/asset_libraries/`,
        resourceItemId: myId,
        accessControl: { enabled: false },
      },
      headerButtons: ({ deleteButtonProps, listButtonProps }) => (
        <>
          {<ListButton {...listButtonProps} />}
          {deleteButtonProps && (
            <DeleteButton
              {...deleteButtonProps}
              accessControl={{ enabled: false }}
              recordItemId={myId}
              resource={`media/asset_libraries`}
            />
          )}
        </>
      ),
    };
    if (noLayout) {
      LayoutComponent = Fragment;
      layoutProps = {};
    }
    return (
      <Authenticated key="asset-library-detail">
        <LayoutComponent {...layoutProps}>
          <Form
            form={form}
            initialValues={assetLibrary?.data}
            layout="horizontal"
            labelCol={{
              span: 3,
            }}
            wrapperCol={{
              span: 20,
            }}
            labelAlign="right"
          >
            {libraries?.length > 1 && isModal && (
              <Form.Item label={t("asset_libraries.select_library")}>
                <Select
                  optionFilterProp="label"
                  defaultValue={myId}
                  style={{ width: "100%" }}
                  showSearch
                  options={libraries.map((lib) => {
                    return {
                      label: lib.name,
                      value: lib.id,
                    };
                  })}
                  onChange={(value) => {
                    setMyId(value);
                    setCurrentAssetLibraryId?.(value);
                    form.setFieldsValue(
                      libraries.find((lib) => lib.id === value)
                    );
                    setPage(1);
                  }}
                />
              </Form.Item>
            )}
            <Form.Item label={t("asset_libraries.name")} name="name">
              <Input />
            </Form.Item>
          </Form>
          <Spin spinning={formLoading}>
            <Tabs
              items={tabs}
              onChange={(tab) => setActiveKey(tab as AssetNames)}
              defaultActiveKey={activeTab}
            />
          </Spin>
          {isEdit && (
            <UploadFiles
              {...activeTabUploadProps}
              maxFileSize={120}
              onUploadComplete={async (files, responses) => {
                // Insert the newly created asset into the list for immediate
                // usage without waiting for the backend to process it
                const itemRequestProperties =
                  activeTabUploadProps.itemRequestProperties;
                const newAssets = responses.map((response, index) => {
                  return {
                    id: (response as GetOneResponse<CreateAssetResponse>).data
                      .asset_id,
                    ...itemRequestProperties(files[index]?.originFileObj),
                    path: URL.createObjectURL(files[index]?.originFileObj),
                  };
                });
                setNewAssets(newAssets);
                onUploadComplete?.(newAssets);
              }}
            />
          )}
        </LayoutComponent>
      </Authenticated>
    );
  }
);

export default AssetLibraryDetail;
AssetLibraryDetail.displayName = "AssetLibraryDetail";
export const useMediaLibraryAssets = (
  id: string,
  organizationId?: string,
  page?: number,
  newAssets?: Partial<MediaAsset>[] | null,
  setNewAssets?: (assets: Partial<MediaAsset>[]) => void
) => {
  const prevId = useRef<string>(id);
  const [allAssets, setAllAssets] = useState<Partial<MediaAsset>[]>(
    newAssets ?? []
  );
  const { data: assetLibraryAssets, isLoading: isLoadingAssetLibraryAssets } =
    useList<MediaAsset | { id: string; skeleton: boolean }>({
      resource: `/media/asset_libraries/${id}/assets`,
      pagination: {
        current: page,
      },
      queryOptions: {
        enabled: !!id && !!organizationId,
      },
    });
  useEffect(() => {
    const nextPageAssets = assetLibraryAssets?.data.filter(
      (asset) => !allAssets.some((a) => a.id === asset?.id)
    );
    if (assetLibraryAssets?.data) {
      const uploadedAssets = newAssets?.filter(
        (asset) => !allAssets.some((a) => a.id === asset?.id)
      );
      if (prevId.current !== id) {
        setAllAssets?.([]);
        prevId.current = id;
      } else {
        if (uploadedAssets?.length > 0 || nextPageAssets?.length > 0) {
          setAllAssets?.((prev) => [
            ...(uploadedAssets ?? []),
            ...prev,
            ...nextPageAssets,
          ]);
        }
      }
      setNewAssets?.(null);
    }
  }, [assetLibraryAssets?.data, allAssets, newAssets, id, setNewAssets]);
  const { images, music, fonts, videos, assets } = useSortedAssets(allAssets);
  return {
    hasMore:
      assetLibraryAssets?.data?.length > 0 &&
      assetLibraryAssets?.data?.length % 10 === 0,
    assets,
    images,
    music,
    fonts,
    videos,
    isLoadingAssetLibraryAssets,
    assetLibraryAssets,
  };
};
