import React from "react";
import TextArea, { TextAreaProps } from "antd/es/input/TextArea";
import { useBoundStore } from "store";
import type { StepWithDescription } from "./types";
export const OtherInput: React.FC<TextAreaProps> = ({
  onChange,
  placeholder,
}) => {
  const { onboardingState } = useBoundStore((state) => {
    return {
      onboardingState: state.onboardingState,
    };
  });
  const { currentStep } = onboardingState;
  let defaultValue = "";
  if (isStepWithDescription(onboardingState[currentStep])) {
    defaultValue = onboardingState[currentStep]?.other ?? "";
  }
  return (
    <TextArea
      onChange={onChange}
      name={"other_value"}
      showCount
      defaultValue={defaultValue}
      maxLength={235}
      placeholder={placeholder}
      style={{ width: "100%" }}
    />
  );
};

function isStepWithDescription(step: any): step is StepWithDescription {
  return step.hasOwnProperty("other");
}
