import { useTranslate } from "@refinedev/core";
import { useNotification } from "@refinedev/core";
import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { MediaProject, ProjectMediaResponse } from "pages/media/types";

import UploadFiles, { UploadFilesRef } from "components/UploadFiles";
import { PaperClipOutlined } from "@ant-design/icons";

export const ProjectMediaUpload = ({
  projectData: project,
  onUploadSuccess,
  media,
}: {
  projectData: MediaProject;
  onUploadSuccess?: Function;
  media: ProjectMediaResponse;
}) => {
  const t = useTranslate();
  const [processingMedia, setProcessingMedia] = useState(false);

  const { open } = useNotification();
  const uploadFilesRef = useRef<UploadFilesRef>(null);

  const uploadFiles = uploadFilesRef?.current?.upload;
  const handleFinish = async () => {
    setProcessingMedia(true);
  };

  useEffect(() => {
    (async () => {
      if (processingMedia && uploadFiles) {
        try {
          await uploadFiles(`/media/projects/${project.id}/assets`);
          onAssetUploadSuccess();
        } catch {
          open!({
            message: t("projects.components.ProjectMediaUpload.error"),
            description: t(
              "projects.components.ProjectMediaUpload.failedToUpload"
            ),
            type: "error",
          });
        }
      }
    })();
  }, [processingMedia]);

  const onAssetUploadSuccess = async () => {
    setProcessingMedia(true);

    await onUploadSuccess?.();
    setProcessingMedia(false);
  };

  return (
    <>
      <UploadFiles
        ref={uploadFilesRef}
        onAbort={() => {
          setProcessingMedia(false);
        }}
        itemRequestProperties={{
          type: "CreateProjectMediaAsset",
          organization_id: project?.organization_id,
          category: "AssetImage",
          source: "UiGenerated",
          contact_id: media.contact_id,
          asset: {
            type: "Video",
          },
          media_settings_id: "default", // TODO use id from API when available
        }}
        onUploadComplete={() => {
          onUploadSuccess?.();
        }}
        maxCount={1}
        accept={"video/mp4"}
        acceptedMimeTypes="video/mp4"
        multiple={false}
        name="file"
      />
      <Button
        onClick={handleFinish}
        type="default"
        style={{ marginTop: 30 }}
        icon={<PaperClipOutlined />}
        disabled={processingMedia}
      >
        {t("buttons.attach") as string}
      </Button>
    </>
  );
};
