import { Badge, BadgeProps, Spin, Tag, Tooltip } from "antd";
import { ReactNode } from "react";
import type { StoryboardStatus } from "../types";
import styled from "styled-components";
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAntTheme } from "hooks/useAntTheme";
import { t } from "i18n";

const STATUS_COLORS: Record<StoryboardStatus, BadgeProps["status"]> = {
  Pending: "default",
  Approved: "success",
  Generating: "processing",
  Reviewing: "warning",
  WaitingUserInput: "warning",
};

const STATUS_ICONS: Record<StoryboardStatus, ReactNode> = {
  Pending: <></>,
  Generating: <SyncOutlined spin />,
  Approved: <CheckOutlined />,
  Reviewing: <ExclamationCircleOutlined />,
  WaitingUserInput: <UserOutlined />,
};
const STATUS_LABELS: Record<StoryboardStatus, string | null> = {
  Pending: "Pending",
  Generating: "Generating",
  Approved: "Approved",
  Reviewing: "Reviewing",
  WaitingUserInput: "WaitingUserInput",
};

export const StoryboardStatusBadge = ({
  status,
  label,
}: {
  status: StoryboardStatus;
  label?: ReactNode;
}) => {
  const color = STATUS_COLORS[status];
  const { theme } = useAntTheme();
  return (
    <>
      {label ? (
        <Tooltip title={t(`media.components.StoryboardStatus.${status}`)}>
          <div style={{ position: "relative" }}>
            {["Generating"].includes(status) && (
              <StyledSpin
                spinning
                size="small"
                style={{ color: theme.colorInfo, height: 20, width: 20 }}
                indicator={<LoadingOutlined />}
              />
            )}
            <Badge status={color} text={label} />
          </div>
        </Tooltip>
      ) : (
        <Badge status={color} text={label} />
      )}
    </>
  );
};

export const StoryboardStatusTag = ({
  status,
  tooltip,
}: {
  status: StoryboardStatus;
  tooltip?: ReactNode;
}) => {
  const color = STATUS_COLORS[status];
  const { theme } = useAntTheme();
  const label = STATUS_LABELS[status] ?? status;

  return (
    <Tooltip title={tooltip}>
      <Tag
        color={color}
        icon={STATUS_ICONS[status]}
        style={{ border: 0, borderRadius: 100 }}
      >
        {t(`media.components.MediaStatus.${label}`)}
      </Tag>
    </Tooltip>
  );
};

const StyledSpin = styled(Spin)`
  top: 7px;
  left: -6px;
  position: absolute;
`;
