import { useAntTheme } from "hooks/useAntTheme";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useVolatileBoundStore } from "store";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./TemplatePreview";
import { getRectangleRelativeSize } from "./helpers/helper";

export const DraggableComponent = ({
  children,
  position,
  size,
  drag,
  resize,
  id,
  saveRatio = false,
  onDragStop,
  onFocus,
}: {
  children: ReactNode;
  position: {
    x: number;
    y: number;
  };
  size: {
    width: number | string;
    height: number | string;
  };
  drag: boolean;
  resize: boolean;
  id?: string;
  onDragStop: (params: {
    id?: string;
    position: { x: number; y: number };
    size: { width: number; height: number };
  }) => void;
  onFocus: () => void;
  saveRatio?: boolean;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { theme } = useAntTheme();

  const rndChildrenRef = useRef<HTMLDivElement | null>(null);

  //To access rnd div directly
  // let rndRef = useRef<Rnd | null>(null);

  const { deleteImage, addUpdate } = useVolatileBoundStore((store) => ({
    deleteImage: store.deleteImage,
    addUpdate: store.addUpdate,
  }));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        rndChildrenRef.current &&
        !rndChildrenRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false); // clean the border if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Rnd
      // ref={(c) => (rndRef.current = c)}
      bounds="parent"
      position={{ x: position.x, y: position.y }}
      size={{
        width: size.width,
        height: size.height,
      }}
      lockAspectRatio={saveRatio}
      onDragStop={(_e, draggableData) => {
        const position = { x: draggableData.x, y: draggableData.y };

        if (id !== undefined) {
          onDragStop({
            id,
            position,
            size: getRectangleRelativeSize(draggableData.node),
          });
        } else {
          onDragStop({
            position,
            size: getRectangleRelativeSize(draggableData.node),
          });
        }

        addUpdate();
      }}
      disableDragging={!drag}
      enableResizing={resize}
      resizeHandleComponent={
        resize && isFocused
          ? {
              top: <ResizeHandler position="top" />,
              right: <ResizeHandler position="right" />,
              left: <ResizeHandler position="left" />,
              bottom: <ResizeHandler position="bottom" />,
            }
          : {}
      }
      onMouseDown={(e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const isDeleteButton = target.closest("button");

        onFocus();

        if (isDeleteButton && id) {
          deleteImage(id);
        }

        setIsFocused(true);
      }}
      onResizeStart={() => {
        setIsFocused(true);
      }}
      onResizeStop={(_e, _direction, ref, _delta, position) => {
        const relativeWidth = parseFloat(
          (ref.getBoundingClientRect().width / DEFAULT_WIDTH).toFixed(2)
        );

        const relativeHeight = parseFloat(
          (ref.getBoundingClientRect().height / DEFAULT_HEIGHT).toFixed(2)
        );
        const size = {
          width: relativeWidth,
          height: relativeHeight,
        };

        if (id !== undefined) {
          onDragStop({ id, size, position });
        } else {
          onDragStop({ size, position });
        }

        addUpdate();
      }}
      // lockAspectRatio={true}
      style={{
        border: isFocused ? `1px solid ${theme.colorPrimary}` : "unset",
        zIndex: isFocused ? 10 : 1,
        position: "absolute",
        boxSizing: "border-box",
      }}
    >
      <div ref={rndChildrenRef}>{children}</div>

      {id && isFocused && (
        <Button
          type="text"
          size="small"
          icon={<DeleteOutlined className="delete-button" />}
          style={{
            position: "absolute",
            right: -20,
            top: -20,
            color: theme.colorPrimary,
          }}
        />
      )}
    </Rnd>
  );
};

const ResizeHandler = ({ position }: { position: string }) => {
  const { theme } = useAntTheme();

  const handlerStyles = {
    top: {
      wrapper: {
        height: "40%",
        width: "10%",
        top: "calc(50% - 2px)",
        left: "50%",
        borderRadius: "3px 3px 0 0",
      },
      inner: { height: "30%", width: "70%" },
    },
    bottom: {
      wrapper: {
        height: "40%",
        width: "10%",
        top: "calc(50% + 2px)",
        left: "50%",
        borderRadius: "0 0 3px 3px",
      },
      inner: { height: "30%", width: "70%" },
    },
    left: {
      wrapper: {
        height: "10%",
        width: "40%",
        top: "50%",
        left: "calc(50% - 2px)",
        borderRadius: "3px 0 0 3px",
      },
      inner: { height: "70%", width: "30%" },
    },
    right: {
      wrapper: {
        height: "10%",
        width: "40%",
        top: "50%",
        left: "calc(50% + 2px)",
        borderRadius: "0 3px 3px 0",
      },
      inner: { height: "70%", width: "30%" },
    },
  };

  const { wrapper: wrapperStyles, inner: innerStyles } =
    handlerStyles[position];

  return (
    <div
      style={{
        ...wrapperStyles,
        backgroundColor: theme.colorPrimary,
        position: "absolute",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          ...innerStyles,
          backgroundColor: "white",
        }}
      />
    </div>
  );
};
