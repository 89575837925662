import { useTranslate } from "@refinedev/core";
import { Result, Button, Typography, Flex, Card, Row, Col } from "antd";
import {
  Translate,
  HourglassHigh,
  MaskHappy,
  UsersThree,
  Swatches,
  Files,
  Confetti,
} from "@phosphor-icons/react";
import {
  useCustomMutation,
  useGo,
  useInvalidate,
  useOne,
} from "@refinedev/core";
import { BrandKit, ContactResponse, MediaProjectResponse } from "../../types";
import { SettingsItem } from "./SettingsItem";
import { secToHHMMSS } from "pages/media/utils";
import { BrandKitPreview } from "pages/media/components/BrandKitPreview";
import useOnboardingAnswers from "pages/onboarding/hooks/useOnboardingAnswers";
import type { Step } from "./ProjectCreateWizard";
import { useAntTheme } from "hooks/useAntTheme";
import { useVolatileBoundStore } from "store";

const { Text } = Typography;

export const SettingsApprovalScreen = ({
  projectId,
  setStep,
  previousStep,
}: {
  projectId: string;
  setStep: (step: Step) => void;
  previousStep: () => void;
}) => {
  const t = useTranslate();
  const { mutateAsync: approve, isLoading: isApproving } = useCustomMutation(
    {}
  );
  const go = useGo();
  const invalidate = useInvalidate();
  const { theme } = useAntTheme();

  const { shouldGoToOnboarding, isLoading: isOnboardingDataLoading } = useOnboardingAnswers();
  const { setCurrentProjectId } = useVolatileBoundStore((state) => ({
    setCurrentProjectId: state.setCurrentProjectId,
  }));

  const goToOnboardingOrProject = () => {
    if (shouldGoToOnboarding) {
      setCurrentProjectId(projectId);
      go({
        to: "/onboarding",
      });
    } else {
      return go({
        to: `/media/projects/${projectId}/storyboard/loading`,
      });
    }
  };

  const { data: projectData, isLoading: isProjectLoading } =
    useOne<MediaProjectResponse>({
      resource: "media/projects",
      id: projectId,
    });

  const { data: contactData } = useOne<ContactResponse>({
    resource: `media/${projectData?.data.organization_id}/contacts`,
    id: projectData?.data?.settings.contact_id,
    queryOptions: {
      enabled: !!projectData?.data?.settings.contact_id,
    },
  });

  const { data: brandKitData } = useOne<BrandKit>({
    resource: `media/${projectData?.data.organization_id}/brand_kits`,
    id: projectData?.data?.settings.brand_kit_id,
    queryOptions: {
      enabled: !!projectData?.data?.settings.brand_kit_id,
    },
  });

  const handleConfirm = async () => {
    const success = await approve({
      url: `media/projects/${projectId}/settings/approve`,
      method: "post",
      values: {},
    });

    if (success) {
      await invalidate({
        resource: "media_projects",
        invalidates: ["detail"],
        id: projectId,
      });
      goToOnboardingOrProject();
    }
  };

  if (isProjectLoading || !projectData?.data) return null;

  const { settings } = projectData.data;
  return (
    <Flex vertical align="center">
      <Result
        status="success"
        icon={<Confetti size={80} />}
        title={t("projects.components.SettingsApproval.yourCustomRequirements")}
        subTitle={
          <Flex vertical gap={30}>
            <Text>
              {t("projects.components.SettingsApproval.onceTheSettings")}
            </Text>
            <Card
              style={{
                borderColor: theme.colorPrimary,
              }}
              // styles={{ body: { background: "transparent" } }}
            >
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <SettingsItem
                    title={t("projects.components.SettingsApproval.language")}
                    icon={<Translate size={24} />}
                    content={settings.language}
                  />
                </Col>
                <Col span={24}>
                  <SettingsItem
                    title={t("projects.components.SettingsApproval.duration")}
                    icon={<HourglassHigh size={24} />}
                    content={
                      settings.duration > 0
                        ? secToHHMMSS(settings.duration)
                        : ""
                    }
                  />
                </Col>
                <Col span={24}>
                  <SettingsItem
                    title={t("projects.components.SettingsApproval.tone")}
                    icon={<MaskHappy size={24} />}
                    content={settings.tone_id}
                  />
                </Col>
                <Col span={24}>
                  <SettingsItem
                    title={t("projects.components.SettingsApproval.contacts")}
                    icon={<UsersThree size={24} />}
                    content={`${contactData?.data.firstname} ${contactData?.data?.lastname}`}
                  />
                </Col>
                <Col span={24}>
                  <SettingsItem
                    title={t("projects.components.SettingsApproval.pages")}
                    icon={<Files size={24} />}
                    content={
                      settings.selected_pages ??
                      t("projects.components.ProjectSettings.keyPagesAll")
                    }
                  />
                </Col>
                <Col span={24}>
                  <SettingsItem
                    title={t("projects.components.SettingsApproval.brandKit")}
                    icon={<Swatches size={24} />}
                    content={
                      brandKitData?.data ? (
                        <BrandKitPreview brandkit={brandKitData?.data} />
                      ) : null
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Flex>
        }
        extra={[
          // not necessary for now
          // <Button
          //   size="large"
          //   key="back"
          //   shape="round"
          //   onClick={() =>
          //     go({
          //       to: {
          //         resource: "media_projects",
          //         action: "show",
          //         id: projectId,
          //       },
          //     })
          //   }
          // >
          //   {t("projects.components.SettingsApproval.iWillApprove")}
          // </Button>,
          <Button
            size="large"
            key="back"
            shape="round"
            onClick={() => previousStep()}
          >
            {t("buttons.back")}
          </Button>,
          <Button
            size="large"
            key="approve"
            type="primary"
            shape="round"
            loading={isApproving || isOnboardingDataLoading}
            disabled={isApproving || isOnboardingDataLoading}
            onClick={handleConfirm}
          >
            {t("projects.components.SettingsApproval.approveSettings")}
          </Button>,
        ]}
      />
    </Flex>
  );
};
