import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Typography } from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import { useNavigation, useTranslate } from "@refinedev/core";
import { ReactNode } from "react";

export const ProjectSubPageHeader = ({
  projectId,
  projectTitle,
  page,
  rightContent,
}: {
  projectId: string;
  projectTitle: string;
  page: string;
  // | "kb"
  // | "BrandKit"
  // | "video"
  // | "contact"
  // | "chat"
  // | "CallToActions"
  // | "animatedDocument"
  // | "share"
  // | "quiz"
  // | "podcast"
  // | "analytics"
  // | "download";
  rightContent?: ReactNode;
}) => {
  const { theme } = useAntTheme();
  const t = useTranslate();
  const { show, list } = useNavigation();
  const pages = {
    kb: t("media.projects.projectMenu.editKnowledgeBase"),
    BrandKit: t("media.projects.projectMenu.changeBrandKit"),
    video: t("media.projects.projectMenu.editVideo"),
    Contacts: t("media.projects.projectMenu.editContact"),
    chat: t("media.projects.projectMenu.editChat"),
    CallToActions: t("media.projects.projectMenu.editCtas"),
    animation: t("media.projects.projectMenu.animatedDocument"),
    share: t("media.projects.projectMenu.shareExperience"),
    quiz: t("media.projects.projectMenu.quiz"),
    podcast: t("media.projects.projectMenu.podcast"),
    analytics: t("media.projects.projectMenu.analytics"),
    download: t("media.projects.projectMenu.download"),
    chatbotEdit: t("media.projects.projectMenu.editChatBot"),
  };
  return (
    <Flex
      justify="space-between"
      align="center"
      style={{
        borderBottom: `1px solid ${theme.colorBorder}`,
        margin: -theme.paddingLG,
        padding: "8px 16px",
        marginBottom: theme.paddingLG,
      }}
    >
      <Button
        type="text"
        size="large"
        icon={<ArrowLeftOutlined />}
        onClick={() => {
          show("media_projects", projectId);
        }}
      >
        {t("components.binder.index.backToProject")}
      </Button>
      <Space>
        <Typography.Text
          type="secondary"
          style={{ cursor: "pointer" }}
          onClick={() => list("media_projects")}
        >
          {t("media.projects.subPageHeader.projects")} /
        </Typography.Text>
        <Typography.Text
          style={{ color: theme.colorText, cursor: "pointer" }}
          onClick={() => show("media_projects", projectId)}
        >
          {projectTitle}
        </Typography.Text>
        <Typography.Text type="secondary" style={{ cursor: "pointer" }}>
          / {pages[page]}
        </Typography.Text>
      </Space>
      <div>{rightContent}</div>
    </Flex>
  );
};
