import { useCallback, useContext, useEffect, useReducer } from "react";
import {
  Refine,
  Pagination,
  OpenNotificationParams,
  CanAccess,
  useIsAuthenticated,
  useCustom,
  useGetIdentity,
  useSetLocale,
  CrudFilter,
} from "@refinedev/core";
import { Typography } from "antd";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  AddressBook,
  Swatches,
  FileVideo,
  Target,
  UserCircle,
  UserSound,
  List,
} from "@phosphor-icons/react";
import { useNotificationProvider } from "@refinedev/antd";
import dataProvider, { generateFilter } from "@refinedev/simple-rest";
// import routerProvider from "@refinedev/react-router-v6/legacy";
import routerProvider from "@refinedev/react-router-v6";
import { RefineKbarProvider } from "@refinedev/kbar";
import { ColorModeContextProvider } from "contexts";
import { Title, CustomSider } from "components/layout";
import { OffLayoutArea } from "components/offLayoutArea";
import { SELECTED_ORG_ID_KEY, User, authProvider } from "./authProvider";
import { CategoryList } from "pages/funds/categories/list";
import CategoryCreate from "pages/funds/categories/create";
import CategoryDetail from "pages/funds/categories/detail";
import { SubCategoryList } from "pages/funds/subCategories/list";
import SubCategoryCreate from "pages/funds/subCategories/create";
import SubCategoryDetail from "pages/funds/subCategories/detail";
import { FundList } from "pages/funds/funds/list";
import { FundDetail } from "pages/funds/funds/detail";
import { FundCreate } from "pages/funds/funds/create";
import queryString from "query-string";
import axiosInstance, { API_URL } from "./services/api";
// import { DashboardPage } from "pages/dashboard";
import { Retool } from "pages/retool";
import "./index.css";
import { newEnforcer, StringAdapter } from "casbin";
import { model } from "./helpers/accessControl";
import { isStudio, selectUserCan } from "./helpers/utils";
import {
  AppContext,
  AppState,
  DEFAULT_ORGANIZATION,
  initialState,
  reducer,
} from "./appContext";
import { MediaTemplateList } from "pages/media/templates/list";
import { VaultList } from "pages/vaults/list";
import { Helmet } from "react-helmet";
import {
  CalendarOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  FileImageOutlined,
  FileTextOutlined,
  FolderOutlined,
  FontColorsOutlined,
  LineChartOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  ShoppingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { MediaAssetsList } from "pages/media/assets/list";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  // Route as BaseRoute,
  Route,
  Routes,
} from "react-router-dom";
import { ThemedLayoutV2 } from "@refinedev/antd";
import { MediaDocumentList } from "pages/media/documents/list";
import { MediaDocumentCreate } from "pages/media/documents/create";
import { MediaDocumentDetail } from "pages/media/documents/detail";
import { MediaList } from "pages/media/media/list";
import { MediaCreate } from "pages/media/media/create";
import { MediaDetail } from "pages/media/media/detail";
import { MediaAssetDetail } from "pages/media/assets/detail";
import { MediaDashboardPage } from "pages/media/dashboard";
import { MediaAssetsReview } from "pages/media/assets/review";
import { MediaAssetsReviewStats } from "pages/media/assets/stats";
import { BoursoramaVideoList } from "pages/extranet/boursorama";
import { MediaBatchList } from "pages/media/batches/list";
import { UserEdit } from "pages/users/admin";
import { AccessScope, Organization } from "types";
import { ChangePassword } from "pages/auth/change-password";
import { AssetOverview } from "pages/assets/detail";
import { AssetChangesReview } from "pages/assets/changes";
import { AssetsEarningCalendarsPage } from "pages/assets/calendars";
import {
  MediaCuepointsAnimation,
  MediaCuepointsEditor,
} from "pages/media/projects/cuepoints";
import { ProjectsList } from "pages/media/projects";
import { ProjectCreate } from "pages/media/projects/create";
import { ProjectEdit } from "pages/media/projects/edit";
import { ProjectDetail } from "pages/media/projects/detail";
import { ProjectDetail as ProjectDetailAll } from "pages/media/projects/detail-all";
import { Chat } from "pages/media/components/chat/Chat";
import { AskDocs } from "pages/media/projects/AskDocs";
import { useAntTheme } from "hooks/useAntTheme";
import { ThemeProvider } from "styled-components";
import { VoiceProfileCreatePage } from "pages/media/voices/create";
import { VoiceProfilesListPage } from "pages/media/voices";
import VoiceProfileDetailPage from "pages/media/voices/detail";
import { CallToActionsFormPage } from "pages/media/ctas/create";
import CallToActionsListPage from "pages/media/ctas";
import { FaceProfileCreatePage } from "pages/media/faces/create";
import FaceProfileDetailPage from "pages/media/faces/detail";
import FaceProfilesListPage from "pages/media/faces";
import { ContactCreatePage } from "pages/media/contacts/create";
import { ContactListPage } from "pages/media/contacts";
import { ContactEditPage } from "pages/media/contacts/edit";
import { BrandKitList } from "pages/media/brandkit";
import { BrandKitEdit } from "pages/media/brandkit/edit";
import { BrandKitCreate } from "pages/media/brandkit/create";
import { BrandKitDetail } from "pages/media/brandkit/detail";
import SystemPage from "pages/system/detail";
import PronunciationsPage from "pages/media/pronunciations";
import PresenterProfilesListPage from "pages/media/presenters";
import { OrganizationPreferencesPage } from "pages/media/organizations";
import { RegisterPage } from "pages/auth/new-register";
import { EmailVerificationPage } from "pages/auth/verification";
import OnboardingPage from "pages/onboarding";
import { ProjectStoryboardEditor } from "pages/media/projects/storyboard";
import { UserProfilePage } from "pages/users/edit";
import { $Dictionary } from "i18next/typescript/helpers";
import useNoOrgRoute from "hooks/useNoOrgRoute";
import { t } from "i18next";
import CreateOrganizationPage from "pages/onboarding/CreateOrganization";
import { useTranslation } from "react-i18next";
import ForgotPasswordPage from "pages/auth/forgot-password";
import { normalizeLocale } from "pages/media/utils";
import { MediaTemplatesEditor } from "pages/media/projects/templates";
import ProductsList from "pages/media/products";
import OrganizationCreatePage from "pages/media/organizations/create";
import MediaOrganizationsList from "pages/media/organizations/list";
import ProductCreatePage from "pages/media/products/create";
import useSessionData from "hooks/useSessionData";
import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import { ProjectStepLoadingPage } from "pages/media/projects/loading";
import { NotificationPlacement } from "antd/es/notification/interface";
import { LoginPage } from "pages/auth/login";
import { MediaIndexPage } from "pages/media";
import ErrorBoundary from "components/ErrorBoundary";
import { ProjectSettingsPage } from "pages/media/projects/settings";
import { ProjectKbPage } from "pages/media/projects/kb";
import { EditChat } from "pages/media/projects/editChat";
import { ProjectChatHistoryPage } from "pages/media/projects/chat-history";
import { ProjectFeaturePage } from "pages/media/projects/feature";
import { DownloadMediaPage } from "pages/media/projects/components/DownloadMediaPage";
import AssetLibraries from "pages/asset-libraries";
import { Circle } from "@phosphor-icons/react/dist/ssr";
import CreateAssetLibraryPage from "pages/asset-libraries/create";
import AssetLibraryDetail from "pages/asset-libraries/edit";
import { ProjectMediaSharePage } from "pages/media/projects/share";
import { ProjectAnalyticsPage } from "pages/media/projects/analytics";
import {
  initializeAnalytics,
  PageViewTracker,
  useAnalytics,
} from "hooks/useAnalytics";
import PageTitle from "components/PageTitle";
import { useBoundStore } from "store";

const { stringify } = queryString;

// to refactor with metadata (v6) in the future
// https://reactrouter.com/6.29.0/route/route
// const Route = ({ title, ...rest }: RouteProps & { title?: string }) => (
//   <React.Fragment>
//     {/* <PageTitle title={title} />  */}
//     <BaseRoute {...rest} />
//   </React.Fragment>
// );

const Element = () => {
  const { theme } = useAntTheme();
  const shouldGoToCreateOrg = useNoOrgRoute();
  const { userData, user, isLoadingIdentity, isLoadingUserData } =
    useSessionData();

  useEffect(() => {
    if (isLoadingIdentity || (user && isLoadingUserData)) {
      return; //avoid init intercom if too early
    }
    // console.debug("intercom");
    let userProps = {};
    if (user && userData?.data) {
      const { id, first_name, last_name, email, created } = userData.data;
      userProps = {
        user_id: String(id),
        name: first_name + " " + last_name,
        email: email,
        created_at: created ? new Date(created).getTime() / 1000 : 0,
      };
    }
    Intercom({
      app_id: "mcwfcpeh",
      ...userProps,
    });
    return () => {
      shutdown();
    };
  }, [userData?.data, user, isLoadingIdentity]);

  return (
    <ThemedLayoutV2 Title={Title} Sider={CustomSider}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          {shouldGoToCreateOrg ? (
            <Navigate to="/organization/create" />
          ) : (
            <Outlet />
          )}
        </ErrorBoundary>
      </ThemeProvider>
    </ThemedLayoutV2>
  );
};
const SessionKeeper = () => {
  const { data: authData } = useIsAuthenticated();
  const { identifyUser } = useAnalytics();
  const changeLocale = useSetLocale();
  const { data: user } = useGetIdentity<User>();
  const { data, isFetching: isFetchingUser }: any = useCustom({
    url: `api/users/me`,
    method: "get",
    queryOptions: {
      enabled: authData?.authenticated === true,
      retry: false,
    },
  });
  const { data: organizationsData }: any = useCustom({
    url: `api/users/me/organizations`,
    method: "get",
    queryOptions: {
      enabled: authData?.authenticated === true,
      retry: false,
    },
  });

  const { dispatch, setOrganization } = useContext(AppContext);

  useEffect(() => {
    if (authData?.authenticated && user?.token) {
      if (!user.token.access_token) return;
      const accessToken = user.token.access_token;
      // force axios Authorization header
      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
  }, [authData?.authenticated, user]);

  const organizations = organizationsData?.data;
  const userData = data?.data;
  useEffect(() => {
    if (userData && !isFetchingUser) {
      // restore user language
      changeLocale(normalizeLocale(userData.language));
      const { accesses } = userData.accesses;
      identifyUser(userData.id.toString(), {
        country: userData.country,
        language: userData.language,
        created: userData.created,
      });
      dispatch({ type: "accesses", payload: accesses });
      dispatch({ type: "setUser", payload: userData });
    }
  }, [dispatch, userData, changeLocale, isFetchingUser]);
  useEffect(() => {
    if (organizations && !isFetchingUser) {
      dispatch({ type: "setOrganizations", payload: organizations });
      const localStorageOrgId = localStorage.getItem(SELECTED_ORG_ID_KEY);
      // If user has selected an organization before and then logged in again
      // restore it
      if (localStorageOrgId) {
        const org =
          organizations?.find(
            (organization: Organization) =>
              organization.id === localStorageOrgId
          ) ?? DEFAULT_ORGANIZATION;

        setOrganization(org);
      } else {
        // No local storage
        const org = organizations?.find(
          (organization: Organization) =>
            organization.id === userData?.organizations?.[0]
        );
        // No orgs on the user, probably an admin, set the default placeholder organization
        setOrganization(org ?? DEFAULT_ORGANIZATION);
      }
    }
  }, [dispatch, organizations, userData, isFetchingUser, setOrganization]);

  return null;
};
function App() {
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const setAppState = useBoundStore((state) => state.setAppState);
  const setOrganization = useCallback(
    (value: string | Organization) => {
      // A string might come from the organization switch
      let selectedOrg: string | Organization;
      if (typeof value === "string") {
        selectedOrg = state.organizations.find((org: any) => org.id === value);
        localStorage.setItem(SELECTED_ORG_ID_KEY, value);
      } else {
        selectedOrg = value;
        localStorage.setItem(SELECTED_ORG_ID_KEY, value.id);
      }
      dispatch({
        type: "setOrganization",
        payload: selectedOrg ?? DEFAULT_ORGANIZATION,
      });
      setAppState({ workspace: null });
    },
    [state.organizations]
  );
  const i18nProvider = {
    translate: (key: string, params) => {
      if (!params) {
        params = {};
      }
      if (process.env.NODE_ENV === "development") {
        return t(key, params as $Dictionary);
      }
      // default translation value in case no custom defaultValue
      // to not display the key as fallback
      if (typeof params === "object" && params.defaultValue === undefined) {
        params.defaultValue = "";
      }
      // not necessary currently
      // if (params.defaultValue === null) {
      //   return 'null'
      // }
      return t(key, params as $Dictionary);
    },
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => normalizeLocale(i18n.language),
  };

  const simpleRestProvider = dataProvider(API_URL, axiosInstance);

  const myDataProvider = {
    ...simpleRestProvider,
    getList: async ({
      resource,
      pagination,
      meta,
      filters = [],
    }: {
      resource: string;
      pagination?: Pagination | undefined;
      meta?: any;
      filters?: CrudFilter[];
    }) => {
      const url = `${API_URL}/${resource}`;
      // console.debug({ url, pagination });
      const { current = 1, pageSize = 10 } = pagination ?? {};
      const hasServerPagination = pagination?.mode === "server";

      type Query =
        | {
            _start: number;
            _end: number;
            size: number;
            page: number;
          }
        | {
            continue_index: string;
          };

      let query: Query = {
        _start: (current - 1) * pageSize,
        _end: current * pageSize,
        size: pageSize,
        page: current,
      };

      // for cursor based pagination
      // cf https://refine.dev/docs/data/hooks/use-infinite-list/#how-to-use-cursor-based-pagination
      if (meta.cursor && pagination?.current) {
        query = {
          continue_index: String(pagination?.current),
        };
      }

      const queryFilters = generateFilter(filters);

      const requestUrl = hasServerPagination
        ? url.includes("?")
          ? `${url}&${stringify({ ...query, ...queryFilters })}`
          : `${url}?${stringify({ ...query, ...queryFilters })}`
        : url;

      const { data, headers } = await axiosInstance.get(requestUrl);

      const total = +headers["x-total-count"];

      return {
        data,
        total,
        cursor: {
          next: data.continue_index,
        },
      };
    },
    update: async ({
      resource,
      id,
      variables,
      meta,
    }: {
      resource: string;
      variables: any;
      meta?: any;
      id: string | number;
    }) => {
      const method: "patch" | "put" = ["patch", "put"].includes(meta?.method)
        ? meta.method
        : "patch";

      const params = (meta?.urlParams as URLSearchParams)?.toString() ?? "";

      const url = `${API_URL}/${resource}/${id}?${params}`;
      // console.debug("update", { method, variables });

      const { data } = await axiosInstance[method](url, variables);

      return {
        data,
      };
    },
  };

  const { accesses, hasAccesses, organization, user }: AppState = state;
  // console.debug({ state });

  const hasRole = (subject: string, action: string, scope?: AccessScope) => {
    const actionsLookup: {
      [id: string]: string;
    } = {
      list: "read",
      show: "read",
      edit: "update",
      delete: "delete",
      create: "create",
      document_index: "document_index",
      clear: "clear",
      read: "read",
    };
    const targetAction = actionsLookup[action]; //?? action;

    const enabled = selectUserCan(accesses, {
      subject,
      action: targetAction,
      scope,
    });
    // console.debug(
    //   "hasRole",
    //   accesses,
    //   subject,
    //   action,
    //   scope,
    //   targetAction,
    //   enabled
    // );
    if (enabled) {
      return action;
    }
    return "none"; // return fake permission none to block access
  };
  const hasRoles = (
    subject: string,
    roles: [action: string, scope?: AccessScope][]
  ) => {
    const result = roles
      .map(([action, scope]) => hasRole(subject, action, scope))
      .join(")|(");
    return "(" + result + ")";
  };

  const roleFundScreen = "FundDefinition";
  const roleCategoryScreen = "Category";
  const appFlavor = isStudio ? "studio" : "default";

  useEffect(() => {
    dispatch({ type: "setAppFlavor", payload: appFlavor });
    initializeAnalytics();
  }, []);

  const notificationProvider = useNotificationProvider();

  type FriendlyErrorMessages = Record<
    number,
    {
      message: string;
    }
  >;

  const getFriendlyErrorMessage = (error: any): OpenNotificationParams => {
    const ErrorMessages: FriendlyErrorMessages = {
      404: {
        message: t("src.App.resourceNotFound"), // todo i18n
      },
    };
    const parsedStatusCode = error?.message.match(/\d{3}/)?.[0];
    const friendly = ErrorMessages[parsedStatusCode];

    let description = error?.description;
    if (typeof description !== "string" && description?.message) {
      description = description.message;
    } else {
      // Very bad if we got to here.
      description = JSON.stringify(description);
    }
    const formattedOriginal = (
      <Typography.Text type="secondary">
        {description} • {error.message}
      </Typography.Text>
    );
    const result = {
      ...error,
      message: (
        <>
          {friendly && (
            <>
              {friendly?.message}
              <br />
            </>
          )}
          {formattedOriginal}
        </>
      ),
      description: t("src.App.somethingWentWrong"),
    };
    console.debug("error caught", error);
    return result;
  };
  const open = notificationProvider.open;
  notificationProvider.open = (
    args: OpenNotificationParams & {
      skipFriendly?: boolean;
    }
  ) => {
    if (args.message) {
      args.message = args?.message?.replace(/&#x2F;/g, "/");
    }
    // force position of all notifications to not be on
    // top right in conflict with UI buttons
    // bottom right in conflict with Intercom widget
    // refine antd notification was patched to support this props
    // TODO refactor with a custom provider without patching refine
    const overrideOptions: { placement: NotificationPlacement } = {
      placement: "bottomLeft",
    };

    if (args.type === "error" && !args.skipFriendly) {
      try {
        open({ ...getFriendlyErrorMessage(args), ...overrideOptions });
      } catch (error) {
        console.error(error);
        open({
          type: "error",
          description: t("src.App.somethingWentWrong"),
          message: t("src.App.error"),
          ...overrideOptions,
        });
      }
    } else {
      open({ ...args, ...overrideOptions });
    }
  };

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ state, dispatch, setOrganization }}>
        <ColorModeContextProvider>
          {/* <RefineSnackbarProvider> */}
          <RefineKbarProvider>
            <Refine
              dataProvider={{
                default: myDataProvider,
              }}
              notificationProvider={notificationProvider}
              options={{
                warnWhenUnsavedChanges: true,
                reactQuery: {
                  clientConfig: {
                    defaultOptions: {
                      queries: {
                        retry: false,
                      },
                    },
                  },
                },
              }}
              // catchAll={<ErrorComponent />}
              accessControlProvider={
                // hack to fix accessControlProvider not being reactive when changing state
                hasAccesses
                  ? {
                      can: async ({ action, params, resource }) => {
                        const permissions = `
                  p, admin, dashboard, (list)
                  p, admin, users,  ${hasRoles("User", [
                    ["list", "ALL"],
                    ["edit", "ALL"],
                  ])}
                  p, admin, cache, ${hasRoles("Cache", [
                    ["clear", "ALL"],
                    ["read", "ALL"],
                  ])}
                  p, admin, assets,  ${hasRoles("Media.GenerationStep", [
                    ["list"],
                    ["show"],
                  ])}
                  p, admin, media, (list)
                  p, admin, media_dashboard, ${hasRoles(
                    "Media.GenerationStep",
                    [["list"], ["show"]]
                  )}
                  p, admin, media_media, ${hasRoles("Media.Media", [
                    ["list", "ORG"],
                    ["create", "ORG"],
                    ["show", "ORG"],
                  ])}
                  p, admin, media_templates, ${hasRoles("Media.Template", [
                    ["show", "ORG"],
                  ])}
                  p, admin, media_asset_libraries, ${hasRoles(
                    "Media.AssetLibrary",
                    [["list"], ["edit"], ["show"], ["create"], ["delete"]]
                  )}
                  p, admin, media_assets, ${hasRoles("Media.Asset", [
                    ["list", "ORG"],
                    ["edit", "ORG"],
                    ["show", "ORG"],
                  ])}
                  p, admin, media_assets_review, ${hasRoles("Media.Asset", [
                    ["list", "ORG"],
                    ["edit", "ORG"],
                  ])}
                  p, admin, media_documents, ${hasRoles("Media.Document", [
                    ["list", "ORG"],
                    ["edit", "ORG"],
                    ["create", "ORG"],
                    ["show", "ORG"],
                    ["delete", "ORG"],
                    ["document_index", "ORG"],
                  ])}
                  p, admin, media_batches, ${hasRoles("Media.Batch", [
                    ["list"],
                    ["show"],
                  ])}
                  p, admin, media_generation_steps, ${hasRoles(
                    "Media.GenerationStep",
                    [["list"], ["show"], ["update"]]
                  )}
                  p, admin, media_projects, ${hasRoles("Media.Project", [
                    ["list"],
                    ["show"],
                    ["create"],
                    ["edit"],
                    ["delete"],
                  ])}
                  p, admin, media_brandkits, ${hasRoles("Media.BrandKit", [
                    ["list"],
                    ["show"],
                    ["create"],
                    ["edit"],
                    ["delete"],
                  ])}
                  p, admin, media_voices, ${hasRoles("Media.VoiceProfile", [
                    ["list"],
                    ["show"],
                    ["create"],
                    ["edit"],
                    ["delete"],
                  ])}
                  p, admin, media_faces, ${hasRoles("Media.FaceProfile", [
                    ["list"],
                    ["show"],
                    ["create"],
                    ["edit"],
                    ["delete"],
                  ])}
                  p, admin, media_presenters, ${hasRoles(
                    "Media.PresenterProfile",
                    [["list"], ["show"], ["create"], ["edit"], ["delete"]]
                  )}
                  p, admin, media_ctas, ${hasRoles("Media.VoiceProfile", [
                    ["list"],
                    ["show"],
                    ["create"],
                    ["edit"],
                    ["delete"],
                  ])}
                  p, admin, media_contacts, ${hasRoles("Media.Contact", [
                    ["list"],
                    ["show"],
                    ["create"],
                    ["edit"],
                    ["delete"],
                  ])}
                  p, admin, media_products, ${hasRoles("Media.Product", [
                    ["show"],
                    ["create"],
                    ["edit"],
                    ["delete"],
                    ["list"],
                  ])}
                  p, admin, media_pronunciations, ${hasRoles(
                    "Media.Pronunciation",
                    [["show"], ["create"], ["edit"], ["delete"], ["list"]]
                  )}
                  p, admin, vaults, (${hasRole("Vault", "list")})
                  p, admin, retool, (list)
                  p, admin, strategies, (${hasRole(roleFundScreen, "list")})
                  p, admin, funds, (${hasRole(roleFundScreen, "list")})
                  p, admin, funds, (${hasRole(roleFundScreen, "show")})
                  p, admin, funds, (${hasRole(roleFundScreen, "edit")})
                  p, admin, funds, (${hasRole(roleFundScreen, "create")})
                  p, admin, api/funds, (${hasRole(roleFundScreen, "delete")})

                  p, admin, category, (${hasRole(roleCategoryScreen, "list")})
                  p, admin, category, (${hasRole(roleCategoryScreen, "show")})
                  p, admin, category, (${hasRole(roleCategoryScreen, "edit")})
                  p, admin, category, (${hasRole(roleCategoryScreen, "create")})
                  p, admin, api/funds/categories, (${hasRole(
                    roleCategoryScreen,
                    "delete"
                  )})

                  p, admin, subcategory, (${hasRole(
                    roleCategoryScreen,
                    "list"
                  )})
                  p, admin, subcategory, (${hasRole(
                    roleCategoryScreen,
                    "show"
                  )})
                  p, admin, subcategory, (${hasRole(
                    roleCategoryScreen,
                    "edit"
                  )})
                  p, admin, subcategory, (${hasRole(
                    roleCategoryScreen,
                    "create"
                  )})
                  p, admin, subcategory, (${hasRole(
                    roleCategoryScreen,
                    "delete"
                  )})
                  p, admin, organization, ${hasRoles("Organization", [
                    ["list", "ORG"],
                    ["show", "ORG"],
                    ["create", "ORG"],
                    ["edit", "ORG"],
                    ["delete", "ORG"],
                  ])}
                  p, admin, media_organization_preferences, ${hasRoles(
                    "Media.OrganizationPreference",
                    [
                      ["list", "ORG"],
                      ["show", "ORG"],
                      ["create", "ORG"],
                      ["edit", "ORG"],
                      ["delete", "ORG"],
                    ]
                  )}
                  p, admin, onboarding_answer, (${hasRole(
                    "Media.OnboardingAnswer",
                    "create"
                  )})
                  )
                  `;
                        const adapter = new StringAdapter(permissions);

                        const enforcer = await newEnforcer(model, adapter);

                        return {
                          can: await enforcer.enforce(
                            "admin",
                            resource,
                            action
                          ),
                        };
                      },
                    }
                  : undefined
              }
              resources={[
                // {
                //   name: "strategies",
                //   meta: {
                //     label: "Funds",
                //     icon: <BankOutlined style={{ height: 14 }} />,
                //     hasRole: false, //hasRole(roleFundScreen, "list"),
                //   },
                // },
                // {
                //   name: "funds",
                //   list: "/funds",
                //   create: "/funds/create",
                //   show: "funds/:id",
                //   edit: "/funds/:id/edit",
                //   meta: {
                //     parent: "strategies",
                //     label: "Funds List",
                //     icon: <AccountBalance style={{ height: 14 }} />,
                //     hasRole: hasRole(roleFundScreen, "list"),
                //     canDelete: true,
                //   },
                // },
                // {
                //   name: "category",
                //   parentName: "strategies",
                //   list: "/categories",
                //   edit: "/categories/:id/edit",
                //   show: "/categories/:id",
                //   create: "/categories/create",
                //   meta: {
                //     label: "Categories",
                //     icon: <Category style={{ height: 14 }} />,
                //     canDelete: true,
                //     hasRole: hasRole(roleCategoryScreen, "list"),
                //   },
                // },
                // {
                //   name: "subcategory",
                //   parentName: "strategies",
                //   list: "/subcategories",
                //   edit: "/subcategories/:id/edit",
                //   show: "/subcategories/:id",
                //   create: "/subcategories/create",
                //   meta: {
                //     canDelete: true,
                //     icon: <Category style={{ height: 14 }} />,
                //     label: "Sub Categories",
                //     hasRole: hasRole(roleCategoryScreen, "list"),
                //   },
                // },
                {
                  name: "media",
                  list: "/media",
                  meta: {
                    label: t("src.App.workSpace"),
                    icon: <List />, // need an icon for collapsed sider menu
                    hasRole: true, // // hasRole: hasRole(roleFundScreen, "list"),
                  },
                },
                {
                  name: "media_asset_libraries",
                  list: "/media/libraries",
                  create: "/media/libraries/create",
                  show: "/media/libraries/:id",
                  edit: "/media/libraries/:id/edit",
                  meta: {
                    parent: "media",
                    label: t("src.App.assetLibraries"),
                    icon: <Circle />,
                    hasRole: true, // // hasRole: hasRole(roleFundScreen, "list"),
                    hide: true, // no need for now in the menu
                  },
                },
                {
                  name: "media_dashboard",
                  list: "/media/dashboard",
                  meta: {
                    parent: "media",
                    icon: <DashboardOutlined />,
                    label: t("src.App.dashboard"),
                    hasRole: true, //hasRole(roleFundScreen, "list"),
                    hide: isStudio,
                  },
                },
                {
                  name: "assets",
                  list: "/assets",
                  show: "/assets/:id",
                  meta: {
                    parent: "media",
                    icon: <LineChartOutlined />,
                    label: t("src.App.stocks"),
                    hasRole: true, //hasRole(roleFundScreen, "list"),
                    hide: isStudio,
                  },
                },
                {
                  name: "media_projects",
                  // show: isDocustream()
                  //   ? "/media/projects/:id"
                  //   : "/media/projects/:id/all",
                  show: "/media/projects/:id",
                  list: "/media/projects",
                  create: "/media/projects/create",
                  edit: "/media/projects/:id/edit",
                  meta: {
                    parent: "media",
                    label: t("src.App.projects"),
                    icon: <FolderOutlined />,
                  },
                },
                {
                  name: "media_brandkits",
                  list: "media/brandkits",
                  show: "media/brandkits/:id",
                  create: "media/brandkits/create",
                  edit: "media/brandkits/:id/edit",
                  meta: {
                    parent: "media",
                    icon: <Swatches size={18} weight="bold" />,
                    label: t("src.App.brandKits"),
                  },
                },

                {
                  name: "media_contacts",
                  show: "/media/contacts/:id",
                  list: "/media/contacts",
                  create: "/media/contacts/create",
                  edit: "/media/contacts/:id/edit",
                  meta: {
                    parent: "media",
                    label: t("src.App.contacts"),
                    icon: <AddressBook size={18} weight="bold" />,
                  },
                },
                {
                  name: "media_voices",
                  show: "/media/voices/:id",
                  list: "/media/voices",
                  create: "/media/voices/create",
                  edit: "/media/voices/:id/edit",
                  meta: {
                    parent: "media",
                    label: t("src.App.voices"),
                    icon: <UserSound size={18} weight="bold" />,
                    hide: isStudio,
                  },
                },
                {
                  name: "media_faces",
                  show: "/media/faces/:id",
                  list: "/media/faces",
                  create: "/media/faces/create",
                  edit: "/media/faces/:id/edit",
                  meta: {
                    parent: "media",
                    label: t("src.App.faces"),
                    icon: <UserCircle size={18} weight="bold" />,
                    hide: isStudio,
                  },
                },
                {
                  name: "media_presenters",
                  list: "/media/presenters",
                  meta: {
                    parent: "media",
                    label: t("src.App.presenters"),
                    icon: <UserOutlined size={18} />,
                    hide: isStudio,
                  },
                },
                {
                  name: "media_ctas",
                  show: "/media/ctas/:id",
                  list: "/media/ctas",
                  create: "/media/ctas/create",
                  edit: "/media/ctas/:id",
                  meta: {
                    parent: "media",
                    label: t("src.App.callToActions"),
                    icon: <Target size={18} weight="bold" />,
                  },
                },
                {
                  name: "media_products",
                  show: "/media/products",
                  list: "/media/products",
                  create: "/media/products/create",
                  meta: {
                    icon: <ShoppingOutlined />,
                    parent: "media",
                    label: t("src.App.products"),
                    hasRole: true,
                  },
                },
                {
                  name: "media_documents",
                  list: "/media/documents",
                  create: "/media/documents/upload",
                  show: "/media/documents/:id",
                  meta: {
                    parent: "media",
                    icon: <FileTextOutlined style={{ height: 14 }} />,
                    label: t("src.App.documents"),
                    hasRole: true, //hasRole(roleFundScreen, "list"),
                    hide: true, //isStudio,
                  },
                },
                {
                  name: "media_media",
                  show: "/media/media/:id",
                  list: "/media/media",
                  meta: {
                    parent: "media",
                    icon: <PlayCircleOutlined style={{ height: 14 }} />,
                    label: t("src.App.media"),
                    canDelete: false,
                    // hasRole: hasRole("Media.Media", "debug") !== "none",
                    // for admins
                    hide: hasRole("Media.GenerationStep", "show") === "none", //isStudio,
                  },
                },
                {
                  name: "media_batches",
                  list: "/media/batches/",
                  show: "/media/batches/:id/:key",
                  meta: {
                    parent: "media",
                    icon: <CalendarOutlined />,
                    label: t("src.App.batches"),
                    hasRole: hasRole("Media.Batch", "list"),
                  },
                },
                {
                  name: "media_assets",
                  list: "/media/assets/",
                  show: "/media/assets/:id",
                  meta: {
                    parent: "media",
                    icon: <FileImageOutlined style={{ height: 14 }} />,
                    label: t("src.App.assets"),
                    hasRole: hasRole("Media.Asset", "list"),
                    // for admins
                    hide: hasRole("Media.GenerationStep", "show") === "none",
                  },
                },
                {
                  name: "media_assets_review",
                  show: "/media/assets/review",
                  list: "/media/assets/review",
                  meta: {
                    parent: "media",
                    icon: <FileDoneOutlined style={{ height: 14 }} />,
                    label: t("src.App.assetsReview"),
                    hasRole: hasRole("Media.Asset", "edit"),
                    // for admins TODO make more specific .review access
                    hide: hasRole("Media.GenerationStep", "show") === "none",
                  },
                },

                {
                  name: "media_templates",
                  list: "/media/templates",
                  meta: {
                    icon: <FileVideo style={{ height: 14 }} />,
                    parent: "media",
                    label: t("src.App.templates"),
                    hasRole: hasRole("Media.Template", "show"),
                    hide: isStudio,
                  },
                },
                {
                  name: "media_pronunciations",
                  show: "/media/pronunciations",
                  list: "/media/pronunciations",
                  meta: {
                    icon: <FontColorsOutlined />,
                    parent: "media",
                    label: t("src.App.pronunciations"),
                    hasRole: hasRole("Media.Pronunciation", "show"),
                    hide: isStudio,
                  },
                },

                {
                  name: "media_products",
                  create: "/media/products/create",
                  list: "/media/products",
                  meta: {
                    icon: <ShoppingOutlined />,
                    parent: "media",
                    label: t("src.App.products"),
                    hasRole: true,
                    hide: isStudio,
                  },
                },
                {
                  name: "users",
                  show: "/users",
                  list: "/users",
                  meta: {
                    icon: <UsergroupAddOutlined />,
                    label: t("src.App.users"),
                    hasRole: hasRole("User", "edit", "ALL"),
                    hide: isStudio,
                  },
                },
                {
                  name: "organization",
                  show: "/organizations/:id", // todo
                  list: "/organizations",
                  create: "/organizations/create",
                  meta: {
                    hide: true,
                  },
                },
                {
                  name: "system",
                  show: "/system",
                  list: "/system",
                  meta: {
                    icon: <SettingOutlined />,
                    label: t("src.App.system"),
                    hasRole: hasRole("System", "edit", "ALL"),
                    hide: isStudio,
                  },
                },
                {
                  name: "vaults",
                  list: "/vaults",
                  meta: {
                    label: t("src.App.sharedVaults"),
                    icon: <CodeSandboxOutlined style={{ height: 14 }} />,
                    route: "vaults",
                    hasRole: hasRole("Vault", "list"),
                    hide: isStudio,
                  },
                },

                // deprecated
                // {
                //   name: "retool",
                //   list: "/retool",
                //   meta: {
                //     label: "Retool",
                //   },
                // },
              ]}
              authProvider={authProvider}
              routerProvider={routerProvider}
              i18nProvider={i18nProvider}
              OffLayoutArea={OffLayoutArea} //todo
            >
              <Helmet>
                {process.env.REACT_APP_ENV === "production" && (
                  <script>
                    {
                      '(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:5164606,hjsv:6};a=o.getElementsByTagName("head")[0];r=o.createElement("script");r.async=1;r.src="https://static.hotjar.com/c/hotjar-"+h._hjSettings.hjid+".js?sv="+h._hjSettings.hjsv;a.appendChild(r)})(window,document);'
                    }
                  </script>
                )}
                {process.env.REACT_APP_ENV === "production" && (
                  <script>
                    {
                      '(function(c,o,v,i,e,w){c[v]=c[v]||function(){(c[v].a=c[v].a||[]).push(arguments)};var s=o.createElement(i);s.src=e;s.async=1;var h=o.getElementsByTagName(i)[0];h.parentNode.insertBefore(s,h);c.addEventListener("error",function(err){c[v]("error",err)});c.addEventListener("message",function(msg){c[v]("message",msg)})})(window,document,"coview","script","https://cdn.coview.com/coview.js");coview("start", {projectKey:"pXpfBy3Haeo"});'
                    }
                  </script>
                )}
              </Helmet>
              <SessionKeeper />
              <PageViewTracker />
              <Routes>
                <Route element={<Element />}>
                  <Route
                    index
                    element={
                      <>
                        <CanAccess
                          action="list"
                          resource="media_projects"
                          fallback={<Navigate to="/login" />}
                        >
                          <Navigate to="media/projects" />
                        </CanAccess>
                      </>
                      // <Authenticated key={"home"}>
                      //   {/* <MediaDashboardPage /> */}
                      // </Authenticated>
                    }
                  />
                  <Route
                    path="login"
                    element={
                      <>
                        <PageTitle titleKey="login" />
                        <LoginPage />
                      </>
                    }
                  />
                  <Route
                    path="verification"
                    element={
                      <>
                        <PageTitle titleKey="verification" />
                        <EmailVerificationPage />
                      </>
                    }
                  />

                  <Route
                    path="forgot-password"
                    element={
                      <>
                        <PageTitle titleKey="forgotpassword" />
                        <ForgotPasswordPage />
                      </>
                    }
                  />
                  <Route
                    path="register"
                    element={
                      <>
                        <PageTitle titleKey="register" />
                        <RegisterPage />
                      </>
                    }
                  />
                  {/* <Route element={<ProtectedRoute />}> */}
                  <Route path="/organization">
                    <Route index element={<OrganizationPreferencesPage />} />
                    <Route path="create" element={<CreateOrganizationPage />} />
                  </Route>
                  <Route path="/organizations">
                    <Route path="create" element={<OrganizationCreatePage />} />
                    <Route index element={<MediaOrganizationsList />} />
                  </Route>
                  <Route path="users" element={<UserEdit />} />
                  <Route path="settings" element={<UserProfilePage />} />
                  <Route path="system" element={<SystemPage />} />
                  {/* <Route
                      path="/update-password"
                      element={<UpdatePassword />}
                    /> */}
                  <Route path="/change-password" element={<ChangePassword />} />
                  <Route path="vaults" element={<VaultList />} />
                  <Route path="retool" element={<Retool />} />
                  <Route
                    path="extranet/boursorama"
                    element={<BoursoramaVideoList />}
                  />
                  <Route path="/funds">
                    <Route index element={<FundList />} />
                    <Route path="create" element={<FundCreate />} />
                    <Route path=":id" element={<FundDetail />} />
                    <Route path=":id/edit" element={<FundCreate />} />
                  </Route>
                  <Route path="/categories">
                    <Route index element={<CategoryList />} />
                    <Route path="create" element={<CategoryCreate />} />
                    <Route path=":id" element={<CategoryDetail />} />
                    <Route path=":id/edit" element={<CategoryCreate />} />
                  </Route>
                  <Route path="/subcategories">
                    <Route index element={<SubCategoryList />} />
                    <Route path="create" element={<SubCategoryCreate />} />
                    <Route path=":id" element={<SubCategoryDetail />} />
                    <Route path=":id/edit" element={<SubCategoryCreate />} />
                  </Route>
                  <Route path="/assets">
                    <Route index element={<AssetOverview />} />
                    <Route path="changes" element={<AssetChangesReview />} />
                    <Route
                      path="calendars"
                      element={<AssetsEarningCalendarsPage />}
                    />
                    <Route path=":id" element={<AssetOverview />} />
                  </Route>
                  <Route path="/media">
                    <Route index element={<MediaIndexPage />} />
                    <Route path="dashboard" element={<MediaDashboardPage />} />
                    <Route
                      index
                      element={
                        <>
                          <PageTitle titleKey="templates" />
                          <MediaTemplateList />
                        </>
                      }
                    />

                    <Route path="media">
                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="media" />
                            <MediaList />
                          </>
                        }
                      />
                      <Route path="create" element={<MediaCreate />} />
                      <Route path=":id" element={<MediaDetail />} />
                      <Route path=":id/edit" element={<MediaDetail />} />
                    </Route>
                    <Route path="batches">
                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="batches" />
                            <MediaBatchList organization={organization} />
                          </>
                        }
                      />
                      <Route path=":id/:key" element={<MediaBatchList />} />
                    </Route>
                    <Route path="documents">
                      <Route
                        index
                        element={
                          <MediaDocumentList organization={organization} />
                        }
                      />
                      <Route
                        path="upload"
                        element={
                          <MediaDocumentCreate organization={organization} />
                        }
                      />
                      <Route
                        path=":id"
                        element={
                          <MediaDocumentDetail organization={organization} />
                        }
                      />
                      {/* <Route
                          path=":id/storyboard"
                          element={
                            <DocumentStoryboardEditor
                              organization={organization}
                            />
                          }
                        /> */}
                      <Route
                        path=":id/binder"
                        element={<MediaCuepointsEditor />}
                      ></Route>
                      <Route
                        path=":id/chat"
                        element={
                          <Chat
                            documentIds={[
                              "8d3efc14-9e60-44af-aaa0-000a2244ef9d",
                            ]}
                            organizationId="boursorama"
                          />
                        }
                      ></Route>
                    </Route>
                    <Route
                      path="assets"
                      element={
                        <>
                          <PageTitle titleKey="assets" />
                          <MediaAssetsList userId={user?.id} />
                        </>
                      }
                    />
                    <Route
                      path="assets/review"
                      element={
                        <>
                          <PageTitle titleKey="assetsReview" />
                          <MediaAssetsReview />
                        </>
                      }
                    />
                    <Route
                      path="assets/review/stats"
                      element={<MediaAssetsReviewStats />}
                    />
                    <Route path="assets/:id" element={<MediaAssetDetail />} />
                    <Route
                      path="assets/review/:sub_category"
                      element={<MediaAssetsList userId={user?.id} />}
                    />
                    <Route path="projects">
                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="projects" />
                            <ProjectsList />
                          </>
                        }
                      />
                      <Route path="create" element={<ProjectCreate />} />
                      <Route path=":id/setup" element={<ProjectCreate />} />
                      <Route
                        path=":id/setup/:setupType"
                        element={<ProjectCreate />}
                      />
                      <Route path=":id/edit" element={<ProjectEdit />} />
                      <Route
                        path=":id/storyboard/loading"
                        element={
                          <>
                            <PageTitle titleKey="storyBoard" />
                            <ProjectStepLoadingPage />
                          </>
                        }
                      />
                      <Route
                        path=":id/settings/:setting"
                        element={<ProjectSettingsPage />}
                      />
                      <Route path=":id/kb" element={<ProjectKbPage />} />
                      <Route
                        path=":id/chat/history"
                        element={<ProjectChatHistoryPage />}
                      />
                      <Route
                        path=":id/analytics"
                        element={<ProjectAnalyticsPage />}
                      />

                      <Route
                        path=":id/storyboard/:storyboardId"
                        element={
                          <>
                            <PageTitle titleKey="storyboardEditor" />
                            <ProjectStoryboardEditor />
                          </>
                        }
                      />
                      <Route
                        path=":id/storyboard"
                        element={
                          <>
                            <PageTitle titleKey="storyboardEditor" />
                            <ProjectStoryboardEditor />
                          </>
                        }
                      />
                      <Route
                        path=":id/feature/:feature"
                        element={<ProjectFeaturePage />}
                      />
                      <Route
                        path=":id/chat"
                        element={<AskDocs organization={organization} />}
                      />
                      <Route path=":id/chat/edit" element={<EditChat />} />
                      <Route path=":id" element={<ProjectDetail />} />
                      <Route path=":id/all" element={<ProjectDetailAll />} />
                      <Route
                        path=":id/media/:mediaId/binder"
                        element={<MediaCuepointsEditor />}
                      />
                      <Route
                        path=":id/media/:mediaId/animation"
                        element={
                          <>
                            <PageTitle titleKey="highlightsEditor" />
                            <MediaCuepointsAnimation />
                          </>
                        }
                      />
                      <Route
                        path=":id/media/:mediaId/templates"
                        element={<MediaTemplatesEditor />}
                      />
                      <Route
                        path=":id/media/:mediaId/download"
                        element={<DownloadMediaPage />}
                      />
                      <Route
                        path=":id/media/:mediaId/share"
                        element={<ProjectMediaSharePage />}
                      />
                    </Route>

                    <Route path="voices">
                      <Route
                        path="create"
                        element={
                          <>
                            <PageTitle titleKey="voicesCreate" />
                            <VoiceProfileCreatePage />
                          </>
                        }
                      />
                      <Route path=":id" element={<VoiceProfileDetailPage />} />
                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="voices" />
                            <VoiceProfilesListPage />
                          </>
                        }
                      />
                    </Route>
                    <Route path="brandkits">
                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="brandKits" />
                            <BrandKitList />
                          </>
                        }
                      />

                      <Route
                        path="create"
                        element={
                          <>
                            <PageTitle titleKey="brandKitsCreate" />
                            <BrandKitCreate />
                          </>
                        }
                      />

                      <Route
                        path=":id/edit"
                        element={
                          <>
                            <PageTitle titleKey="brandKitsEdit" />
                            <BrandKitEdit />
                          </>
                        }
                      />

                      <Route
                        path=":id"
                        element={
                          <>
                            <PageTitle titleKey="brandKits" />
                            <BrandKitDetail />
                          </>
                        }
                      />
                    </Route>
                    <Route path="faces">
                      <Route
                        path="create"
                        element={
                          <>
                            <PageTitle titleKey="facesCreate" />
                            <FaceProfileCreatePage />
                          </>
                        }
                      />

                      <Route
                        path=":id"
                        element={
                          <>
                            <PageTitle titleKey="faces" />
                            <FaceProfileDetailPage />
                          </>
                        }
                      />

                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="faces" />
                            <FaceProfilesListPage />
                          </>
                        }
                      />
                    </Route>
                    <Route path="presenters">
                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="presenters" />
                            <PresenterProfilesListPage />
                          </>
                        }
                      />
                    </Route>

                    <Route path="contacts">
                      <Route
                        path="create"
                        element={
                          <>
                            <PageTitle titleKey="contactsCreate" />
                            <ContactCreatePage />
                          </>
                        }
                      />
                      <Route
                        path=":id"
                        element={
                          <>
                            <PageTitle titleKey="contactsEdit" />
                            <ContactEditPage />
                          </>
                        }
                      />

                      <Route
                        path=":id/edit"
                        element={
                          <>
                            <PageTitle titleKey="contactsEdit" />
                            <ContactEditPage />
                          </>
                        }
                      />

                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="contacts" />
                            <ContactListPage />
                          </>
                        }
                      />
                    </Route>

                    <Route path="ctas">
                      <Route
                        path="create"
                        element={
                          <>
                            <PageTitle titleKey="callToActionsCreate" />
                            <CallToActionsFormPage />
                          </>
                        }
                      />
                      <Route path=":id" element={<CallToActionsFormPage />} />
                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="callToActions" />
                            <CallToActionsListPage />
                          </>
                        }
                      />
                    </Route>
                    <Route
                      path="pronunciations"
                      element={
                        <>
                          <PageTitle titleKey="pronunciations" />
                          <PronunciationsPage />
                        </>
                      }
                    />

                    <Route path="products">
                      <Route
                        path="create"
                        element={
                          <>
                            <PageTitle titleKey="products - create" />
                            <ProductCreatePage />
                          </>
                        }
                      />
                      <Route
                        index
                        element={
                          <>
                            <PageTitle titleKey="products" />
                            <ProductsList />
                          </>
                        }
                      />
                    </Route>
                    <Route path="libraries">
                      <Route index element={<AssetLibraries />} />
                      <Route
                        path="create"
                        element={<CreateAssetLibraryPage />}
                      />
                      <Route path=":id" element={<AssetLibraryDetail />} />
                      <Route
                        path=":id/edit"
                        element={<AssetLibraryDetail isEdit />}
                      />
                    </Route>
                  </Route>

                  <Route
                    path="onboarding"
                    element={
                      <>
                        <PageTitle titleKey="Onboarding" />
                        <OnboardingPage />
                      </>
                    }
                  />
                </Route>
                {/* </Route> */}
              </Routes>
              <ReactQueryDevtools initialIsOpen={false} />
            </Refine>
          </RefineKbarProvider>
          {/* </RefineSnackbarProvider> */}
        </ColorModeContextProvider>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
