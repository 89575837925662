import { useGetLocale, useTranslate } from "@refinedev/core";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types/index";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { useOne, useGo, Authenticated } from "@refinedev/core";
import {
  Button,
  Empty,
  Flex,
  Form,
  Input,
  List,
  Popconfirm,
  Typography,
  Space,
  Card,
  message,
} from "antd";
import { FatButton } from "../../../../../Libertify.admin/src/components/FatButton";
import { useMediaAssetsStorage } from "hooks/useMediaAssetsStorage";
import { useOrganization } from "hooks/useOrganization";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MediaAsset, CallToAction } from "../types";
import { nanoid } from "nanoid";
import { DotsSix, Trash } from "@phosphor-icons/react";
import type { MenuProps } from "antd";
import {
  CalendarOutlined,
  DownloadOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Edit } from "@refinedev/antd";
import { useAntTheme } from "hooks/useAntTheme";
import { useSupportedLanguages } from "hooks/useSupportedLanguages";
import i18n from "i18n";
import { CTAMenu } from "./components/ctaMenu";

type MenuItemType = MenuProps["items"][number];
type Item = CallToAction & { label: ReactNode };

type DraggableActionProps = {
  item: Item;
  onClose: (id: string) => void;
  onChange: (id: string, action: Item) => void;
  addItems: { [key: string]: MenuItemType };
};

const DraggableAction: React.FC<DraggableActionProps> = (props) => {
  const t = useTranslate();
  const { supportedLanguagesOptions: languages, supportedLanguages } =
    useSupportedLanguages();
  const getLocale = useGetLocale();
  const userLocale = getLocale();
  const { item, ...rest } = props;
  const hasUrl = item.action.type === "ExternalLink";
  const [form] = Form.useForm<{
    url: string;
    labels: { [key: string]: Record<string, string> };
  }>();
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: item.id,
    });
  // all possible locales
  const locales = [
    ...new Set([
      ...Object.keys(item.ui_definition.labels),
      ...supportedLanguages,
    ]),
  ]
    // remove invalid locales (fr, en when returned from backend in user data after signup)
    .filter((locale) => locale.length === 5);
  // move user locale to first position (probabaly its prefered one for edition)
  const userLocaleIndex = locales.findIndex((x) => x === userLocale);
  if (userLocaleIndex !== -1) {
    locales.splice(userLocaleIndex, 1);
    locales.unshift(userLocale);
  }
  const { theme } = useAntTheme();

  const commonStyle = {
    cursor: "move",
    transition: "unset", // Prevent element from shaking after drag
  };

  const style = transform
    ? {
        ...commonStyle,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: isDragging ? "unset" : transition, // Improve performance/visual effect when dragging
        borderColor: "yellow",
      }
    : commonStyle;

  // FieldData not exposed by antd ???
  const handleFieldsUpdated = (fields: any[], allFields: any[]) => {
    const url = allFields.find((x) => x?.name?.[0] === "url")?.value;
    // console.debug({ allFields });
    const newItem = {
      ...item,
      action: {
        ...item.action,
        url,
      },
      ui_definition: {
        ...item.ui_definition,
        labels: {
          ...item.ui_definition.labels,
          ...Object.fromEntries(
            locales.map((locale) => [
              locale,
              allFields.find(
                ({ name }) => JSON.stringify(name) === JSON.stringify(["labels", locale, item.id])
              )?.value,
            ])
          ),
        },
      },
    };
    // console.debug({ allFields, newItem });
    // bubble up
    props.onChange(item.id, newItem);
  };

  useEffect(() => {
    form.setFieldValue("url", item.action.url);
    Object.entries(item.ui_definition.labels).forEach(([key, value]) => {
      form.setFieldValue(["labels", key, item.id], value);
    });
  }, [item.id]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  let label;
  const { addItems } = props;
  const myItem = addItems[item.action.type];
  if ("label" in myItem) {
    label = myItem.label;
  }
  return (
    <List.Item style={style} ref={setNodeRef}>
      <Flex justify="space-between" style={{ width: "100%" }}>
        <Space>
          <Flex vertical gap={10}>
            <Flex vertical gap={0}>
              <Typography.Text
                type="secondary"
                strong
                style={{ color: theme.colorPrimary }}
              >
                {label ?? item.action.type}
              </Typography.Text>
              <Button size="large" shape="round">
                {item.ui_definition.labels[userLocale]}
              </Button>
            </Flex>
            <Form
              {...formItemLayout}
              onFieldsChange={handleFieldsUpdated}
              form={form}
              style={{ minWidth: 400 }}
            >
              {hasUrl && (
                <Form.Item label={t("media.ctas.create.url")} name="url">
                  <Input type="url" />
                </Form.Item>
              )}

              {locales.map((locale, index) => {
                return (
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label={`Label ${t("languageNames." + languages.find((x) => x.value === locale)?.value.split("-")[0], locale)}`}
                    name={["labels", locale, item.id]}
                    key={locale}
                  >
                    <Input type="text" size="small" />
                  </Form.Item>
                );
              })}
            </Form>
          </Flex>
        </Space>
        <Space align="center">
          <Popconfirm
            title={t("media.ctas.create.areYouSure")}
            onConfirm={() => {
              props.onClose(item.id);
            }}
          >
            <Button danger icon={<Trash size={24} />} type="text" />
          </Popconfirm>
          <DotsSix {...listeners} size={24} />
        </Space>
      </Flex>
    </List.Item>
  );
};
type CallToActionsWithLabel = {
  actions: (CallToAction & { label: ReactNode })[];
};

export function CallToActionsFormPage() {
  const t = useTranslate();
  const getLocale = useGetLocale();
  const userLocale = getLocale();
  const sensors = useSensors(useSensor(PointerSensor));
  const [items, setItems] = useState<CallToActionsWithLabel["actions"]>([]);
  const { id } = useParams();
  const [form] = Form.useForm<{
    description: string;
  }>();
  const description = Form.useWatch("description", form);
  const [loading, setLoading] = useState(false);
  const go = useGo();

  const { data, isSuccess } = useOne<MediaAsset>({
    resource: `media/media/assets`,
    id,
    queryOptions: {
      enabled: Boolean(id),
    },
  });
  const { organization } = useOrganization({});
  const { saveOrganizationAsset } = useMediaAssetsStorage({
    organizationId: organization?.id,
  });
  const {
    organizationAssets: { data: ctasData },
  } = useMediaAssetsStorage({
    organizationId: organization?.id,
    assetType: "CallToActions",
    enabled: true,
  });
  const ready = id ? isSuccess : true;
  const hasExistingCtas = ctasData?.data && ctasData?.data.length > 0;
  const initialDescription = hasExistingCtas ? "" : "Default";

  const addItems: { [key: string]: MenuItemType } = {
    ExternalLink: {
      label: t("media.ctas.create.externalLink"),
      key: "ExternalLink",
      icon: <LinkOutlined />,
      disabled: items.some((x) => x.action.type === "ExternalLink"),
    },
    CalendarAppointment: {
      label: t("media.ctas.create.calendarAppointment"),
      key: "CalendarAppointment",
      icon: <CalendarOutlined />,
      disabled: items.some((x) => x.action.type === "CalendarAppointment"),
    },
    DocumentDownload: {
      label: t("media.ctas.create.documentDownload"),
      key: "DocumentDownload",
      icon: <DownloadOutlined />,
      disabled: items.some((x) => x.action.type === "DocumentDownload"),
    },
    Email: {
      label: t("media.ctas.create.email"),
      key: "Email",
      icon: <MailOutlined />,
      disabled: items.some((x) => x.action.type === "Email"),
    },
    Phone: {
      label: t("media.ctas.create.phone"),
      key: "Phone",
      icon: <PhoneOutlined />,
      disabled: items.some((x) => x.action.type === "Phone"),
    },
  };
  //   const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  //     message.info("Click on left button.");
  //     console.log("click left button", e);
  //   };
  const handleMenuClick = (key: string) => {
    const item = addItems[key];
    handleAddItem({
      type: key,
      label: <>{"label" in item ? item.label : ""}</>,
    });
  };

  useEffect(() => {
    (async () => {
      if (!data) return;
      const response = await fetch(data.data.path);
      const json = await response.json();
      setItems(json.actions ?? []);
      form.setFieldValue("description", data.data.description ?? "");
    })();
  }, [data]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      setItems((data) => {
        const oldIndex = data.findIndex((item) => item.id === active.id);
        const newIndex = data.findIndex((item) => item.id === over.id);

        return arrayMove(data, oldIndex, newIndex);
      });
    }
  };
  const handleClose = (removedId: string) => {
    // console.debug({ removedId });
    setItems((oldItems) => oldItems.filter((item) => item.id !== removedId));
  };
  const handleChange = (id: string, action: Item) => {
    setItems((oldItems) =>
      oldItems.map((item) => (item.id === id ? action : item))
    );
  };

  const handleAddItem = ({
    type,
    label,
  }: {
    type: string; //CallToActions["actions"][number]["action"]["type"];
    label: React.ReactNode;
  }) => {
    setItems((oldItems) => [
      ...oldItems,
      {
        label,
        id: nanoid(),
        // action: { type: "ExternalLink", url: "" },
        action: { type },
        ui_definition: {
          labels: { [userLocale]: t("media.ctas.create.placeholder") },
          type: "Button",
        },
      },
    ]);
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      const assetId = await saveOrganizationAsset(
        "CallToActions",
        { actions: items },
        id,
        {
          category: "MediaMeta",
          description,
        }
      );

      message.success(t("notifications.saveSuccessful"));

      if (!id) {
        go({
          to: {
            resource: "media_ctas",
            id: assetId,
            action: "edit",
          },
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Authenticated key="ctas_create">
      <Edit
        isLoading={!ready}
        headerButtons={() => null}
        saveButtonProps={{
          onClick: handleSave,
          shape: "round",
          icon: null,
          loading: loading,
          size: "large",
          disabled: loading,
        }}
      >
        <Flex vertical gap={10}>
          <Form
            style={{ width: 400 }}
            form={form}
            initialValues={{ description: initialDescription }}
          >
            <Form.Item
              label=""
              name="description"
              rules={[
                {
                  required: true,
                  message: t("media.ctas.create.pleaseChooseA"),
                },
              ]}
            >
              <Input
                type="text"
                placeholder={t("media.ctas.create.name")}
                maxLength={20}
                size="large"
              />
            </Form.Item>
          </Form>

          <CTAMenu
            onSelect={handleMenuClick}
            disabledItems={Object.keys(addItems).filter((key) =>
              items.some((item) => item.action.type === key)
            )}
          />

          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
          >
            <SortableContext
              items={items}
              strategy={horizontalListSortingStrategy}
            >
              <List
                locale={{
                  emptyText: (
                    <Empty
                      description={t("media.ctas.create.emptyHelp")}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ),
                }}
                dataSource={items}
                renderItem={(item) => (
                  <DraggableAction
                    addItems={addItems}
                    item={item}
                    key={item.id}
                    onClose={handleClose}
                    onChange={handleChange}
                  />
                )}
              ></List>
            </SortableContext>
          </DndContext>
        </Flex>
      </Edit>
    </Authenticated>
  );
}
