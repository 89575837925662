import Finish from "./Finish";
import MultipleChoiceStep from "./MultipleChoiceStep";
import FreeTextStep from "./FreeTextStep";
import { FIRST_STEP, LAST_STEP, I18N_PREFIX } from "./constants";
import { OnboardingAnswerNormalized, Step } from "./types";
import SingleValueStep from "./SingleValueStep";
import { User } from "types";
import { RuleObject } from "antd/es/form";

export const ORDERED_STEPS = {
  department: SingleValueStep,
  // If we get any more steps, this could be reused as a generic
  // multiple value step
  usage: MultipleChoiceStep,
  business: SingleValueStep,
  website: FreeTextStep,
  source: SingleValueStep,
  finish: Finish,
};

const ORDERED_STEPS_KEYS = Object.keys(ORDERED_STEPS);
export function getCurrentStepNumber(step: Step) {
  return Object.keys(ORDERED_STEPS).indexOf(step);
}

export function getPreviousStep(step: Step) {
  const stepNumber = getCurrentStepNumber(step);
  if (stepNumber === 0) return FIRST_STEP;
  if (stepNumber === -1) return FIRST_STEP;
  return ORDERED_STEPS_KEYS[stepNumber - 1] as Step;
}

export function getNextStep(step: Step) {
  const stepNumber = getCurrentStepNumber(step);
  if (stepNumber === -1) return LAST_STEP;
  if (stepNumber === ORDERED_STEPS_KEYS.length - 1) return LAST_STEP;
  return ORDERED_STEPS_KEYS[stepNumber + 1] as Step;
}

export const ONBOARDING_NUMBER_QUESTIONS = Object.keys(ORDERED_STEPS).length;

/**
 * Uses the user's email to generate a placeholder for the organization input
 *
 * @param user A user with email
 * @returns string The placeholder for the organization input
 */
export const getOrganizationPlaceholder = (user: User | null) => {
  const genericPlaceholder = `${I18N_PREFIX}.placeholders.organization`;
  if (!user) {
    return genericPlaceholder;
  }
  const domainName = user.email.split("@")[1]?.toLowerCase();
  if (!domainName) return genericPlaceholder;
  const companyName = domainName.split(".")[0];
  const notCompanyName = [
    "gmail",
    "yahoo",
    "hotmail",
    "outlook",
    "icloud",
    "mail",
  ];
  if (notCompanyName.includes(companyName)) {
    return genericPlaceholder;
  } else {
    return companyName.slice(0, 1).toUpperCase() + companyName.slice(1);
  }
};
export const placeholders = Object.keys(ORDERED_STEPS).reduce(
  (acc, key) => {
    if (key === "organization") {
      acc[key] = getOrganizationPlaceholder;
      return acc;
    } else if (key === "finish") {
      acc[key] = "";
      return acc;
    }
    acc[key as Step] = `${I18N_PREFIX}.placeholders.${key}`;
    return acc;
  },
  {} as Record<Step, string | ((user: User | null) => string)>
);

export const transformWebsite = (value: string) => {
  if (value === "") return value; // Website can be empty
  if (!value.startsWith("http://") && !value.startsWith("https://")) {
    return `http://${value}`;
  }
  return value;
};

export const RULES: Record<Step, RuleObject[] | null> = {
  department: null,
  usage: null,
  business: null,
  website: [
    {
      message: `${I18N_PREFIX}.errors.website.invalidUrl`,
      type: "url",
      transform: transformWebsite,
    },
  ],
  source: null,
  organization: [
    {
      required: true,
      message: `${I18N_PREFIX}.errors.organization.required`,
    },
    {
      required: true,
      message: `${I18N_PREFIX}.errors.organization.tooLong`,
      max: 32,
    },
  ],
  finish: null,
};
export const titles = createStrings("titles");

export const descriptions = createStrings("descriptions");
function createStrings(type: string) {
  return Object.keys(ORDERED_STEPS).reduce(
    (acc, key) => {
      acc[key] = `${I18N_PREFIX}.${type}.${key}`;
      return acc;
    },
    {} as Record<string, string>
  );
}

export function getUniqueAnswers(answers?: OnboardingAnswerNormalized[]) {
  if (!answers) return new Set();
  return new Set(answers.map((a: OnboardingAnswerNormalized) => a.question));
}
export const errorNotificationWithEntityIdsHandler = (
  error: unknown,
  entityIdsField: string | string[]
) => {
  const errorDetails = (error as any)?.message;
  const description = errorDetails?.message ?? "";
  const fields = Array.isArray(entityIdsField)
    ? entityIdsField
    : [entityIdsField];
  // keep first non null
  const entityIds = fields.map((field) => errorDetails?.[field]).find(Boolean);

  return {
    type: "error" as const,
    description: description,
    message: Array.isArray(entityIds) ? entityIds.join("\n") : "",
  };
};
