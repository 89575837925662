import { FontPreviewText } from "pages/media/brandkit/components/FontPreview";
import { forwardRef } from "react";
import { useVolatileBoundStore } from "store";
import { DraggableComponent } from "./DraggableComponent";
import { FadeInFadeOutWrapper } from "./FadeInFadeOutWrapper";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./TemplatePreview";
import { useTranslate } from "@refinedev/core";
import { TabKeys } from "./types";
import { getFirstSentence } from "./helpers/helper";

interface ChapterPreviewProps {
  text: string;
}
export const ChapterPreview = forwardRef<HTMLVideoElement, ChapterPreviewProps>(
  ({ text }, ref) => {
    const {
      textStyle,
      setTextStyle,
      subtitlesAnimation,
      presenter,
      setPresenter,
      avatarClose,
      background,
      setCurrentItem,
      setActiveTab,
    } = useVolatileBoundStore((store) => ({
      textStyle: store.templateState.textStyle,
      subtitlesAnimation: store.templateState.subtitlesAnimation,
      presenter: store.templateState.presenter,
      avatarClose: store.templateState.avatarClose,
      background: store.templateState.background,
      setPresenter: store.setPresenter,
      setTextStyle: store.setTextStyle,
      setCurrentItem: store.setCurrentItem,
      setActiveTab: store.setActiveTab,
    }));

    const t = useTranslate();

    const sectionFragment = getFirstSentence(text);

    return (
      <>
        <div
          style={{
            height: 60,
            backgroundColor: background.headerColor ?? "#ffffff",
            backgroundImage:
              background.headerView === "Picture"
                ? `url(${background?.headerImage})`
                : "",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        {background.bodyView === "Video" && (
          <video
            ref={ref}
            src={background.bodyVideo}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          >
            <track kind="captions" src="" />
          </video>
        )}
        {textStyle.showSubtitles && (
          <DraggableComponent
            position={{ x: textStyle.position.x, y: textStyle.position.y }}
            size={{
              width: "fit-content",
              height: "fit-content",
            }}
            resize={true}
            drag={true}
            onDragStop={setTextStyle}
            onFocus={() => {
              setCurrentItem({ type: "Subtitles" });
              setActiveTab(TabKeys.text);
            }}
          >
            <FadeInFadeOutWrapper
              fadeIn={textStyle.fadeIn}
              fadeOut={textStyle.fadeOut}
            >
              <div className="font-rect">
                <FontPreviewText
                  style={{
                    width: textStyle.size.width * DEFAULT_WIDTH,
                    height: textStyle.size.height * DEFAULT_HEIGHT,
                    fontSize: textStyle.fontSize * 2,
                    padding: 0,
                    fontWeight: textStyle.bold ? "bold" : "normal",
                    fontStyle: textStyle.italic ? "italic" : "normal",
                    textDecoration: textStyle.underline ? "underline" : "none",
                    backgroundColor: textStyle.textBackground,
                    color: textStyle.subtitles_color,
                    WebkitTextStroke: subtitlesAnimation
                      ? `1px ${textStyle.subtitles_stroke_color}`
                      : "unset",
                  }}
                  font={textStyle.textFont}
                  text={
                    sectionFragment ?? t("media.brandkit.mockup.exampleText")
                  }
                  animated={subtitlesAnimation}
                />
              </div>
            </FadeInFadeOutWrapper>
          </DraggableComponent>
        )}
        {presenter.presenter && (
          <DraggableComponent
            // key={nanoid()}
            position={{ x: presenter.position.x, y: presenter.position.y }}
            size={{
              width: presenter.size.width * DEFAULT_WIDTH,
              height: presenter.size.width * DEFAULT_WIDTH,
            }}
            saveRatio={true}
            resize={true}
            drag={true}
            onDragStop={setPresenter}
            onFocus={() => {
              setCurrentItem({ type: "Presenter" });
              setActiveTab(TabKeys.presenter);
            }}
          >
            <FadeInFadeOutWrapper
              fadeIn={presenter.fadeIn}
              fadeOut={presenter.fadeOut}
            >
              <div
                style={{
                  borderRadius: presenter.avatarCircle ? "50%" : "unset",
                  backgroundColor: presenter.avatarCircle
                    ? presenter.background
                    : "unset",
                  overflow: "hidden",
                  width: presenter.size.width * DEFAULT_WIDTH,
                  height: presenter.size.width * DEFAULT_WIDTH,
                  position: "absolute",
                }}
              >
                <img
                  src={
                    avatarClose
                      ? presenter.presenter?.face_profile?.thumbnail_asset_path
                      : (presenter.presenter?.face_profile
                          ?.extracted_asset_path ??
                        presenter.presenter?.face_profile?.custom_asset_path)
                  }
                  width="100%"
                  height="100%"
                  alt="Presenter"
                  draggable="false"
                  style={{
                    position: "absolute",
                    bottom: -10,
                    objectFit: "cover",
                  }}
                />
              </div>
            </FadeInFadeOutWrapper>
          </DraggableComponent>
        )}
      </>
    );
  }
);

ChapterPreview.displayName = "ChapterPreview";
