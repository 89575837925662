import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { getAppTitle } from "helpers/utils";
import { useTranslate } from "@refinedev/core";

interface PageTitleProps {
  title?: string;
  titleKey?: string;
  analyticsTitle?: string;
  analyticsTitleKey?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  titleKey,
  analyticsTitle,
  analyticsTitleKey,
}) => {
  const t = useTranslate();
  const appTitle = useMemo(getAppTitle, []);
  const ns = "page-titles";

  const translatedTitle = titleKey ? t(titleKey, { ns }) : title;
  const englishTitle =
    (titleKey || analyticsTitleKey) && !analyticsTitle
      ? t(analyticsTitleKey ?? titleKey, { ns, lng: "en" })
      : null;
  const prettyTitle = translatedTitle
    ? `${translatedTitle} • ${appTitle}`
    : appTitle;

  return (
    <Helmet>
      <title>{prettyTitle}</title>
      {/* custom meta to use for Segment event forced in English */}
      <meta
        name="analytics-title"
        content={analyticsTitle ?? englishTitle ?? appTitle}
      />
    </Helmet>
  );
};

export default PageTitle;
