import { DisplayMetrics, TDetailedStoryboard } from "pages/media/types";
import { CombinedVolatileSlice } from "store/types";
import { StateCreator } from "zustand";

type StoryboardState = {
  scriptsWordsCount: number | undefined;
  readingTime: number | undefined;
  metrics: DisplayMetrics | undefined;
  storyboard: TDetailedStoryboard | undefined;
};

export const initialState: StoryboardState = {
  scriptsWordsCount: undefined,
  readingTime: undefined,
  metrics: undefined,
  storyboard: undefined,
};

export interface StoryboardSlice {
  storyboardState: StoryboardState;
  setScriptsWordsCount: (scriptsWordsCount: number) => void;
  setReadingTime: (readingTime: number) => void;
  setMetrics: (metrics: DisplayMetrics) => void;
  setStoryboard: (
    updateOrNewState:
      | TDetailedStoryboard
      | ((oldState: TDetailedStoryboard | undefined) => TDetailedStoryboard)
  ) => void;
  resetStoryboardState: () => void;
}
export const createStoryboardSlice: StateCreator<
  CombinedVolatileSlice,
  //https://github.com/pmndrs/zustand/issues/980#issuecomment-1162289836
  [["zustand/devtools", never], ["zustand/immer", never]],
  [],
  StoryboardSlice
> = (set) => {
  return {
    storyboardState: initialState,
    setStoryboardState: (value) =>
      set({ storyboardState: value }, undefined, "setStoryboardState"),
    resetStoryboardState: () =>
      set({ storyboardState: initialState }, undefined, "resetStoryboardState"),
    setScriptsWordsCount: (scriptsWordsCount) =>
      set(
        (state) => {
          state.storyboardState.scriptsWordsCount = scriptsWordsCount;
        },
        undefined,
        "setScriptsWordsCount"
      ),
    setReadingTime: (readingTime) =>
      set(
        (state) => {
          state.storyboardState.readingTime = readingTime;
        },
        undefined,
        "setReadingTime"
      ),
    setMetrics: (metrics) =>
      set(
        (state) => {
          state.storyboardState.metrics = metrics;
        },
        undefined,
        "setMetrics"
      ),
    setStoryboard: (updateOrNewState) => {
      set(
        (state) => {
          if (typeof updateOrNewState === "function") {
            const updatedStoryboard = updateOrNewState(
              state.storyboardState.storyboard
            );
            state.storyboardState.storyboard = updatedStoryboard;
          } else {
            state.storyboardState.storyboard = updateOrNewState;
          }
        },
        undefined,
        "setStoryboard"
      );
    },
  };
};
