import { Button, Flex, Form, Input, Space, Tooltip, Typography } from "antd";
import { DateField } from "@refinedev/antd";
import { addDateTz } from "../../utils";
import { useTranslate } from "@refinedev/core";
import dayjs from "dayjs";
import { useState } from "react";
import {
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
  FileOutlined,
  FolderOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useUpdate } from "@refinedev/core";
import { MediaProjectResponse } from "../../types";
import { User } from "types";
import { UUID } from "components/UUID";
import styled from "styled-components";
import { PencilSimpleLine } from "@phosphor-icons/react";

export const ProjectHeader = ({
  project,
  owner,
}: {
  project: MediaProjectResponse;
  owner: User;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const t = useTranslate();
  const now = dayjs();
  const projectCreationDate = dayjs(addDateTz(project?.created));
  const projectDiffDurationTime = dayjs.duration(
    -now.diff(projectCreationDate)
  );

  const { mutateAsync: updateMutationAsync, isLoading: updateMutationLoading } =
    useUpdate<MediaProjectResponse>({});

  const primaryDocument = project?.documents.find(
    (document) => document.id === project?.storyboards[0]?.primary_document_id
  );

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async (values: MediaProjectResponse) => {
    if (!project?.id) return;
    await updateMutationAsync({
      id: project?.id,
      resource: "media/projects",
      values: { ...project, ...values },
    });
    setIsEditing(false);
  };

  return (
    <Flex
      vertical
      style={{
        fontWeight: "normal",
        paddingLeft: 16,
        marginTop: 5,
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Space>
        {/* <Avatar
          style={{
            backgroundColor: "orange",
            verticalAlign: "middle",
          }}
          size="large"
          gap={5}
        >
          <Tooltip
            arrow={false}
            placement="bottom"
            title={
              owner ? (
                <>
                  {owner.first_name} {owner.last_name}
                  <Typography.Text type="secondary">
                    {" "}
                    • {project?.owner_id}
                  </Typography.Text>
                </>
              ) : null
            }
          >
            {ownerDetails}
          </Tooltip>
        </Avatar> */}
        {isEditing ? (
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSave}
            style={{ position: "relative", height: 40 }}
            initialValues={{ title: project.title }}
          >
            <Form.Item name="title" rules={[{ required: true, message: "" }]}>
              <Input
                size="large"
                style={{
                  height: 40,
                  fontSize: 26,
                  fontWeight: "bold",
                  width: "40vw",
                }}
              />
            </Form.Item>
            <Space style={{ position: "absolute", right: 0, bottom: -26 }}>
              <Button
                size="small"
                htmlType="submit"
                loading={updateMutationLoading}
                icon={<CheckOutlined />}
              />
              <Button
                size="small"
                onClick={handleCancel}
                icon={<CloseOutlined />}
              />
            </Space>
          </Form>
        ) : (
          <EditableText
            data-testid="ProjectTitle"
            editable={{ tooltip: false, icon: <></> }}
            onClick={() => setIsEditing(true)}
          >
            {project?.title}
            <EditableTextIcon>
              <PencilSimpleLine weight="bold" size={24} />
            </EditableTextIcon>
          </EditableText>
        )}
      </Space>

      <Flex align="center" style={{ paddingLeft: 10, marginTop: -5 }}>
        {project.created && (
          <Typography.Text type="secondary">
            {t("media.projects.detail.created")}
            {projectDiffDurationTime.humanize(true)}
          </Typography.Text>
        )}
        {owner && (
          <>
            <Typography.Text type="secondary" style={{ margin: "0 5px" }}>
              {t("media.projects.detail.by")}
            </Typography.Text>
            <Typography.Text>
              {owner?.first_name || owner?.last_name
                ? `${owner.first_name} ${owner.last_name}`
                : "Anonymous"}
            </Typography.Text>
          </>
        )}
        {primaryDocument && (
          <>
            <Typography.Text type="secondary" style={{ margin: "0 5px" }}>
              {t("media.projects.detail.from")}
            </Typography.Text>
            <Typography.Text>
              {primaryDocument.name.slice(0, -4)}
            </Typography.Text>
          </>
        )}
      </Flex>
    </Flex>
  );
};

const EditableTextIcon = styled.span`
  opacity: 0.5;
  transition: 500ms;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  margin-left: 5px;
`;

const EditableText = styled(Typography.Text)`
  font-size: 26px;
  font-weight: bold;
  display: inline-block;
  padding: 0px 12px;
  position: relative;

  &:hover ${EditableTextIcon} {
    opacity: 1;
  }
`;
